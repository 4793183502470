import _ from 'lodash';
import type { DictionaryType } from 'types/story';
import { getText } from 'utils/dictionary';
import {
	DEFAULT_MEDIA_QUERY_PLATFORMS,
	COMPONENT_STATES,
	COMPONENT_TYPE as TYPE,
	COMPONENT_DISPLAY_NAME,
	LAYER_TYPE,
} from 'common/constants';
import { UNIFORM_PROPS } from 'common/constants/component-props';
import { generateElementId, generateNodeId } from 'utils/generate-id';
import View from 'client/components/common/BuildingBlocks/Text/Text';

type Props = {
	dictionary?: DictionaryType;
	text?: string;
};

export const TEXT = ({ dictionary = {}, text }: Props = {}) => {
	const _id = generateElementId();

	return {
		view: View,
		get model() {
			const type = TYPE.TEXT;

			return {
				_id,
				type,
				uiConfig: {
					layer: LAYER_TYPE.CONTENT,
					editorProps: {
						name: COMPONENT_DISPLAY_NAME[type],
						selectable: true,
						draggable: true,
						expandable: false,
						expanded: false,
					},
					nodeProps: {
						id: generateNodeId(_id, type),
					},
					componentProps: {
						styles: {},
						other: {},
						[UNIFORM_PROPS.tag]: 'p',
					},
				},
				children: {
					[COMPONENT_STATES.DEFAULT]: {
						[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]:
							text ?? getText(dictionary, type, `text_${_.random(2)}`),
					},
				},
			};
		},
	};
};
