/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

export default ({ color = '#FFF', ...props }: SVGAttributes<SVGElement> & { color?: string }) => (
	<svg width="28px" height="28px" {...props} viewBox="0 0 28 28">
		<path
			d="M23.417 4.583C21 2.167 17.683.667 14 .667 6.633.667.683 6.633.683 14S6.633 27.333 14 27.333c6.217 0 11.4-4.25 12.883-10h-3.466C22.05 21.217 18.35 24 14 24 8.483 24 4 19.517 4 14S8.483 4 14 4c2.767 0 5.233 1.15 7.033 2.967l-5.366 5.366h11.666V.667l-3.916 3.916z"
			fill={color}
		/>
	</svg>
);
