import { CARD_DEFAULT_TEMPLATE_NAME } from 'common/constants';
import { CardFacadeBase } from 'utils/facades/card-facade-base';
import { defaultTemplate } from 'utils/facades/templates/default-template';

class PollFacade extends CardFacadeBase {
	static template = {
		[CARD_DEFAULT_TEMPLATE_NAME]: defaultTemplate,
	};
}

export { PollFacade };
