/* eslint-disable max-len */
import React from 'react';
import css from './NotFound.scss';

interface Props {
	actions?: React.ReactNode;
	title?: string;
}

const NotFound: React.FC<Props> = ({ title, actions }) => (
	<div className={css.notFound}>
		<div className={css.content}>
			<div className={css.imgWrap}>
				<svg xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 866 357">
					<defs>
						<path
							id="a"
							d="M224.507 266.12V230.5L329.43 64.727h29.702v50.71H341.02l-70.632 111.925v1.932h146.456v36.825H224.507zM342.47 312v-56.747l.483-15.938V64.727h42.258V312H342.47z"
						/>
						<path
							id="b"
							d="M544.846 316.709c-19.882 0-36.926-5.051-51.133-15.153s-25.113-24.711-32.72-43.828c-7.606-19.117-11.41-42.158-11.41-69.123.08-26.965 3.924-49.925 11.53-68.881 7.607-18.956 18.514-33.425 32.72-43.406 14.208-9.98 31.212-14.971 51.013-14.971s36.825 4.99 51.072 14.971c14.248 9.981 25.174 24.47 32.781 43.466 7.607 18.996 11.41 41.937 11.41 68.821 0 27.046-3.803 50.127-11.41 69.244-7.607 19.117-18.513 33.706-32.72 43.767-14.207 10.062-31.251 15.093-51.133 15.093zm0-37.791c15.455 0 27.67-7.627 36.644-22.88 8.975-15.254 13.463-37.731 13.463-67.433 0-19.64-2.053-36.141-6.158-49.503-4.105-13.361-9.9-23.443-17.386-30.245-7.486-6.801-16.34-10.202-26.563-10.202-15.374 0-27.528 7.626-36.463 22.88-8.935 15.253-13.442 37.61-13.523 67.07-.08 19.72 1.912 36.302 5.977 49.744 4.065 13.443 9.84 23.565 17.326 30.366 7.486 6.802 16.38 10.203 26.683 10.203z"
						/>
						<path
							id="c"
							d="M834.517 64.727v164.566l31.634.001v36.825h-31.634V312h-42.741v-45.88H673.813V230.5L778.736 64.727h55.781zm-42.259 50.71h-1.931l-70.632 111.925v1.932h72.563V115.437z"
						/>
						<linearGradient id="d" x1="100%" x2="14.338%" y1="50%" y2="50%">
							<stop offset="0%" stopColor="#00AB7B" stopOpacity="0.347" />
							<stop offset="100%" stopColor="#00AB7B" stopOpacity="0" />
						</linearGradient>
						<linearGradient id="e" x1="100%" x2="64.671%" y1="50%" y2="50%">
							<stop offset="0%" stopColor="#00AB7B" stopOpacity="0.339" />
							<stop offset="100%" stopColor="#00AB7B" stopOpacity="0" />
						</linearGradient>
					</defs>
					<g fill="none" fillRule="evenodd">
						<g fill="#00A87E" fillRule="nonzero">
							<use xlinkHref="#a" />
							<use xlinkHref="#a" />
						</g>
						<g fill="#00A87E" fillRule="nonzero">
							<use xlinkHref="#b" />
							<use xlinkHref="#b" />
						</g>
						<g fill="#00A87E" fillRule="nonzero">
							<use xlinkHref="#c" />
							<use xlinkHref="#c" />
						</g>
						<path
							fill="url(#d)"
							d="M546.747 98C531.337 98 349.087 65.333 0 0v357l544.737-78C579.58 279 597 248.667 597 188c0-88-39.834-90-50.253-90z"
						/>
						<path
							fill="url(#e)"
							d="M546.747 98c-6.821 0-46.335-6.401-118.542-19.203-60.607-10.746-60.923 61.596-.947 217.025L544.738 279C579.578 279 597 248.667 597 188c0-88-39.834-90-50.253-90z"
						/>
						<path
							fill="#1DCEA2"
							fillRule="nonzero"
							d="M479.4 89.278c33.045 5.625 53.55 8.809 61.512 9.552-13.532 1.21-24.372 8.78-32.529 22.705-8.935 15.253-13.442 37.61-13.523 67.07-.08 19.72 1.912 36.302 5.977 49.744 4.065 13.443 9.84 23.565 17.326 30.366 7.486 6.802 16.38 10.203 26.683 10.203 1.29 0 2.558-.054 3.803-.16-4.83 1.311-10.048 2.058-15.652 2.242l-53.412 7.816c-7.541-8.527-13.738-18.89-18.592-31.088-7.606-19.117-11.41-42.158-11.41-69.123.08-26.965 3.924-49.925 11.53-68.881 4.682-11.665 10.612-21.631 17.793-29.898z"
						/>
						<path
							fill="#23C29B"
							fillRule="nonzero"
							d="M349.887 64.73c12.357 2.241 24.136 4.37 35.335 6.385l-.012 158.18 31.634-.001v36.825l-31.634.001v35.721l-42.742 6.121V266.12h-117.96V230.5L329.428 64.727zm-6.936 50.707h-1.931l-70.632 111.925v1.933h72.563V115.438z"
						/>
					</g>
				</svg>
			</div>
			{title ? <h1 className={css.title}>{title}</h1> : null}
		</div>
		{actions ? <div className={css.actions}>{actions}</div> : null}
	</div>
);

export default NotFound;
