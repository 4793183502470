import _ from 'lodash';
import type { EmbedStoryParams } from 'src/sdk';
import type { StoryModel } from 'src/types/story';
import type { ClientReducerState } from 'client/reducers';
import { pageview } from 'utils/analytics';
import { StoryFacade } from 'utils/facades/story-facade';
import { location } from 'utils/url-helper';
import { API } from 'client/constants/actions';
import { apiRequest } from 'client/actions/api';
import { selectStory, selectStoryCardSettings, selectStorySettings } from 'client/reducers/story/selectors';
import { getCurrentMediaQueryKey } from 'utils/story-media-query';

const { isScreenshot } = location.client;

export type EventBaseTypes = {
	storyId: string;
	organizationId: string;
	teamId: string | null;
	memberId: string | null;
};

type SendStoryViewEvent = EventBaseTypes & { isUserUnregistered: boolean };

// TODO: Vadim wants delta to be calculated from as much as possible earlier timestamp (when we start loading)
const w = window;
const INIT_TIME = w && w.STORY_INITIAL_TIME ? w.STORY_INITIAL_TIME : Date.now();

function getDelta() {
	return Math.round((Date.now() - INIT_TIME) / 1000);
}

function getPlatform(story: StoryModel, embedViewport: EmbedStoryParams['viewport']) {
	const version = StoryFacade.VERSIONS.latest;
	const facade = new StoryFacade(story, version);

	return getCurrentMediaQueryKey({ mediaQuery: facade.mediaQuery, embedViewport });
}

function nonInvalidValues(data: any) {
	return _.reduce(
		data,
		(acc, curr, key) => {
			if (curr) acc[key] = curr;
			return acc;
		},
		{}
	);
}

/**
 * Every time user enter to story (pageview)
 */
export function sendStoryViewEvent({
	organizationId,
	storyId,
	isUserUnregistered,
	teamId,
	memberId,
}: SendStoryViewEvent) {
	return (dispatch, getState: () => ClientReducerState) => {
		if (isScreenshot()) return null;
		const state = getState();

		// if (!isUserUnregistered) {
		// 	pageview({
		// 		location: window.location.href,
		// 		path: `/${organizationId}/${storyId}`, // ?
		// 		title: `${organizationId}/${storyId}`,
		// 	});
		// }

		return dispatch(
			apiRequest({
				action: API.SEND_VIEW_STORY_EVENT,
				data: {
					data: {
						event: 'view_story',
						referrer: document.referrer,
						properties: nonInvalidValues({
							storyId,
							organizationId,
							teamId,
							memberId,
							platform: getPlatform(selectStory(state)!, state.settings.embed.viewport),
							delta: getDelta(),
							pageViewId: state.user.storyPageViewId,
						}),
					},
				},
			})
		);
	};
}

type SendCardViewEvent = EventBaseTypes & { cardId: string; gaUniversalId: string | undefined };
/**
 * Every time user enter to specific card (pageview)
 */
export function sendCardViewEvent({
	organizationId,
	storyId,
	cardId,
	teamId,
	memberId,
	gaUniversalId,
}: SendCardViewEvent) {
	return (dispatch, getState: () => ClientReducerState) => {
		if (isScreenshot()) return null;
		const state = getState();

		const [organizationSlug, slug] = selectStory(state)!.clientStoryId!.split('/');
		const path = [organizationSlug, slug === storyId ? undefined : slug, storyId, cardId].filter(Boolean).join('/');

		const title =
			selectStoryCardSettings(state, cardId)?.seo?.title ||
			selectStorySettings(state)?.seo?.title ||
			selectStory(state)!.name;

		document.title = title;

		pageview({
			location: window.location.href,
			path,
			title,
			gaUniversalId,
		});

		return dispatch(
			apiRequest({
				action: API.SEND_VIEW_CARD_EVENT,
				data: {
					data: {
						event: 'view_card',
						properties: nonInvalidValues({
							storyId,
							cardId,
							organizationId,
							teamId,
							memberId,
							platform: getPlatform(selectStory(state)!, state.settings.embed.viewport),
							delta: getDelta(),
							pageViewId: state.user.storyPageViewId,
						}),
					},
				},
			})
		);
	};
}

type SendShareEvent = Omit<SendCardViewEvent, 'gaUniversalId'> & {
	sharePlatform: 'facebook' | 'whatsapp' | 'twitter' | 'email';
};
/**
 * Every time user clicks on share btn (facebook | twitter...)
 */
export function sendShareEvent({ sharePlatform, organizationId, storyId, cardId, teamId, memberId }: SendShareEvent) {
	return (dispatch, getState: () => ClientReducerState) => {
		if (isScreenshot()) return null;
		const state = getState();

		return dispatch(
			apiRequest({
				action: API.SEND_VIEW_CARD_EVENT,
				data: {
					data: {
						event: 'story.share',
						properties: nonInvalidValues({
							sharePlatform,
							storyId,
							cardId,
							organizationId,
							teamId,
							memberId,
							platform: getPlatform(selectStory(state)!, state.settings.embed.viewport),
							delta: getDelta(),
							pageViewId: state.user.storyPageViewId,
						}),
					},
				},
			})
		);
	};
}

type SendRegisterEvent = EventBaseTypes & { cardId: string };

export function sendRegisterEvent({ organizationId, storyId, cardId, teamId, memberId }: SendRegisterEvent) {
	return (dispatch, getState: () => ClientReducerState) => {
		if (isScreenshot()) return null;
		const state = getState();

		let registerType = 'guest';
		if (state.user.google?.id) registerType = 'google';
		else if (state.user.facebook?.id) registerType = 'facebook';
		else if (state.user.sms?.id) registerType = 'sms';

		return dispatch(
			apiRequest({
				action: API.SEND_REGISTER_EVENT,
				data: {
					data: {
						event: 'register',
						properties: nonInvalidValues({
							registerType,
							storyId,
							organizationId,
							teamId,
							memberId,
							platform: getPlatform(selectStory(state)!, state.settings.embed.viewport),
							delta: getDelta(),
							pageViewId: state.user.storyPageViewId,
						}),
					},
				},
			})
		);
	};
}

type SendStartStoryEvent = EventBaseTypes & { cardId: string };

export function sendStartStoryEvent({ organizationId, storyId, cardId, teamId, memberId }: SendStartStoryEvent) {
	return (dispatch, getState: () => ClientReducerState) => {
		if (isScreenshot()) return null;
		const state = getState();

		return dispatch(
			apiRequest({
				action: API.SEND_START_STORY_EVENT,
				data: {
					data: {
						event: 'start_story',
						properties: nonInvalidValues({
							storyId,
							cardId,
							organizationId,
							teamId,
							memberId,
							platform: getPlatform(selectStory(state)!, state.settings.embed.viewport),
							delta: getDelta(),
							pageViewId: state.user.storyPageViewId,
						}),
					},
				},
			})
		);
	};
}

type SendFinishStoryEvent = EventBaseTypes & { cardId: string };

export function sendFinishStoryEvent({ organizationId, storyId, cardId, teamId, memberId }: SendFinishStoryEvent) {
	return (dispatch, getState: () => ClientReducerState) => {
		if (isScreenshot()) return null;
		const state = getState();

		return dispatch(
			apiRequest({
				action: API.SEND_FINISH_STORY_EVENT,
				data: {
					data: {
						event: 'finish_story',
						properties: nonInvalidValues({
							storyId,
							cardId,
							organizationId,
							teamId,
							memberId,
							platform: getPlatform(selectStory(state)!, state.settings.embed.viewport),
							delta: getDelta(),
							pageViewId: state.user.storyPageViewId,
						}),
					},
				},
			})
		);
	};
}
