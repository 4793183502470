import { debounce, get, has, replace } from 'lodash';
import React, { RefObject } from 'react';
import cn from 'classnames';
import { isLayerType } from 'common/utils/blocks/is-layer-type';
import { triggerFocus } from 'common/utils/helpers';
import { BBCommonProps, BBEditableModeProps, EditorMode } from 'types/story';
import { transmitToAdminBbUpdate } from 'client/utils/transmit-to-admin-bb-update';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import { COMPONENT_STATES } from 'common/constants';
import { UNIFORM_PROPS } from 'common/constants/component-props';
import { CHILDREN_KEY, getStateDOMAttrs } from 'client/components/common/BuildingBlocks/utils/common';
import ContentEditable from 'client/components/common/BuildingBlocks/Text/ContentEditable';
import { FIELD_DATA_ATTR, FIELD_EDITABLE_ATTR } from '../constants';
import { Error } from '../Error';
import { withField, WithFieldT } from '../utils';
import css from './Checkbox.scss';

type State = {
	editing: boolean;
	label: any;
};

type Props = BBCommonProps & WithFieldT;

class Checkbox extends React.Component<Props, State> {
	state = {
		editing: false,
		label: this.props.children as string,
	};

	onChangeDebounced = debounce((getHtml: () => string) => {
		const html = getHtml();
		if (this.state.label !== html) this.save(html, true);
	}, 500);

	static getDerivedStateFromProps({ children }: Props, state: State): Partial<State> | null {
		const isUpdatedChildrenFromProp = document.activeElement === document.body && children !== state.label;

		if (isUpdatedChildrenFromProp) {
			return { label: children, editing: false };
		}

		return null;
	}

	get path() {
		return get(this.props.editableModeProps, ['nodeProps', 'data-path']);
	}

	get fieldProps() {
		return {
			className: css.input,
			name: this.props.uiConfig.editorProps.name,
			[FIELD_DATA_ATTR]: '',
			onChange: (e: React.SyntheticEvent<HTMLLabelElement | HTMLDivElement>) => {
				if (this.props.isEditableMode) return;

				const { checked } = e.target as HTMLInputElement;
				this.props.setStates({ [COMPONENT_STATES.SELECTED]: checked });
			},
			value: `${this.checked}`,
		};
	}

	get labelProps() {
		const defaultProps = { className: css.label };

		if (this.state.editing) {
			return {
				...defaultProps,
				[FIELD_EDITABLE_ATTR]: '', // prevent from dragging by <Points />
				onKeyUp: e => e.stopPropagation(), // prevent application keyboard shortcuts while editing text
				onKeyDown: e => e.stopPropagation(), // prevent application keyboard shortcuts while editing text
			};
		}

		return {
			...defaultProps,
			dangerouslySetInnerHTML: { __html: this.state.label },
		};
	}

	get checked() {
		return Boolean(this.props.states[COMPONENT_STATES.SELECTED]);
	}

	get stateAttributes() {
		if (this.checked) {
			return getStateDOMAttrs({
				...this.props.states,
				[COMPONENT_STATES.HOVER]: false,
				[COMPONENT_STATES.SELECTED_HOVER]: false,
			});
		}

		return this.props.stateAttrs;
	}

	onEditComplete = () => {
		this.onChangeDebounced.flush();
		this.disableEditing();
	};

	save = (label: string, isContinueEditing: boolean = false) => {
		const path = this.props.editableModeProps?.nodeProps?.['data-path'];
		const { currentMediaQuery } = this.props;

		if (!path || !this.state.editing) {
			console.warn("path isn't defined...");
			return;
		}

		// NOTE: children is always writes to defaultState, but for certain media query
		transmitToAdminBbUpdate({
			id: Checkbox.name,
			path: `elements.${path}.${CHILDREN_KEY}.${COMPONENT_STATES.DEFAULT}.${currentMediaQuery}`,
			value: label,
			isStory: isLayerType(this.props).global,
		});

		this.setState({ editing: isContinueEditing, label });
	};

	/**
	 * TODO: trigger won't work until we set tabIndex={1} for props.containerRef,
	 *       but with this attr won't work ContentEditable. Need to find a solution.
	 * */
	updateSelectionRect = (p: { getHtml: () => string }) => {
		// trigger SelectionHint update to set current editable node sizes
		triggerFocus(this.props.containerRef!.current);

		this.onChangeDebounced(p.getHtml);
	};

	enableEditing = () => {
		if (!this.props.isEditableMode || this.state.editing) return;

		this.setState({ editing: true }, () => {
			// update SelectionHint and place cursor into contenteditable block
			triggerFocus(this.props.containerRef?.current?.querySelector('[contenteditable="true"]'));
		});
	};

	disableEditing = () => this.setState({ editing: false });

	renderEditable() {
		const props = { ...this.props.editableModeProps?.nodeProps, className: css.editable };
		return this.renderDefault(props);
	}

	renderDefault(props: Partial<BBEditableModeProps['nodeProps']> = {}) {
		const { nodeProps, editorProps } = this.props.uiConfig;
		const className = cn(css.checkboxW, nodeProps.className, props.className, {
			[css.checked]: this.checked,
			[css.editing]: this.state.editing,
			[css.noLabel]: !replace(this.state.label, '<br/>', ''),
		});
		const isIncorrect = has(props, 'data-incorrect-state') || this.props.states[COMPONENT_STATES.INCORRECT];
		const errorMessage = this.props.getErrorMsg(props);
		const CheckboxNode = this.props.isEditableMode ? 'div' : 'label';

		const common = {
			...this.props.uiConfig.nodeProps,
			...this.stateAttributes,
			...this.props.eventListeners,
		};

		return (
			<div
				{...common}
				{...props}
				className={className}
				ref={this.props.containerRef as RefObject<HTMLDivElement>}
			>
				<CheckboxNode
					className={css.checkbox}
					onDoubleClick={this.enableEditing}
					{...(this.props.editorMode === EditorMode.CONTENT ? { onClick: this.enableEditing } : null)}
				>
					{/* Box */}
					<span className={css.box}>
						<input type="checkbox" checked={this.checked} {...this.fieldProps} />
						<span data-checkbox="box" className={cn(css.shape, css.shapeMain)} />
						<span data-checkbox="box" className={cn(css.shape, css.shapeMotion)} />
					</span>
					{/* Label */}
					{this.state.editing ? (
						<div {...this.labelProps}>
							<ContentEditable
								html={this.state.label}
								onChange={this.updateSelectionRect}
								onComplete={this.onEditComplete}
								editableRef={this.props.containerRef as RefObject<HTMLDivElement>}
							/>
						</div>
					) : (
						<div {...this.labelProps} />
					)}
				</CheckboxNode>
				<Error
					isHidden={!isIncorrect || typeof errorMessage === 'undefined'}
					path={this.path}
					isEditableMode={!!this.props.isEditableMode}
					errorType={editorProps[UNIFORM_PROPS.fieldErrorShow]}
					text={errorMessage}
					editorMode={this.props.editorMode}
				/>
			</div>
		);
	}

	render() {
		return this.props.isEditableMode ? this.renderEditable() : this.renderDefault();
	}
}

export default withCardTransitionContext(withField(Checkbox));
