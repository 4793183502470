import { EditorMode } from 'types/story';
import { SET_EDITOR_MODE } from 'client/constants/actions';

export type SetEditorModePayload = {
	mode: EditorMode;
};

export function setEditorMode(payload: SetEditorModePayload) {
	return { type: SET_EDITOR_MODE, payload };
}
