import { COMPONENT_TYPE as TYPE, LAYER_TYPE, COMPONENT_DISPLAY_NAME } from 'common/constants';
import { generateElementId, generateNodeId } from 'utils/generate-id';
import View from 'client/components/common/BuildingBlocks/Img/Img';
import type { DictionaryType } from 'types/story';

// const pictures = [
// 	// wide
// 	'https://storycards.imgix.net/2022/02/20/1645356569175-b518cf17-3e78-405c-ad55-ba43bf057dbe.jpeg',
// 	// tall
// 	'https://storycards.imgix.net/2022/02/20/1645356607918-c48e6223-24db-48b7-ba35-4e9f7d726222.jpeg',
// 	// square
// 	'https://storycards.imgix.net/2022/02/20/1645356672849-acaabae1-6cf3-477e-a13c-e26c934b078c.jpg',
// 	// small
// 	'https://storycards.imgix.net/2022/02/20/1645356771895-25873acf-7c50-44c1-adf2-933b814358de.png',
// ];

type Props = {
	dictionary?: DictionaryType;
	lang?: string;
	styles?: Record<string, any>;
	other?: Record<string, any>;
};

export const IMG = (props: Props = {}) => {
	const _id = generateElementId();

	return {
		view: View,
		get model() {
			const type = TYPE.IMG;

			return {
				_id,
				type,
				uiConfig: {
					layer: LAYER_TYPE.CONTENT,
					editorProps: {
						name: COMPONENT_DISPLAY_NAME[type],
						selectable: true,
						draggable: true,
						expandable: false,
						expanded: false,
					},
					nodeProps: {
						id: generateNodeId(_id, type),
					},
					componentProps: {
						styles: props.styles || {},
						other: props.other || {},
					},
				},
				children: [],
			};
		},
	};
};
