import { useCallback, useRef, useState } from 'react';
import { useWillUnmount } from 'common/components/useWillUnmount';

/**
 * @param timeToCount time in milliseconds
 * @param interval interval to update countdown (time in milliseconds)
 */
export const useCountDown = (timeToCount = 60 * 1000, interval = 1000) => {
	const [timeLeft, setTimeLeft] = useState(timeToCount);
	const [isComplete, setIsComplete] = useState(false);
	const [isPause, setIsPause] = useState(true);
	const timer = useRef({ requestId: 0, timeToCount, timeStart: 0, lastInterval: 0, _timeLeft: timeLeft });

	timer.current._timeLeft = timeLeft;

	// Start a countdown
	const start = useCallback(
		(_timeToCount?: number) => {
			window.cancelAnimationFrame(timer.current.requestId);

			if (!timeToCount) return;

			const newTimeToCount = _timeToCount === undefined ? timeToCount : _timeToCount;
			timer.current.timeStart = 0;
			timer.current.lastInterval = 0;
			timer.current.timeToCount = newTimeToCount;
			timer.current.requestId = window.requestAnimationFrame(run);
			setTimeLeft(newTimeToCount);

			function run(ts: number) {
				if (!timer.current.timeStart) {
					timer.current.timeStart = ts;
					timer.current.lastInterval = ts;
				}

				setIsPause(false);

				// update countdown
				const diff = ts - timer.current.lastInterval;
				if (ts - timer.current.lastInterval >= interval) {
					timer.current.lastInterval = ts;
					setTimeLeft(v => {
						const tl = Math.max(0, v - diff);
						if (tl === 0) setIsComplete(true);
						return tl;
					});
				}

				if (ts - timer.current.timeStart < timer.current.timeToCount) {
					// continue
					timer.current.requestId = window.requestAnimationFrame(run);
				} else {
					// complete
					setTimeLeft(0);
					setIsComplete(true);
				}
			}
		},
		[timeToCount, interval]
	);

	// Pause a countdown
	const pause = useCallback(() => {
		window.cancelAnimationFrame(timer.current.requestId);
		timer.current.timeStart = 0;
		timer.current.lastInterval = 0;
		timer.current.timeToCount = timer.current._timeLeft;
		setIsPause(true);
	}, []);

	// Resume a countdown
	const resume = useCallback(() => {
		if (!timer.current.timeStart && timer.current._timeLeft > 0) {
			window.cancelAnimationFrame(timer.current.requestId);
			start(timer.current._timeLeft);
		}
	}, [start]);

	useWillUnmount(() => {
		window.cancelAnimationFrame(timer.current.requestId);
	});

	return { timeLeft, isComplete, isPause, start, pause, resume };
};
