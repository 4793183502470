import lego from 'common/assets/lottie/lego.json';
import {
	COMPONENT_STATES,
	COMPONENT_TYPE as TYPE,
	DEFAULT_MEDIA_QUERY_PLATFORMS,
	LAYER_TYPE,
	COMPONENT_DISPLAY_NAME,
} from 'common/constants';
import { generateElementId, generateNodeId } from 'utils/generate-id';
import View from 'client/components/common/BuildingBlocks/Lottie/Lottie';
import { OTHER_PROPS } from 'common/constants/component-props';

export const LOTTIE = () => {
	const _id = generateElementId();

	return {
		view: View,
		get model() {
			const type = TYPE.LOTTIE;

			return {
				_id,
				type,
				uiConfig: {
					layer: LAYER_TYPE.CONTENT,
					editorProps: {
						name: COMPONENT_DISPLAY_NAME[type],
						selectable: true,
						draggable: true,
						expandable: false,
						expanded: false,
					},
					nodeProps: {
						id: generateNodeId(_id, type),
					},
					componentProps: {
						styles: {},
						other: {
							[COMPONENT_STATES.DEFAULT]: {
								[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]: {
									[OTHER_PROPS.lottie.ltSrc]: lego,
								},
							},
						},
					},
				},
				children: [],
			};
		},
	};
};
