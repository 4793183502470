import React, { RefObject, FC } from 'react';
import type { BBCommonProps } from 'types/story';
import { DISABLED_ANSWER_ATTR, COMPONENT_TYPE } from 'common/constants';
import cn from 'classnames';
import css from './Answer.scss';

export interface AnswerBodyProps extends BBCommonProps {
	isDisabled: boolean;
	onClickCapture: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<void>) | undefined;
}

const AnswerBody: FC<AnswerBodyProps> = ({ onClickCapture, isEditableMode, ...props }) => {
	const editableProps = isEditableMode ? { ...props.editableModeProps?.nodeProps } : null;

	const clickHandler = onClickCapture
		? async (event: React.MouseEvent | React.TouchEvent) => {
				// Click on nested Overlay component children should not be captured by Answer
				if ((event.target as HTMLElement).closest(`[data-bb="${COMPONENT_TYPE.OVERLAY}"]`)) {
					return;
				}

				// Ensure the Answer component click handler has priority over other event handlers
				event.stopPropagation(); // prevent nested dom nodes event handlers
				event.preventDefault(); // prevent click on nested <a> nodes

				await onClickCapture(event as unknown as React.MouseEvent<HTMLElement, MouseEvent>);
			}
		: undefined;

	return (
		<div
			{...props.uiConfig.nodeProps}
			{...props.stateAttrs}
			{...props.eventListeners}
			// Capture phase is used to prevent any events at child nodes. Nothing should interfere an Answer click
			onClickCapture={clickHandler}
			{...editableProps}
			{...{ [DISABLED_ANSWER_ATTR]: props.isDisabled }}
			className={cn(css.answer, props.uiConfig.nodeProps.className, editableProps?.className)}
			ref={props.containerRef as RefObject<HTMLDivElement>}
		>
			{props.children}
		</div>
	);
};

export default AnswerBody;
