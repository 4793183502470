import {
	COMPONENT_TYPE as TYPE,
	LAYER_TYPE,
	COMPONENT_DISPLAY_NAME,
	COMPONENT_STATES,
	DEFAULT_MEDIA_QUERY_PLATFORMS,
} from 'common/constants';
import { generateElementId, generateNodeId } from 'common/utils/generate-id';
import { OTHER_PROPS, UNIFORM_PROPS } from 'common/constants/component-props';
import View from './Counter';
import { defaultProps } from './constants';

export const COUNTER = () => {
	const _id = generateElementId();

	return {
		view: View,
		get model() {
			const type = TYPE.COUNTER;

			return {
				_id,
				type,
				uiConfig: {
					layer: LAYER_TYPE.CONTENT,
					editorProps: {
						name: COMPONENT_DISPLAY_NAME[type],
						selectable: true,
						draggable: true,
						expandable: false,
						expanded: false,
					},
					nodeProps: {
						id: generateNodeId(_id, type),
					},
					componentProps: {
						[UNIFORM_PROPS.counterType]: defaultProps.counterType,
						[UNIFORM_PROPS.counterDate]: defaultProps.counterDate,
						styles: {
							[COMPONENT_STATES.DEFAULT]: {
								[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]: {},
							},
						},
						other: {
							[COMPONENT_STATES.DEFAULT]: {
								[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]: {
									[OTHER_PROPS.counter.counterUnits]:
										defaultProps[OTHER_PROPS.counter.counterUnits].join(','),
								},
							},
						},
					},
				},
				children: [],
			};
		},
	};
};
