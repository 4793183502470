import React from 'react';
import type { BBCommonProps, StorySettingsOfCard } from 'types/story';
import type { EventProviderContextT } from 'client/components/pages/Story/EventProvider/Context';
import { COMPONENT_STATES } from 'common/constants';
import { EventEmitter } from 'utils/event-emitter';
import { ClientReducerState, useClientDispatch } from 'client/reducers';
import { location } from 'utils/url-helper';
import { StoryHistory } from 'client/utils';
import { sendSortableAnswer } from 'client/actions/api';
import { updateAnswers } from 'client/actions/update-answers';
import { ChildrenWithParentState } from 'client/components/common/BuildingBlocks/ChildrenWithParentState';
import { TimerEvEm } from 'client/components/common/BuildingBlocks/Timer/utils';
import { LottieEvEm } from 'client/components/common/BuildingBlocks/Lottie/utils';
import { AnimationEvEm } from 'client/components/common/BuildingBlocks/animation';
import AnswerBody from './AnswerBody';

const { isPreview } = location.client;

type OnTriviaSortEndEvent = {
	type: 'onTriviaSortEnd';
	data: {
		isLast: boolean;
		isCorrect: boolean;
		answerNodeId: string;
		answerIds: string[];
		/* It's "true" if Answer displayed initially in SortableBox and not a result of user interaction.
		 In this case this Answer should update answer state in Redux and StoryHistory, to include these
		 answers in score and other answer calculations to display correct "Variables" values */
		isInitial?: boolean;
	};
};

type OnPollSortEndEvent = {
	type: 'onPollSortEnd';
	data: {
		answerNodeId: string;
		answerIds: string[];
	};
};

type OnSortEndEvent = OnTriviaSortEndEvent | OnPollSortEndEvent;

export const SortableAnswerEvEm = new EventEmitter<OnSortEndEvent>();

type Props = BBCommonProps &
	EventProviderContextT & {
		storyId: string;
		cardId: string;
		showResults: boolean;
		showCorrect: boolean;
		cardSettings: StorySettingsOfCard | undefined;
		answersData: ClientReducerState['user']['answers'][string];
	};

const SortableAnswer: React.FC<Props> = ({ answersData, cardSettings, storyId, cardId, setStates, ...props }) => {
	const answerId = props._id;
	const answerNodeId = props.uiConfig.nodeProps.id;
	const dispatch = useClientDispatch();
	const isSubmitted = Boolean(answersData?.submitted);
	const isDisabled = Boolean(props.isEditableMode) || isSubmitted;

	const getTextContent = React.useCallback(
		() => props.containerRef?.current?.innerText || 'MediaContent*',
		[props.containerRef]
	);

	const selectedIdsRef = React.useRef<string[]>([]);
	selectedIdsRef.current = answersData?.selected ?? [];

	const onTriviaSortEnd = React.useCallback(
		async (event: OnTriviaSortEndEvent) => {
			if (event.data.answerNodeId !== answerNodeId) {
				return;
			}

			const { isLast, isCorrect, answerIds, isInitial } = event.data;

			if (!isInitial) {
				setStates({
					[COMPONENT_STATES.CORRECT]: isCorrect,
					[COMPONENT_STATES.INCORRECT]: !isCorrect,
				});
			}

			// Store answer in redux (select|deselect)
			dispatch(
				updateAnswers({
					cardId,
					answerId,
					data: { textContent: getTextContent(), isCorrect },
					submitted: isLast,
				})
			);

			// Store answer to localStorage (used then to calculate a total score)
			const storyHistory = new StoryHistory({ storyId });
			const previousAnswers = storyHistory.history[storyId]?.cp?.cards?.[cardId]?.answers ?? [];
			new StoryHistory({ storyId }).addAnswer({
				cardId,
				selectedAnswers: [...previousAnswers, { id: answerId, isCorrectOrder: isCorrect }],
			});

			if (!isInitial) {
				LottieEvEm.emit('playback', { action: 'onMultipleAnswerSelect', answerNodeId, isCorrect });
				AnimationEvEm.emit('playback', { action: 'onMultipleAnswerSelect', answerNodeId, isCorrect });
			}

			if (isLast) {
				TimerEvEm.emit('pause', { cardId });
				setTimeout(() => {
					LottieEvEm.emit('playback', { action: 'onMultipleAnswerSubmit' });
					AnimationEvEm.emit('playback', { action: 'onMultipleAnswerSubmit' });
				}, 200);
			}

			if (!isPreview && !isInitial) {
				dispatch(
					sendSortableAnswer({
						type: 'partial',
						cardId,
						storyId,
						answerId,
						answerText: getTextContent(),
						items: answerIds,
					})
				);
			}
		},
		[dispatch, answerId, answerNodeId, setStates, cardId, storyId, getTextContent]
	);

	const onPollSortEnd = React.useCallback(
		(event: OnPollSortEndEvent) => {
			if (event.data.answerNodeId !== answerNodeId) {
				return;
			}

			// Store answer in redux (select|deselect)
			dispatch(
				updateAnswers({
					cardId,
					answerId,
					answerIds: event.data.answerIds,
					data: { textContent: getTextContent() },
				})
			);
		},
		[answerNodeId, dispatch, cardId, answerId, getTextContent]
	);

	React.useEffect(() => {
		SortableAnswerEvEm.addListener('onPollSortEnd', onPollSortEnd);
		SortableAnswerEvEm.addListener('onTriviaSortEnd', onTriviaSortEnd);
		return () => {
			SortableAnswerEvEm.removeListener('onPollSortEnd', onPollSortEnd);
			SortableAnswerEvEm.removeListener('onTriviaSortEnd', onTriviaSortEnd);
		};
	}, [onTriviaSortEnd, onPollSortEnd]);

	return (
		<AnswerBody {...props} setStates={setStates} isDisabled={isDisabled} onClickCapture={undefined}>
			{props.isEditableMode ? (
				props.children
			) : (
				<ChildrenWithParentState states={props.states /* change child states to answer state */}>
					{props.children}
				</ChildrenWithParentState>
			)}
		</AnswerBody>
	);
};

export default SortableAnswer;
