/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import debounce from 'lodash/debounce';
import { isPlaying } from 'common/components/VideoPlayer/helpers';

import type { Props as PlayerProps } from '../index';

// Start video at 1 second to avoid black or empty initial frame if no cover image is provided
const START_TIME = 1;

type Props = {
	className?: string;
	title: PlayerProps['title'];
	src: PlayerProps['src'];
	poster: PlayerProps['poster'];
	controls: PlayerProps['controls'];
	muted: PlayerProps['muted'];
	loop: PlayerProps['loop'];
	autoplay: PlayerProps['autoplay'];
	playsinline: PlayerProps['playsinline'];
	isVisible: boolean;
	videoRef: React.RefObject<HTMLVideoElement>;
	setIsReady: (value: React.SetStateAction<boolean>) => void;
	reloadAttempts: React.MutableRefObject<{ value: number; max: number }>;
};

const NativePlayer: React.FC<Props> = props => {
	const poster = props.poster && props.poster !== 'none' ? props.poster : undefined;
	return (
		<video
			className={props.className}
			title={props.title}
			src={`${props.src}${poster ? '' : `#t=${START_TIME}`}`}
			poster={poster}
			controls={props.controls}
			muted={props.muted}
			loop={props.loop}
			preload={props.autoplay ? 'auto' : 'metadata'}
			autoPlay={props.autoplay}
			playsInline={props.playsinline}
			controlsList="nodownload"
			ref={props.videoRef}
			onCanPlay={() => {
				console.info('VideoPlayer:onCanPlay');
				props.setIsReady(true);
			}}
			onPlay={event => {
				if (props.videoRef.current && Math.floor(props.videoRef.current.currentTime) <= START_TIME) {
					// eslint-disable-next-line no-param-reassign
					props.videoRef.current.currentTime = 0;
				}

				[...document.querySelectorAll('video')].forEach(video => {
					if (video !== event.currentTarget && isPlaying(video) && !video.muted) {
						console.info('VideoPlayer:pause:other');
						video.pause();
					}
				});
			}}
			onError={debounce(event => {
				console.error('VideoPlayer:onError', event);

				if (props.isVisible && props.reloadAttempts.current.value < props.reloadAttempts.current.max) {
					console.info('=== VideoPlayer:reload ===', props.reloadAttempts.current.value);
					props.videoRef.current?.load();
					// eslint-disable-next-line no-param-reassign
					props.reloadAttempts.current.value += 1;
				}
			}, 300)}
		/>
	);
};

export default NativePlayer;
