import { merge } from 'lodash';
import type { StorySettingsType } from 'types/story';
import { CARD_DEFAULT_TEMPLATE_NAME } from 'common/constants';
import { defaultTemplate } from 'utils/facades/templates/default-template';
import { CardFacadeBase } from 'utils/facades/card-facade-base';
import { SortableType } from 'client/components/common/BuildingBlocks/SortableBox/constants';

const defaultSortableType = SortableType.multiContainer;

const sortableTriviaDefaultTemplate = (params: Parameters<typeof defaultTemplate>[0]) => {
	const template = defaultTemplate(params);

	template.metadata.storySettings.cards = merge<StorySettingsType['cards'], StorySettingsType['cards']>(
		template.metadata.storySettings.cards ?? {},
		{
			// set sortableTriviaType by default for a new cards created from scratch
			[template.editor.card._id]: { sortableType: defaultSortableType },
		}
	);

	return template;
};

class SortableTriviaFacade extends CardFacadeBase {
	static template = {
		[CARD_DEFAULT_TEMPLATE_NAME]: sortableTriviaDefaultTemplate,
	};

	getSortableType = (storySettings: StorySettingsType) => {
		// use CountType.score to fallback old stories, because of it was initial type
		return storySettings.cards?.[this.data._id]?.sortableType ?? defaultSortableType;
	};
}

export { SortableTriviaFacade };
