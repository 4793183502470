import { UNIFORM_PROPS, OTHER_PROPS } from 'common/constants/component-props';

export enum OverlayType {
	fixed = 'fixed',
	relative = 'relative',
}

export enum OverlayShowOn {
	tap = 'tap',
	hover = 'hover',
}

export enum OverlayAnimationPreset {
	none = 'none',
	fade = 'fade',
	slideUp = 'slideUp',
	slideDown = 'slideDown',
	slideLeft = 'slideLeft',
	slideRight = 'slideRight',
}

export const defaultProps = {
	[UNIFORM_PROPS.overlayType]: OverlayType.fixed,
	[UNIFORM_PROPS.overlayShowOn]: OverlayShowOn.tap,
	[UNIFORM_PROPS.overlayTriggers]: { show: [], hide: [] },
	[OTHER_PROPS.overlay.overlayBackdrop]: 'rgba(0, 0, 0, 0.6)',
	[OTHER_PROPS.overlay.overlayBackdropVisible]: true,
	[OTHER_PROPS.overlay.overlayVisible]: false,
	[OTHER_PROPS.overlay.overlayAnimationPreset]: {
		enter: OverlayAnimationPreset.slideUp,
		exit: OverlayAnimationPreset.slideDown,
	},
};
