import { MutableRefObject, useEffect, useRef } from 'react';

interface UseClickOutside {
	(
		ref: MutableRefObject<HTMLElement | null>,
		handler: (event: Event) => void,
		params?: { extraDoc?: Document | null; capture?: boolean; disabled?: boolean }
	): void;
}

export const useClickOutside: UseClickOutside = (ref, handler, params = {}) => {
	const mouseDownTargetRef = useRef<HTMLElement | null>(null);
	const capture = params.capture ?? false;
	const disabled = params.disabled ?? false;

	useEffect(() => {
		const mouseDownListener = (event: MouseEvent) => {
			mouseDownTargetRef.current = event.target as HTMLElement;
		};

		const clickListener = (event: MouseEvent) => {
			if (
				handler &&
				ref.current &&
				mouseDownTargetRef.current &&
				!ref.current.contains(mouseDownTargetRef.current)
			) {
				handler(event);
			}
		};

		const docs = [document, ...(params.extraDoc ? [params.extraDoc] : [])];
		const controller = new AbortController();

		if (!disabled) {
			docs.forEach(doc => {
				doc.addEventListener('mousedown', mouseDownListener, { capture, signal: controller.signal });
				doc.addEventListener('click', clickListener, { capture, signal: controller.signal });
			});
		}

		return () => controller.abort();
	}, [ref, handler, params.extraDoc, capture, disabled]);

	return null;
};
