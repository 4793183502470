import {
	COMPONENT_STATES,
	COMPONENT_TYPE,
	DEFAULT_MEDIA_QUERY_PLATFORMS,
	LAYER_TYPE,
	COMPONENT_DISPLAY_NAME,
} from 'common/constants';
import { OTHER_PROPS } from 'common/constants/component-props';
import { generateElementId, generateNodeId } from 'utils/generate-id';
import { BOX_TYPE } from 'client/components/common/BuildingBlocks/Box/constants';
import View from './SortableBox';

export const SORTABLE_BOX = () => {
	const _id = generateElementId();

	return {
		view: View,
		get model() {
			const type = COMPONENT_TYPE.SORTABLE_BOX;

			return {
				_id,
				type,
				uiConfig: {
					layer: LAYER_TYPE.CONTENT,
					editorProps: {
						name: COMPONENT_DISPLAY_NAME[type],
						selectable: true,
						draggable: true,
						expandable: true,
						expanded: true,
					},
					nodeProps: {
						id: generateNodeId(_id, type),
					},
					componentProps: {
						styles: {},
						other: {
							[COMPONENT_STATES.DEFAULT]: {
								[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]: {
									[OTHER_PROPS.box.boxType]: BOX_TYPE.STACK,
								},
							},
						},
					},
				},
				children: [],
			};
		},
	};
};
