import type { DictionaryType } from 'types/story';
import {
	DEFAULT_MEDIA_QUERY_PLATFORMS,
	COMPONENT_STATES,
	COMPONENT_TYPE as TYPE,
	Pin,
	LAYER_TYPE,
	COMPONENT_DISPLAY_NAME,
} from 'common/constants';
import View from 'client/components/common/BuildingBlocks/Answer/ResultText/ResultText';
import { generateElementId, generateNodeId } from 'utils/generate-id';

type Props = {
	dictionary?: DictionaryType;
};

export const RESULT_TEXT = (props: Props = {}) => {
	const _id = generateElementId();

	return {
		view: View,
		get model() {
			const type = TYPE.RESULT_TEXT;

			return {
				_id,
				type,
				uiConfig: {
					layer: LAYER_TYPE.CONTENT,
					editorProps: {
						name: COMPONENT_DISPLAY_NAME[type],
						selectable: true,
						draggable: false,
						expandable: false,
						expanded: false,
					},
					nodeProps: {
						id: generateNodeId(_id, type),
					},
					componentProps: {
						styles: {
							[COMPONENT_STATES.DEFAULT]: {
								[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]: {
									left: '1.17vw',
									top: '-2.19vw',
									right: 'auto',
									bottom: 'auto',
								},
							},
						},
						other: {
							[COMPONENT_STATES.DEFAULT]: {
								[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]: {
									pinType: Pin.lb,
								},
							},
						},
					},
				},
				children: [],
			};
		},
	};
};
