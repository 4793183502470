import React from 'react';
import MagicIcon from 'common/components/Icon/Magic';
import { transmitTo, IFRAME_ACTIONS } from 'utils/iframe-tunnel';
import ConditionalFeatureRender from 'client/components/common/ConditionalFeatureRender';

const onClick = () => {
	// Flow: send msg to admin -> read msg -> emit inspector event -> call event
	// listener -> -> call openAIModal
	transmitTo({
		id: 'Image',
		target: 'admin',
		action: IFRAME_ACTIONS.OPEN_AI_MODAL,
	});
};

const AIImageActions = () => {
	return (
		<ConditionalFeatureRender feature="create-ai-story">
			<button title="Generate AI image" type="button" onClick={onClick}>
				<MagicIcon width={16} />
			</button>
		</ConditionalFeatureRender>
	);
};

export default AIImageActions;
