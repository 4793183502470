import React, { RefObject, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { BBCommonProps, EditorMode } from 'types/story';
import { COMPONENT_STATES } from 'common/constants';
import { isLayerType } from 'common/utils/blocks/is-layer-type';
import { CSS_PROPS, UNIFORM_PROPS } from 'common/constants/component-props';
import { transmitToAdminBbUpdate } from 'client/utils/transmit-to-admin-bb-update';
import type { TFieldProps } from 'client/components/common/BuildingBlocks/Fields/types';
import { SelectionHintEvent } from 'client/components/common/SelectionHint/SelectionHintEvent';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import type { TransitionHandlerContextType } from 'client/components/common/CardTransition/TransitionHandler';
import { FIELD_DATA_ATTR, FIELD_EDITABLE_ATTR } from '../constants';
import { Error } from '../Error';
import { placeholderPath, withField, WithFieldT } from '../utils';
import css from './TextArea.scss';

type Props = BBCommonProps & WithFieldT & TransitionHandlerContextType;

const TextArea: React.FC<Props> = props => {
	const propsRef = useRef(props);
	propsRef.current = props;

	const { isEditableMode, editorMode } = props;
	const path = props.editableModeProps?.nodeProps?.['data-path'];
	const isGlobal = isLayerType(props).global;
	const [readOnly, setReadOnly] = useState(isEditableMode);
	const [pending, setPending] = useState(false);
	const providedPlaceholder = props.uiConfig.componentProps[UNIFORM_PROPS.fieldPlaceholder];
	const [placeholder, setPlaceholder] = useState(providedPlaceholder);

	// list of properties to enable placeholder inline editing
	const editableModeInputProps: Partial<TFieldProps> | null = isEditableMode
		? {
				...(readOnly ? null : { [FIELD_EDITABLE_ATTR]: '' /* necessarily prevent component drag */ }),
				value: readOnly ? '' : placeholder,
				style: { color: props.uiConfig.componentProps.styles[CSS_PROPS.field.style.text.phColor] },
				[editorMode === EditorMode.CONTENT ? 'onClick' : 'onDoubleClick']: () => {
					setReadOnly(false);
				},
				onChange: event => {
					setPlaceholder(event.target.value);
				},
				onBlur: event => {
					if (placeholder === providedPlaceholder) {
						setReadOnly(true);
						return;
					}

					transmitToAdminBbUpdate({
						id: TextArea.name,
						path: `elements.${path}.${placeholderPath}`,
						value: placeholder,
						isStory: isGlobal,
					});

					setPending(true);
				},
				onFocus: event => {
					// prevent this block from being selected when clicking on its parent
					event.stopPropagation();
				},
			}
		: null;

	// list of common input properties
	const inputProps: Omit<TFieldProps, 'type'> = {
		className: css.area,
		name: props.uiConfig.editorProps?.name,
		placeholder,
		autoComplete: 'off',
		[FIELD_DATA_ATTR]: '',
		readOnly,
		...editableModeInputProps,
	};

	const nodeProps = {
		...props.uiConfig.nodeProps,
		...(props.editableModeProps ? props.editableModeProps.nodeProps ?? null : null),
		className: cn(css.textArea, props.uiConfig.nodeProps.className, props.editableModeProps?.nodeProps?.className, {
			[css.editable]: props.isEditableMode,
		}),
	};
	const isIncorrect = 'data-incorrect-state' in nodeProps || props.states[COMPONENT_STATES.INCORRECT];
	const errorMessage = props.getErrorMsg(nodeProps);

	// place caret into input to start editing placeholder
	useEffect(() => {
		if (!readOnly) {
			const p = propsRef.current as WithRequired<BBCommonProps, 'editableModeProps'>;
			SelectionHintEvent.triggerFocus(p);
		}
	}, [readOnly]);

	// update placeholder with a value from props
	useEffect(() => {
		setPlaceholder(providedPlaceholder);
	}, [providedPlaceholder]);

	// ensure that editable placeholder successfully saved to complete editing mode
	useEffect(() => {
		if (providedPlaceholder === placeholder && pending) {
			setPending(false);
			setReadOnly(true);
		}
	}, [providedPlaceholder, placeholder, pending]);

	return (
		<div
			{...props.stateAttrs}
			{...props.eventListeners}
			{...nodeProps}
			ref={props.containerRef as RefObject<HTMLDivElement>}
		>
			<div className={css.areaWrap}>
				<textarea {...inputProps} />
			</div>
			<Error
				isHidden={!isIncorrect || errorMessage === undefined}
				path={path}
				isEditableMode={!!props.isEditableMode}
				errorType={props.uiConfig.editorProps[UNIFORM_PROPS.fieldErrorShow]}
				text={errorMessage}
				editorMode={props.editorMode}
			/>
		</div>
	);
};

export default withCardTransitionContext(withField(TextArea));
