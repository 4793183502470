import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import cn from 'classnames';
import _ from 'lodash';

import { TRANSITION_TYPE } from 'common/constants';
import type { BBCommonProps, BBEditableModeProps } from 'types/story';
import { CONTENT_CONTAINER_ID } from 'client/constants/common';
import type { ClientReducerState } from 'client/reducers';
import { selectCardId } from 'client/reducers/card/selectors';
import OutbrainWidget from 'client/components/common/OutbrainWidget';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import { getExposeDuration, getExposeParams } from 'client/components/common/BuildingBlocks/utils/common';
import AutoHeight from './AutoHeight';
import css from './Content.scss';

const mapState = (state: ClientReducerState, ownProps: OwnProps) => {
	const cardId = selectCardId(state);
	const isEmbedAutoHeight = state.settings.embed.autoHeight;

	// todo: refactor to `selectStorySettings` (warn! circular dependency)
	const storySettings = state.story.story?.storyVersions?.latest.settings;
	// todo: refactor to `selectStoryCardSettings` (warn! circular dependency)
	const cardSettings = cardId ? storySettings?.cards?.[cardId] : undefined;

	return {
		exposeDuration: getExposeDuration(cardSettings?.expose?.duration ?? ''),
		exposeEffect: ownProps.uiConfig?.expose?.effect,
		isEmbedAutoHeight: Boolean(isEmbedAutoHeight),
	};
};

const connector = connect(mapState);

type OwnProps = BBCommonProps & {
	exposeDuration?: number;
	exposeEffect?: ValuesType<typeof TRANSITION_TYPE>;
	onTransitionEnd: (...args: any[]) => any;
	transitionCSS: Record<string, boolean>;
	resetChildrenTransition: () => void;
};

type Props = OwnProps & ConnectedProps<typeof connector>;

class Content extends React.Component<Props> {
	contentRef = React.createRef<HTMLDivElement>();

	contentInnerRef = React.createRef<HTMLDivElement>();

	childrenTransitions: any;

	isSelfTransitionComplete: boolean;

	isChildrenTransitionComplete: boolean;

	transitionTimeout?: number;

	onResizeDebounced: any;

	constructor(props, context) {
		super(props, context);

		this.childrenTransitions = props.resetChildrenTransition();
		this.isSelfTransitionComplete = false;
		this.isChildrenTransitionComplete = (Array.isArray(this.props.children) ? this.props.children.length : 0) === 0;
		this.transitionTimeout = 0;
		this.onResizeDebounced = _.debounce(this.onWindowResize, 50);
	}

	componentDidMount() {
		window.addEventListener('resize', this.onResizeDebounced);
		this.onWindowResize();
	}

	componentDidUpdate(prevProps: any) {
		const { duration, delay, effect } = this.exposeParams;

		if (effect && this.props.in !== prevProps.in) {
			this.isSelfTransitionComplete = false;
			clearTimeout(this.transitionTimeout);
			this.transitionTimeout = window.setTimeout(
				() => {
					this.isSelfTransitionComplete = true;
					if (this.isSelfTransitionComplete && this.isChildrenTransitionComplete) {
						this.props.onTransitionEnd(this.props);
					}
				},
				effect ? duration + delay : 0
			);
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.onResizeDebounced);
	}

	get exposeParams() {
		return getExposeParams({
			exposeEffect: this.props.exposeEffect,
			exposeDuration: this.props.exposeDuration,
			bbType: this.props.type,
			in: this.props.in,
		});
	}

	onWindowResize = () => {
		if (!this.contentRef.current) {
			return;
		}
		this.contentRef.current.style.minHeight = `${window.innerHeight}px`;
	};

	onChildTransitionEnd = ({ _id, type }) => {
		if (this.childrenTransitions[_id]) {
			return;
		}

		this.childrenTransitions[_id] = true;

		if (_.values(this.childrenTransitions).every(isComplete => !!isComplete)) {
			this.isChildrenTransitionComplete = true;
			if (this.isSelfTransitionComplete && this.isChildrenTransitionComplete) {
				this.props.onTransitionEnd(this.props);
			}
		}
	};

	renderEditable() {
		const props = { ...this.props.editableModeProps?.nodeProps };
		return this.renderDefault(props);
	}

	renderDefault(props: Partial<BBEditableModeProps['nodeProps']> = {}) {
		const { uiConfig, stateAttrs, eventListeners, transitionCSS, isEmbedAutoHeight } = this.props;
		const { duration, delay } = this.exposeParams;

		return (
			<div className={cn(css.contentW, css[`align-${this.props.contentAlignY}`])} ref={this.contentRef}>
				<div className={css.contentI} id={CONTENT_CONTAINER_ID}>
					<div
						{...uiConfig.nodeProps}
						{...stateAttrs}
						{...eventListeners}
						{...props}
						className={cn(transitionCSS, css.content, uiConfig.nodeProps.className, props.className)}
						style={{
							...props.style,
							...uiConfig.nodeProps.style,
							...(transitionCSS
								? { transitionDuration: `${duration}ms`, transitionDelay: `${delay}ms` }
								: null),
						}}
						ref={this.contentInnerRef}
					>
						<AutoHeight contentInnerRef={this.contentInnerRef} isEmbedAutoHeight={isEmbedAutoHeight}>
							{React.Children.map(this.props.children, (child: any) => {
								return React.cloneElement(child, { onTransitionEnd: this.onChildTransitionEnd });
							})}
						</AutoHeight>
					</div>

					<OutbrainWidget />
				</div>
			</div>
		);
	}

	render() {
		return this.props.isEditableMode ? this.renderEditable() : this.renderDefault();
	}
}

export default connector(withCardTransitionContext(Content));
