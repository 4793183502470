import React, { RefObject, useEffect, useState, useRef, useCallback } from 'react';
import cn from 'classnames';
import { gsap } from 'gsap';
import type { BBCommonProps, OverlayTriggersProp } from 'types/story';
import { useClientSelector } from 'client/reducers';
import { isLayerType } from 'utils/blocks/is-layer-type';
import { STORY_ROOT_ID } from 'client/constants/common';
import GlobalStyles from 'common/components/GlobalStyles';
import { useDidMount } from 'common/components/useDidMount';
import { useClickOutside } from 'common/components/useClickOutside';
import { selectEditableElement } from 'client/reducers/editor/selectors';
import PrependPortal from 'client/components/common/PrependPortal';
import { getElementIdByNodeId } from 'client/components/common/BuildingBlocks/utils/common';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import { ChildrenWithParentState } from 'client/components/common/BuildingBlocks/ChildrenWithParentState';
import { OverlayShowOn, OverlayType, OverlayAnimationPreset, defaultProps } from './constants';
import css from './Overlay.scss';

type UseAnimateProps = {
	root: HTMLDivElement | null;
	overlay: HTMLDivElement | null;
	backdrop: HTMLDivElement | null;
	preset: { enter: OverlayAnimationPreset; exit: OverlayAnimationPreset };
	isVisible: boolean;
	disabled: boolean;
};

const useAnimate = ({ isVisible, root, backdrop, overlay, preset, disabled }: UseAnimateProps) => {
	const isReady = useRef(false);

	useEffect(() => {
		const tl = gsap.timeline();
		let x = 0;
		let y = 0;
		const ease = isVisible ? 'sine.out' : 'sine.in';
		const offset = { x: 125, y: 75 };
		const canAnimate = (key: 'enter' | 'exit') => {
			const presetName = preset[key] || OverlayAnimationPreset.none;
			return !disabled && isReady.current && presetName !== OverlayAnimationPreset.none;
		};

		const animateIn = () => {
			tl.set(root, { display: 'block' });

			if (preset.enter === OverlayAnimationPreset.slideLeft) x = offset.x;
			else if (preset.enter === OverlayAnimationPreset.slideRight) x = offset.x * -1;

			if (preset.enter === OverlayAnimationPreset.slideUp) y = offset.y;
			else if (preset.enter === OverlayAnimationPreset.slideDown) y = offset.y * -1;

			if (canAnimate('enter')) {
				tl.fromTo(backdrop, { alpha: 0 }, { alpha: 1, duration: 0.1, ease });
				const fr = { alpha: 0, scale: 0.97, x, y };
				const to = { alpha: 1, scale: 1, y: 0, x: 0, duration: 0.2, ease };
				tl.fromTo(overlay, fr, to);
			}
		};

		const animateOut = () => {
			if (preset.exit === OverlayAnimationPreset.slideLeft) x = offset.x * -1;
			else if (preset.exit === OverlayAnimationPreset.slideRight) x = offset.x;

			if (preset.exit === OverlayAnimationPreset.slideUp) y = offset.y * -1;
			else if (preset.exit === OverlayAnimationPreset.slideDown) y = offset.y;

			if (canAnimate('exit')) {
				tl.to(backdrop, { alpha: 0, duration: 0.1, ease });
				tl.to(overlay, { alpha: 0, scale: 0.93, y, x, duration: 0.2, ease });
			}

			tl.set(root, { display: 'none' });
		};

		if (overlay) {
			if (isVisible) animateIn();
			else animateOut();

			isReady.current = true;
		}
	}, [root, backdrop, overlay, preset, isVisible, disabled]);

	return null;
};

type UseDefaultVisible = {
	isEditableMode?: boolean;
	overlayVisible: boolean;
	isHiddenByLayers: boolean;
	_id: string;
	containerRef: BBCommonProps['containerRef'];
};

const useDefaultVisible = (props: UseDefaultVisible) => {
	const editableElement = useClientSelector(selectEditableElement);
	const selectedNode = editableElement ? document.getElementById(editableElement.uiConfig.nodeProps.id) : null;
	const isContains = selectedNode ? props.containerRef?.current?.contains(selectedNode) : false;
	const isSelected = editableElement?._id === props._id || isContains;
	const [isVisible, setIsVisible] = useState(props.overlayVisible && !props.isHiddenByLayers);
	const [initialVisibilityProps] = useState({
		overlayVisible: props.overlayVisible,
		isHiddenByLayers: props.isHiddenByLayers,
		isSelected,
	});
	const [areVisibilityPropsPristine, setAreVisibilityPropsPristine] = useState(true);

	useEffect(() => {
		if (props.isHiddenByLayers) setIsVisible(false);
		else if (props.isEditableMode && !areVisibilityPropsPristine && isSelected) setIsVisible(true);
		else if (props.isEditableMode && !areVisibilityPropsPristine && !isSelected) setIsVisible(false);
		else if (props.overlayVisible || isSelected) setIsVisible(true);
	}, [props.overlayVisible, props.isEditableMode, props.isHiddenByLayers, isSelected, areVisibilityPropsPristine]);

	useEffect(() => {
		if (
			props.overlayVisible !== initialVisibilityProps.overlayVisible ||
			props.isHiddenByLayers !== initialVisibilityProps.isHiddenByLayers ||
			isSelected !== initialVisibilityProps.isSelected
		) {
			setAreVisibilityPropsPristine(false);
		}
	}, [props.overlayVisible, props.isHiddenByLayers, isSelected, initialVisibilityProps]);

	return [isVisible, setIsVisible] as const;
};

const Overlay: React.FC<BBCommonProps> = props => {
	const { editableModeProps: EMP, stateAttrs, uiConfig, isEditableMode } = props;
	const {
		overlayTriggers = defaultProps.overlayTriggers,
		overlayShowOn = defaultProps.overlayShowOn,
		overlayType = defaultProps.overlayType,
	} = uiConfig.componentProps;
	const {
		overlayVisible = false,
		overlayBackdrop = defaultProps.overlayBackdrop,
		overlayBackdropVisible = defaultProps.overlayBackdropVisible,
		overlayAnimationPreset = defaultProps.overlayAnimationPreset,
	} = uiConfig.componentProps.other;
	const isHiddenByLayers = uiConfig.componentProps.styles.display === 'none';
	const isFixed = isLayerType(props).fixed || isLayerType(props).global || overlayType === OverlayType.fixed;

	const rootRef = useRef<HTMLDivElement | null>(null);
	const backdropRef = useRef<HTMLDivElement | null>(null);
	const [isVisible, setIsVisible] = useDefaultVisible({
		overlayVisible,
		isEditableMode,
		isHiddenByLayers,
		_id: props._id,
		containerRef: props.containerRef,
	});

	const onClickOutside = useCallback(() => {
		if (!isEditableMode && isVisible) {
			setIsVisible(false);
		}
	}, [isEditableMode, setIsVisible, isVisible]);

	// close on click outside when backdrop is not present
	useClickOutside(props.containerRef as RefObject<HTMLDivElement>, onClickOutside, {
		disabled: overlayBackdropVisible,
		capture: true,
	});

	useEffect(
		function visibilityHandler() {
			const controller = new AbortController();

			if (!isEditableMode) {
				const rootTarget = document.getElementById(STORY_ROOT_ID);
				const showTriggers = new Set(overlayTriggers?.show);
				const hideTriggers = new Set(overlayTriggers?.hide);
				const eventName =
					overlayShowOn === OverlayShowOn.hover && !('ontouchstart' in window) ? 'mouseenter' : 'click';

				const listener = (event: MouseEvent) => {
					if (event.target === backdropRef.current) {
						// close overlay on backdrop click
						event.stopPropagation();
						setIsVisible(false);
						return;
					}

					for (
						let target = event.target as HTMLElement | null;
						target && target !== rootTarget;
						target = target.parentElement
					) {
						const targetId = getElementIdByNodeId(target.id);
						if (targetId && (showTriggers.has(targetId) || hideTriggers.has(targetId))) {
							event.stopPropagation(); // prevent target action, for example prevent Button to navigate
							setIsVisible(showTriggers.has(targetId)); // show/hide overlay on trigger node click
							break;
						}
					}
				};

				document.addEventListener(eventName, listener, { signal: controller.signal, capture: true });
			}

			return () => controller.abort();
		},
		[overlayTriggers, overlayShowOn, isEditableMode, setIsVisible]
	);

	const onMouseLeave = () => {
		if (!isEditableMode && overlayShowOn === OverlayShowOn.hover) setIsVisible(false);
	};

	useAnimate({
		root: rootRef.current,
		backdrop: backdropRef.current,
		overlay: (props.containerRef as RefObject<HTMLDivElement>)?.current,
		preset: overlayAnimationPreset,
		isVisible,
		disabled: Boolean(isEditableMode),
	});

	useDidMount(() => {
		if (!isEditableMode && !isVisible) {
			props.onTransitionEnd?.({ in: !!props.in, _id: props._id, type: props.type, uiConfig });
		}
	});

	return (
		<PrependPortal
			targetNodeId="overlay-root"
			// re-mount on overlay order change
			key={`${props._id}-${uiConfig.nodeProps?.style?.zIndex}`}
		>
			<div ref={rootRef} style={{ display: 'none' }}>
				{/* disable page scroll */}
				{!isEditableMode && isFixed && isVisible && <GlobalStyles styles="html { overflow: hidden; }" />}

				{!isEditableMode && <GlobalStyles styles={generateOverlayTriggersStyle(overlayTriggers)} />}

				{overlayBackdropVisible ? (
					<div ref={backdropRef} className={css.overlayBackdrop} style={{ background: overlayBackdrop }} />
				) : null}
				<div
					data-bb=""
					className={cn(css.overlayContainer, { [css.fixed]: isFixed })}
					onMouseLeave={onMouseLeave}
				>
					<div
						{...uiConfig.nodeProps}
						{...stateAttrs}
						{...props.eventListeners}
						{...EMP?.nodeProps}
						style={uiConfig.nodeProps?.style}
						className={cn(css.overlay, uiConfig.nodeProps.className, EMP?.nodeProps?.className)}
						ref={props.containerRef as RefObject<HTMLDivElement>}
					>
						{isEditableMode ? (
							props.children
						) : isVisible ? (
							<ChildrenWithParentState states={props.parentStates}>
								{/* parent states can be received from parent Answer BB or parent Swipe BB */}
								{props.children}
							</ChildrenWithParentState>
						) : null}
					</div>
				</div>
			</div>
		</PrependPortal>
	);
};

function generateOverlayTriggersStyle(overlayTriggers: OverlayTriggersProp) {
	const ids = overlayTriggers?.show?.concat(overlayTriggers?.hide || []);
	return `${ids.map(id => `[id$="${id}"]`).join(', ')} { cursor: pointer; }`;
}

export default withCardTransitionContext(Overlay);
