import React from 'react';
import { isEmpty, indexOf, first, pick, has, get } from 'lodash';
import type { BBCommonProps, BBEditableModeProps } from 'types/story';
import { COMPONENT_STATES } from 'common/constants';
import { CSS_PROPS, UNIFORM_PROPS } from 'common/constants/component-props';
import { withFormContext } from 'client/components/common/StoryCard/Form/withFormContext';
import { FieldBBErrorType } from 'client/components/common/BuildingBlocks/Fields/constants';
import { DateFormat, DateDelimiter } from 'client/components/common/BuildingBlocks/Fields/types';

/**
 * @param isEditableMode {!boolean}
 * @param isIncorrect {!boolean}
 * @param errors {!object} pairs key:value where key is an error name and value is an error message
 * @param errorShow {string=} error name to show by admin panel request
 * @param validationResult {array=} list of error names,
 * provided in preview|published mode as a result of validations that had made
 * @returns {string}
 */
function getErrorMsg({ isEditableMode, isIncorrect, errors, errorShow, validationResult }): string {
	if (!isIncorrect || isEmpty(errors)) {
		return '';
	}

	// show error by admin panel request
	if (isEditableMode && !!errorShow) {
		return errors[errorShow];
	}

	const { required } = FieldBBErrorType;
	const errorType = indexOf(validationResult, required) !== -1 ? required : first(validationResult);
	// @ts-expect-error ts-migrate FIXME
	return errorType ? errors[errorType] : '';
}

/**
 * @param states {!object}
 * @param setStates {!function}
 * @param formContext {!object}
 * @param formContext.errors {!object}
 * @param id {!string}
 */
function updateComponentValidState({ states, setStates, formContext, id }) {
	const isCorrect = states[COMPONENT_STATES.CORRECT];
	const hasErrors = get(formContext, `errors.${id}`);

	if (hasErrors && isCorrect) {
		setStates({
			...states,
			[COMPONENT_STATES.CORRECT]: false,
			[COMPONENT_STATES.INCORRECT]: true,
		});
	}

	if (!hasErrors && !isCorrect) {
		setStates({
			...states,
			[COMPONENT_STATES.CORRECT]: true,
			[COMPONENT_STATES.INCORRECT]: false,
		});
	}
}

export type WithFieldT = {
	getErrorMsg: (props: Partial<BBEditableModeProps['nodeProps']>) => ReturnType<typeof getErrorMsg>;
};

export function withField<P extends BBCommonProps & WithFieldT>(WrappedComponent: React.ComponentType<P>) {
	class WithField extends React.Component<P> {
		static displayName = `WithField(${WrappedComponent.displayName || WrappedComponent.name})`;

		componentDidMount() {
			this.props.formContext?.registerField?.(this.props);
		}

		componentDidUpdate(prevProps: P) {
			// Validation
			// @ts-expect-error ts-migrate FIXME
			updateComponentValidState({
				id: this.props._id,
				...pick(this.props, ['setStates', 'formContext', 'states']),
			});
		}

		/**
		 * @param props {Object=} Node props provided in editable mode
		 * @returns {string}
		 */
		getErrorMsg: WithFieldT['getErrorMsg'] = props => {
			const { componentProps, editorProps } = this.props.uiConfig;
			const isIncorrect = has(props, 'data-incorrect-state') || this.props.states[COMPONENT_STATES.INCORRECT];

			return getErrorMsg({
				isEditableMode: this.props.isEditableMode,
				isIncorrect,
				errorShow: editorProps[UNIFORM_PROPS.fieldErrorShow],
				errors: componentProps[UNIFORM_PROPS.fieldError],
				validationResult: get(this.props.formContext, `errors.${this.props._id}`),
			});
		};

		render() {
			return <WrappedComponent {...this.props} getErrorMsg={this.getErrorMsg} />;
		}
	}

	return withFormContext(WithField);
}

export const placeholderColorPath = `uiConfig.componentProps.styles.${CSS_PROPS.field.style.text.phColor}`;
export const placeholderPath = `uiConfig.componentProps.${UNIFORM_PROPS.fieldPlaceholder}`;

export const getDateFormat = (format: DateFormat, delimiter: DateDelimiter): string => {
	const delimiterRegex = /DD|MM|YYYY/g;
	const result = format.replace(delimiterRegex, match => `${match}${delimiter}`);
	return result.slice(0, -1 /* cut ending delimiter */);
};
