import { SET_EDITABLE_STATE } from 'client/constants/actions';
import { EditableStateInfo } from 'types/story';

/**
 * Store info about an element who is changed his state (used only for editor view)
 * @param payload {Object}
 * @param payload.state {String}
 * @param payload.source {Object}
 * @param payload.source.path {String}
 * @param payload.source.layer {LAYER_TYPE}
 * @param payload.source.id {String}
 * @return {{type, payload: {state: string, path, layer}}}
 */
export function setEditableState(payload: EditableStateInfo) {
	return {
		type: SET_EDITABLE_STATE,
		payload,
	};
}
