import React from 'react';
import type { BBCommonProps } from 'types/story';

type Props = {
	children: React.ReactNode;
	states: BBCommonProps['states'] | undefined;
};

export const ChildrenWithParentState = ({ children, states }: Props): any => {
	return React.Children.map(children, child => {
		if (!React.isValidElement<BBCommonProps>(child)) {
			return child;
		}

		return React.cloneElement<BBCommonProps>(child, { ...child.props, parentStates: states });
	});
};
