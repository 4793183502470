import React from 'react';
import noop from 'lodash/noop';

import { ANIMATION_TRIGGER } from 'common/constants';
import { location } from 'common/utils/url-helper';
import { useClientSelector } from 'client/reducers';
import { selectCardId } from 'client/reducers/card/selectors';
import { selectStoryFacade, selectStorySettings } from 'client/reducers/story/selectors';
import { IOutbrainIntegration } from 'src/types/organization';

import { CONTAINER_POSITION_TOP_OFFSET_EL_ID } from './constants';

type UseOutbrainResult =
	| {
			shouldRenderWidget: true;
			outbrain: IOutbrainIntegration;
			isInViewport: boolean;
	  }
	| {
			shouldRenderWidget: false;
			outbrain?: IOutbrainIntegration;
			isInViewport: false;
	  };

const { isPreview, isScreenshot } = location.client;

/**
 * Hook to check if Outbrain widget should be rendered on the current card.
 *
 * @returns {boolean} shouldRenderWidget - true if the widget should be rendered on the current card.
 * @returns {boolean} isInViewport - true if the widget is in the viewport.
 */
export function useOutbrain(): UseOutbrainResult {
	const [isInViewport, setIsInViewport] = React.useState(false);

	const currentCardId = useClientSelector(selectCardId);
	const storySettings = useClientSelector(selectStorySettings);
	const storyFacade = useClientSelector(selectStoryFacade);
	const outbrain = storySettings?.integrations?.outbrain;
	const isPublishedView = storyFacade.isPublished && !isScreenshot() && !isPreview;

	// Outbrain widget can be attached to a specific card or to all cards,
	// check if the current card is the one that should have the widget:
	const shouldRenderWidget =
		isPublishedView &&
		!!outbrain?.visible &&
		// empty array means "all cards":
		(Boolean(outbrain.cards?.find(cardId => cardId === currentCardId)) || !outbrain.cards?.length);

	React.useEffect(() => {
		if (!shouldRenderWidget) {
			setIsInViewport(false);
			return noop;
		}

		const abortController = new AbortController();
		const intersectionObserver = new IntersectionObserver(
			([entry]) => {
				setIsInViewport(entry.isIntersecting);
			},
			{ threshold: 0 }
		);

		window.addEventListener(
			ANIMATION_TRIGGER.ON_CARD_EXPOSE_COMPLETE,
			() => {
				const outbrainWidget = document.getElementById(CONTAINER_POSITION_TOP_OFFSET_EL_ID);

				if (outbrainWidget) {
					intersectionObserver.observe(outbrainWidget);
				}
			},
			{ signal: abortController.signal }
		);

		return () => {
			intersectionObserver.disconnect();
			abortController.abort();
		};
	}, [shouldRenderWidget]);

	if (!shouldRenderWidget) {
		return {
			shouldRenderWidget: false,
			isInViewport: false,
			outbrain,
		};
	}

	return {
		shouldRenderWidget,
		isInViewport,
		outbrain,
	};
}
