enum ActionStatus {
	PENDING = 'PENDING',
	FULFILLED = 'FULFILLED',
	REJECTED = 'REJECTED',
}

export type ThunkActionType = Record<ActionStatus, string>;

export const createThunkActionType = <T extends string>(type: T) => ({
	PENDING: `${type}/${ActionStatus.PENDING}` as const,
	FULFILLED: `${type}/${ActionStatus.FULFILLED}` as const,
	REJECTED: `${type}/${ActionStatus.REJECTED}` as const,
});
