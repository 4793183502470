import { EventEmitter } from 'utils/event-emitter';
import { OTHER_PROPS } from 'common/constants/component-props';

export enum LottieDirection {
	forward = 'forward',
	backward = 'backward',
}

export enum LottieTrigger {
	// --- Component scope ---

	// on page load + in viewport
	load = 'load',
	// on block click
	onClick = 'onClick',
	// on block hover
	onHover = 'onHover',
	// on page scroll
	scroll = 'scroll',
	// on block mousemove
	cursor = 'cursor',

	// --- Card scope ---

	// on select any answer in card
	onCardAnswer = 'onCardAnswer',
	// on answer card correctly
	onCardAnswerCorrect = 'onCardAnswerCorrect',
	// on answer card incorrectly
	onCardAnswerIncorrect = 'onCardAnswerIncorrect',
	// on leads form card submit click
	onSubmit = 'onSubmit',

	// todo: how to run animation when clicking on some particular block in card?
	//       like click on `ButtonA` will start animation of `ButtonB`
}

export const defaultProps = {
	[OTHER_PROPS.lottie.ltTrigger]: LottieTrigger.load,
	[OTHER_PROPS.lottie.ltKeepRatio]: true,
	[OTHER_PROPS.lottie.ltOffset]: [0, 100] as [number, number],
	[OTHER_PROPS.lottie.ltSpeed]: 1,
	[OTHER_PROPS.lottie.ltLoop]: false,
	[OTHER_PROPS.lottie.ltDir]: LottieDirection.forward,
};

export type LottiePlaybackPayload =
	| { action: 'previewAnimation'; lottieId: string }
	| { action: 'onAnswer'; answerNodeId: string; isCorrect: boolean }
	| { action: 'onMultipleAnswerSelect'; answerNodeId: string; isCorrect?: boolean }
	| { action: 'onMultipleAnswerSubmit' }
	| { action: 'onFormSubmit' };

export type LottiePlaybackEvent = { type: 'playback'; data: LottiePlaybackPayload };

export const LottieEvEm = new EventEmitter<LottiePlaybackEvent>();

function getContainerVisibility(selector: string) {
	const element = document.querySelector(selector);
	if (!element) {
		return 0;
	}
	const { top, height } = element.getBoundingClientRect();
	const current = window.innerHeight - top - document.documentElement.scrollTop;
	const max = window.innerHeight + height - document.documentElement.scrollTop;
	return current / max;
}

type GetInteractivityActions = {
	offset: [number, number];
	container: string;
	totalFrames: number;
	trigger: LottieTrigger;
};

export const getInteractivityActions = (props: GetInteractivityActions) => {
	switch (props.trigger) {
		case LottieTrigger.scroll: {
			const [startOffset, endOffset] = props.offset ?? defaultProps.ltOffset;
			const visible = getContainerVisibility(props.container);
			const start = startOffset / 100 + (visible > 0 ? visible : 0);
			const end = endOffset / 100;

			return [
				{
					visibility: [start, end],
					type: 'seek',
					frames: [0, props.totalFrames],
				},
			];
		}
		case LottieTrigger.cursor:
			return [
				{
					position: { x: [0, 1], y: [0, 1] },
					type: 'seek',
					frames: [0, props.totalFrames],
				},
			];
		default:
			return [];
	}
};
