import React, { SVGAttributes } from 'react';

export default ({ color = '#FFF', ...props }: SVGAttributes<SVGElement> & { color?: string }) => (
	<svg width={18} xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 18 14">
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g transform="translate(-3.000000, -5.000000)">
				<polygon fill={color} points="9 16.17 4.83 12 3.41 13.41 9 19 21 7 19.59 5.59" />
			</g>
		</g>
	</svg>
);
