import _ from 'lodash';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import type { ClientReducerState } from 'client/reducers';
import { setEditorMode } from 'client/actions/set-editor-mode';
import { IframeTunnel, IFRAME_ACTIONS, ClientReceiverMessageEvent } from 'utils/iframe-tunnel';
import { Context } from 'client/components/common/SelectionHint/SelectionHintContext';
import { SELECTION_TYPES, SelectionHintEvEm } from 'client/components/common/SelectionHint/utils';
import { LottieEvEm } from 'client/components/common/BuildingBlocks/Lottie/utils';

const mapStateToProps = ({ editor }: ClientReducerState) => ({
	selection: editor.selection[SELECTION_TYPES.clicked],
});

const connector = connect(mapStateToProps, { setEditorMode });

type OwnProps = {
	updateCard?: (...args: any[]) => any;
	updateStoryElements?: (...args: any[]) => any;
	isCard?: boolean;
	isStory?: boolean;
};

type Props = ConnectedProps<typeof connector> & OwnProps;

class IframeMessageReceiver extends React.PureComponent<Props> {
	static contextType = Context;

	static defaultProps = {
		isCard: false,
		isStory: false,
	};

	IframeTunnel: any;

	componentDidMount() {
		interface SettingsI {
			id: string;
			messageReceiver: (event: MessageEvent) => void;
			filter?: string[];
		}

		const settings: SettingsI = {
			id: `Story:${this.props.isCard ? 'CardElements' : 'StoryElements'}`,
			messageReceiver: this.receiveMessage,
		};

		if (this.props.isCard || this.props.isStory) {
			settings.filter = this.props.isCard
				? [
						IFRAME_ACTIONS.UPDATE_CLIENT_CARD_DATA,
						IFRAME_ACTIONS.INITIATE_SELECT_EDITOR_ELEMENT,
						IFRAME_ACTIONS.FORCE_SELECTION_HINT_UPDATE,
						IFRAME_ACTIONS.SET_EDITOR_MODE,
						IFRAME_ACTIONS.LOTTIE_PLAYBACK,
					]
				: [IFRAME_ACTIONS.UPDATE_CLIENT_STORY_ELEMENTS];
		}

		this.IframeTunnel = new IframeTunnel(settings);
		this.IframeTunnel.init();
	}

	componentWillUnmount() {
		this.IframeTunnel.destroy();
	}

	storyReceiver = (props: ClientReceiverMessageEvent) => {
		const { action, payload } = props.data ?? {};

		if (action === IFRAME_ACTIONS.UPDATE_CLIENT_STORY_ELEMENTS && this.props.updateStoryElements) {
			// editableState
			this.props.updateStoryElements(_.pick(payload, ['elements', 'editableState', 'settings', 'symbols']));
		}
	};

	cardReceiver = (props: ClientReceiverMessageEvent) => {
		const { action, payload } = props.data ?? {};

		switch (action) {
			case IFRAME_ACTIONS.UPDATE_CLIENT_CARD_DATA:
				this.props.updateCard?.(_.pick(payload, ['data', 'editableState', 'settings', 'symbols']));
				break;
			case IFRAME_ACTIONS.INITIATE_SELECT_EDITOR_ELEMENT: {
				const { id } = payload;

				/*
				 Reason for using an `setInterval`:
				 In a client app, certain elements are conditionally rendered. Therefore, when initiating
				 a select action, we cannot guarantee that the element we are trying to select is already mounted
				 in the DOM. As a result, we need to introduce a time delay and retry the action.
				 */

				const retriesMax = 10;
				let retries = 0;
				const interval = setInterval(() => {
					retries += 1;

					if (this.props.selection[id] || retries > retriesMax) {
						clearInterval(interval);
						return;
					}

					const element = document.getElementById(id);
					if (element) {
						const e = new MouseEvent('mousedown', { bubbles: true, ...payload.eventOptions });

						// @ts-ignore
						e.forceSelectionId = payload.forceSelectionId;
						element.dispatchEvent(e);

						if (payload.scrollIntoView && !element.closest('.swiper-wrapper')) {
							element.scrollIntoView({ block: 'nearest' });
						}

						// focus content window to be able to use key listeners on it
						window.focus();
					}
				}, 50);
				break;
			}
			case IFRAME_ACTIONS.SET_EDITOR_MODE:
				this.props.setEditorMode({ mode: payload.mode });
				break;
			case IFRAME_ACTIONS.FORCE_SELECTION_HINT_UPDATE:
				SelectionHintEvEm.emit('forceUpdate');
				break;
			case IFRAME_ACTIONS.LOTTIE_PLAYBACK:
				LottieEvEm.emit('playback', payload);
				break;
			default:
				break;
		}
	};

	receiveMessage = (props: ClientReceiverMessageEvent) => {
		if (this.props.isCard) {
			this.cardReceiver(props);
		}

		if (this.props.isStory) {
			this.storyReceiver(props);
		}
	};

	render() {
		return null;
	}
}

export default connector(IframeMessageReceiver);
