import { set } from 'lodash';
import produce from 'immer';
import { STORY_VERSIONS } from 'common/constants';
import type { ActionType as GetStorySpreadSheetValuesAction } from 'client/actions/get-story-spreadsheet-values-map';
import { getPathToCardInSteps } from 'utils/facades/utils';
import * as actionType from 'client/constants/actions';
import * as actions from 'client/actions';
import initialState from './initial-state';

type ActionType =
	| ReturnType<
			| typeof actions.setStorySymbols
			| typeof actions.setStorySettings
			| typeof actions.setStoryElements
			| typeof actions.setStory
			| typeof actions.setStoryCardData
	  >
	| GetStorySpreadSheetValuesAction;

export default function storyReducer(state = initialState, action: ActionType): typeof initialState {
	return produce(state, draftState => {
		const { type, payload } = action;

		if (type === actionType.SET_STORY) {
			draftState.story = payload.story;
			draftState.spreadsheetVariables = payload.spreadsheetVariables ?? [];
			draftState.features = payload.features ?? {};
		}

		if (type === actionType.SET_STORY_ELEMENTS) {
			draftState.story!.storyVersions.latest.data.elements = payload;
		}

		if (type === actionType.SET_STORY_SYMBOLS) {
			draftState.story!.storyVersions.latest.data.symbols = payload;
		}

		if (type === actionType.SET_STORY_SETTINGS) {
			draftState.story!.storyVersions.latest.settings = payload;
		}

		if (type === actionType.SET_STORY_CARD_DATA && draftState.story) {
			const { steps } = draftState.story.storyVersions.latest.data;
			const cardPath = getPathToCardInSteps(steps, payload._id);
			set(draftState.story.storyVersions[STORY_VERSIONS.latest].data.steps, cardPath, payload);
		}

		if (type === actionType.GET_STORY_SPREADSHEET_VALUES.FULFILLED) {
			draftState.spreadsheetValuesMap = payload;
		}
	});
}
