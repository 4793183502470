import {
	COMPONENT_STATES,
	COMPONENT_TYPE as TYPE,
	DEFAULT_MEDIA_QUERY_PLATFORMS,
	Pin,
	COMPONENT_DISPLAY_NAME,
	LAYER_TYPE,
} from 'common/constants';
import { generateElementId, generateNodeId } from 'utils/generate-id';
import { SHAPE_TYPE } from 'client/components/common/BuildingBlocks/Shape/Shape';
import View from 'client/components/common/BuildingBlocks/Answer/ResultShape/ResultShape';

export const RESULT_SHAPE = () => {
	const _id = generateElementId();

	return {
		view: View,
		get model() {
			const type = TYPE.RESULT_SHAPE;

			return {
				_id,
				type,
				uiConfig: {
					layer: LAYER_TYPE.CONTENT,
					editorProps: {
						name: COMPONENT_DISPLAY_NAME[type],
						selectable: true,
						draggable: false,
						expandable: false,
						expanded: false,
					},

					componentProps: {
						other: {
							[COMPONENT_STATES.DEFAULT]: {
								[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]: {
									shapeType: SHAPE_TYPE.RECT,
									pinType: Pin.b,
								},
							},
						},
						styles: {
							[COMPONENT_STATES.DEFAULT]: {
								[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]: {
									justifySelf: 'stretch',
									alignSelf: 'end',
									bottom: 'auto',
									left: 'auto',
									right: 'auto',
									width: 'auto',
									height: '0.94vw',
									top: '-0.86vw',
								},
							},
						},
					},
					nodeProps: {
						id: generateNodeId(_id, type),
					},
				},
				children: [],
			};
		},
	};
};
