/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

export default ({ color = '#FFF', ...props }: SVGAttributes<SVGElement> & { color?: string }) => (
	<svg width="24px" {...props} viewBox="0 0 24 24">
		<g fill="none" fillRule="evenodd">
			<g opacity="0.5" fillOpacity="0.87">
				<path
					className="fill"
					fill={color}
					d="M13.866 4.03a.206.206 0 00-.086.086.281.281 0 00-.03.134v.25a.562.562 0 01-.06.268.412.412 0 01-.172.171.562.562 0 01-.268.061h-2.5a.562.562 0 01-.268-.06.412.412 0 01-.171-.172.562.562 0 01-.061-.268v-.25a.281.281 0 00-.03-.134.206.206 0 00-.086-.086.256.256 0 00-.084-.027L10.012 4h-1.05c-.287 0-.417.026-.525.074l-.053.026a.681.681 0 00-.284.284c-.056.104-.09.208-.098.447L8 4.96V19.04c0 .334.035.455.1.577a.681.681 0 00.284.284c.104.056.208.09.447.098l.13.002h6.078c.334 0 .455-.035.577-.1a.681.681 0 00.284-.284c.056-.104.09-.208.098-.447l.002-.13V4.96c0-.334-.035-.455-.1-.577a.681.681 0 00-.284-.284c-.104-.056-.208-.09-.447-.098L15.04 4H14a.281.281 0 00-.134.03z"
				/>
			</g>
			<path
				d="M8.75 3.002L15.078 3c.669 0 .911.07 1.156.2.244.131.436.323.567.567.13.245.2.487.2 1.156v14.154c0 .669-.07.911-.2 1.156-.131.244-.323.436-.567.567-.245.13-.487.2-1.156.2H8.923c-.669 0-.911-.07-1.156-.2a1.363 1.363 0 01-.567-.567c-.118-.223-.187-.443-.198-.984L7 4.923c0-.669.07-.911.2-1.156.131-.244.323-.436.567-.567.223-.118.443-.187.984-.198zm5.116 1.028a.206.206 0 00-.086.086.281.281 0 00-.03.134v.25a.562.562 0 01-.06.268.412.412 0 01-.172.171.562.562 0 01-.268.061h-2.5a.562.562 0 01-.268-.06.412.412 0 01-.171-.172.562.562 0 01-.061-.268v-.25a.281.281 0 00-.03-.134.206.206 0 00-.086-.086.256.256 0 00-.084-.027L10.012 4h-1.05c-.287 0-.417.026-.525.074l-.053.026a.681.681 0 00-.284.284c-.056.104-.09.208-.098.447L8 4.96V19.04c0 .334.035.455.1.577a.681.681 0 00.284.284c.104.056.208.09.447.098l.13.002h6.078c.334 0 .455-.035.577-.1a.681.681 0 00.284-.284c.056-.104.09-.208.098-.447l.002-.13V4.96c0-.334-.035-.455-.1-.577a.681.681 0 00-.284-.284c-.104-.056-.208-.09-.447-.098L15.04 4H14a.281.281 0 00-.134.03z"
				fillOpacity="0.87"
				fill={color}
			/>
		</g>
	</svg>
);
