import {
	DEFAULT_MEDIA_QUERY_PLATFORMS as MQ,
	COMPONENT_STATES as STATE,
	COMPONENT_TYPE as TYPE,
	COMPONENT_DISPLAY_NAME,
	LAYER_TYPE,
} from 'common/constants';
import { UNIFORM_PROPS } from 'common/constants/component-props';
import { generateElementId, generateNodeId } from 'utils/generate-id';
import View from 'client/components/common/BuildingBlocks/Answer/Answer';

export const ANSWER = () => {
	const _id = generateElementId();

	return {
		view: View,
		get model() {
			const type = TYPE.ANSWER;

			return {
				_id,
				type,
				uiConfig: {
					layer: LAYER_TYPE.CONTENT,
					editorProps: {
						name: COMPONENT_DISPLAY_NAME[type],
						selectable: true,
						draggable: true,
						expandable: true,
						expanded: false,
					},
					nodeProps: {
						id: generateNodeId(_id, type),
					},
					componentProps: {
						[UNIFORM_PROPS.answerTimeout]: 0,
						styles: {
							[STATE.DEFAULT]: {
								[MQ.DEFAULT]: {},
								[MQ.DESKTOP]: {},
								[MQ.TABLET]: {},
								[MQ.MOBILE]: {},
							},
							[STATE.HOVER]: {
								[MQ.DEFAULT]: {},
							},
							[STATE.CORRECT]: {
								[MQ.DEFAULT]: {},
							},
							[STATE.INCORRECT]: {
								[MQ.DEFAULT]: {},
							},
						},
						other: {},
					},
				},
				children: [],
			};
		},
	};
};
