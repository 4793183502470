import React, { ReactElement, ReactNode } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _ from 'lodash';

import { TRANSITION_TYPE } from 'common/constants';
import type { BBCommonProps, BBEditableModeProps } from 'types/story';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import { withFixedWrapper } from 'client/components/common/BuildingBlocks/FixedWrapper';
import { getExposeDuration, getExposeParams } from 'client/components/common/BuildingBlocks/utils/common';
import css from './FloatAbove.scss';

type FloatAboveProps = BBCommonProps & {
	exposeDuration?: number;
	exposeEffect?: ValuesType<typeof TRANSITION_TYPE>;
	onTransitionEnd: (...args: any[]) => any;
	transitionCSS: any;
	children: ReactNode;
};

class FloatAbove extends React.Component<FloatAboveProps> {
	transitionTimeout: number = 0;

	componentDidUpdate(prevProps: any) {
		const { duration, delay, effect } = this.exposeParams;

		if (this.props.in !== prevProps.in) {
			clearTimeout(this.transitionTimeout);
			this.transitionTimeout = window.setTimeout(
				() => this.props.onTransitionEnd(this.props),
				effect ? duration + delay : 0
			);
		}
	}

	get exposeParams() {
		return getExposeParams({
			exposeEffect: this.props.exposeEffect,
			exposeDuration: this.props.exposeDuration,
			bbType: this.props.type,
			in: this.props.in,
		});
	}

	onChildTransitionEnd = ({ _id, type }) => {
		// do nothing
	};

	renderEditable() {
		const props = { ...this.props.editableModeProps?.nodeProps };
		return this.renderDefault(props);
	}

	renderDefault(props: Partial<BBEditableModeProps['nodeProps']> = {}) {
		const { uiConfig, stateAttrs, eventListeners, transitionCSS } = this.props;
		const { duration, delay } = this.exposeParams;

		return (
			<div
				{...uiConfig.nodeProps}
				{...stateAttrs}
				{...eventListeners}
				className={classNames(transitionCSS, css.floatAbove, uiConfig.nodeProps.className)}
				style={{
					...uiConfig.nodeProps.style,
					...(transitionCSS ? { transitionDuration: `${duration}ms`, transitionDelay: `${delay}ms` } : null),
				}}
				{...props}
			>
				{_.map(React.Children.toArray(this.props.children), (child: ReactElement<BBCommonProps>, index) =>
					withFixedWrapper({
						isCardTree: child.props.isCardTree,
						key: child.props._id,
						layer: child.props.uiConfig.layer,
						zIndex: _.get(child.props, 'uiConfig.nodeProps.style.zIndex'),
						children: React.cloneElement(child, { onTransitionEnd: this.onChildTransitionEnd }),
					})
				)}
			</div>
		);
	}

	render() {
		return this.props.isEditableMode ? this.renderEditable() : this.renderDefault();
	}
}

const mapState = (state, ownProps) => ({
	exposeDuration: getExposeDuration(
		_.get(state, `story.storyVersions.latest.settings.cards.${state.card._id}.expose.duration`)
	),
	exposeEffect: _.get(ownProps.uiConfig, 'expose.effect'),
});

export default connect(mapState)(withCardTransitionContext(FloatAbove));
