import type { DictionaryType } from 'types/story';
import { getText } from 'utils/dictionary';
import {
	DEFAULT_MEDIA_QUERY_PLATFORMS,
	COMPONENT_STATES,
	COMPONENT_TYPE as TYPE,
	LAYER_TYPE,
	COMPONENT_DISPLAY_NAME,
} from 'common/constants';
import { generateElementId, generateNodeId } from 'utils/generate-id';
import View from 'client/components/common/BuildingBlocks/Login/GoogleLogin/GoogleLogin';

type Props = {
	dictionary?: DictionaryType;
};

export const GOOGLE_LOGIN = ({ dictionary = {} }: Props = {}) => {
	const _id = generateElementId();

	return {
		view: View,
		get model() {
			const type = TYPE.GOOGLE_LOGIN;

			return {
				_id,
				type,
				uiConfig: {
					layer: LAYER_TYPE.CONTENT,
					editorProps: {
						name: COMPONENT_DISPLAY_NAME[type],
						selectable: true,
						draggable: true,
						expandable: false,
						expanded: false,
					},
					nodeProps: {
						id: generateNodeId(_id, type),
					},
					componentProps: {
						other: {},
						styles: {},
					},
				},
				children: {
					[COMPONENT_STATES.DEFAULT]: {
						[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]: getText(dictionary, type, 'label'),
					},
				},
			};
		},
	};
};
