import type { Dispatch, SetStateAction } from 'react';
import type { SwiperOptions } from 'swiper/types';
import { createContext, RefObject } from 'react';

type SliderContextType = {
	paginationRef: RefObject<HTMLDivElement>;
	prevArrowRef: RefObject<HTMLDivElement>;
	nextArrowRef: RefObject<HTMLDivElement>;
	settings: SwiperOptions;
	paginationSettings: SwiperOptions['pagination'];
	setPaginationSettings: Dispatch<SetStateAction<SwiperOptions['pagination']>>;
};

const context = createContext<SliderContextType>({} as SliderContextType);

export default context;
