/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

export default ({ color = '#FFF', ...props }: SVGAttributes<SVGElement> & { color?: string }) => (
	<svg width="24px" {...props} viewBox="0 0 64 64">
		<path
			d="M50.7763 4.6595L51.5301 9.18284C51.811 10.8684 53.1318 12.1893 54.8173 12.4704L59.3408 13.2241C59.6737 13.2788 59.9347 13.5398 59.9894 13.8728C60.0598 14.3014 59.7694 14.706 59.3408 14.7764L54.8173 15.5304C53.1318 15.8114 51.811 17.1323 51.5301 18.8178L50.7763 23.3411C50.7217 23.6745 50.4604 23.9357 50.1271 23.9903C49.6985 24.0605 49.2941 23.7698 49.2239 23.3411L48.47 18.8178C48.1891 17.1322 46.8681 15.8113 45.1825 15.5304L40.6591 14.7764C40.3261 14.7217 40.0651 14.4607 40.0105 14.1277C39.9401 13.6991 40.2305 13.2945 40.6591 13.2241L45.1825 12.4704C46.8682 12.1895 48.1891 10.8685 48.47 9.18284L49.2239 4.6595C49.2784 4.32619 49.5397 4.06493 49.873 4.01037C50.3017 3.94019 50.7061 4.23082 50.7763 4.6595ZM29.5533 17.319L31.061 26.3654C31.6226 29.7364 34.2641 32.3781 37.635 32.9402L46.6816 34.4476C47.3476 34.557 47.8695 35.079 47.9789 35.7449C48.1197 36.6021 47.5389 37.4112 46.6816 37.552L37.635 39.06C34.2642 39.622 31.6227 42.2636 31.061 45.6346L29.5533 54.6809C29.4442 55.3475 28.9217 55.87 28.2551 55.9791C27.3978 56.1194 26.589 55.5382 26.4486 54.6809L24.941 45.6346C24.3791 42.2634 21.7373 39.6217 18.3661 39.06L9.31978 37.552C8.65385 37.4426 8.13187 36.9207 8.0225 36.2548C7.88171 35.3975 8.46252 34.5884 9.31978 34.4476L18.3661 32.9402C21.7374 32.3784 24.3792 29.7366 24.941 26.3654L26.4486 17.319C26.5578 16.6524 27.0803 16.1299 27.7468 16.0208C28.6042 15.8805 29.413 16.4617 29.5533 17.319Z"
			fill={color}
		/>
	</svg>
);
