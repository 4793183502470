import React from 'react';
import cn from 'classnames';

import type { BBCommonProps } from 'types/story';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import { getClassNames as getBoxClassNames } from '../Box/constants';

const Sortable = React.lazy(() => import('./Sortable'));

const SortableBox: React.FC<BBCommonProps> = ({ editableModeProps, ...props }) => {
	const containerRef = props.containerRef as React.RefObject<HTMLDivElement>;

	return (
		<div
			{...props.uiConfig.nodeProps}
			{...props.stateAttrs}
			{...props.eventListeners}
			{...editableModeProps?.nodeProps}
			data-box-type={props.uiConfig.componentProps.other?.boxType}
			style={{ ...props.uiConfig.nodeProps?.style, ...editableModeProps?.nodeProps?.style }}
			className={cn(
				props.uiConfig.nodeProps.className,
				editableModeProps?.nodeProps?.className,
				getBoxClassNames({ uiConfig: props.uiConfig })
			)}
			ref={props.containerRef as React.RefObject<HTMLDivElement>}
		>
			{props.isEditableMode ? (
				props.children
			) : (
				<React.Suspense fallback={null}>
					<Sortable
						rootRef={containerRef}
						initialIndexes={props.uiConfig.componentProps.sortableStartItems}
						mode={props.uiConfig.componentProps.sortableMode}
						order={props.uiConfig.componentProps.sortableOrder}
						offsetTop={props.uiConfig.componentProps.other?.dragContainerOffset}
						navigationTimeout={props.uiConfig.componentProps.sortableNavTimeout}
					>
						{props.children}
					</Sortable>
				</React.Suspense>
			)}
		</div>
	);
};

export default withCardTransitionContext(SortableBox);
