/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

export default ({ color = '#FFF', ...props }: SVGAttributes<SVGElement> & { color?: string }) => (
	<svg width="24px" viewBox="0 0 24 24" {...props}>
		<path
			fill={color}
			d="M19,6 C19.5522847,6 20,6.44771525 20,7 L20,17 C20,17.5522847 19.5522847,18 19,18 L5,18 C4.44771525,18 4,17.5522847 4,17 L4,7 C4,6.44771525 4.44771525,6 5,6 L19,6 Z M19,7.613 L12,12.6128895 L5,7.614 L5,17 L19,17 L19,7.613 Z M18.139,7 L5.86,7 L12,11.384 L18.139,7 Z"
		/>
	</svg>
);
