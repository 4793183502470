import { MouseEvent as ReactMouseEvent } from 'react';
import { BBCommonProps } from 'types/story';
import { SELECTION_STAGE, SELECTION_TYPES } from 'client/components/common/SelectionHint/utils';
import { SelectionHintController } from 'client/components/common/SelectionHint/SelectionHintController';

export const EVENT_NAME = 'SelectBB' as const;

export type SelectedHintEventDetail = {
	trigger: 'focus' | 'select' | 'deselect';
	selectionType: ValuesType<typeof SELECTION_TYPES>;
	stage?: ValuesType<typeof SELECTION_STAGE>;
	originalEvent: ReactMouseEvent | { totalSelected: number; shiftKey: boolean; currentTarget: { id: string } };
	componentProps: null | WithRequired<
		Pick<BBCommonProps, '_id' | 'type' | 'uiConfig' | 'editableModeProps' | 'symbol'>,
		'editableModeProps'
	>;
};

type Options = {
	detail: SelectedHintEventDetail;
	bubbles?: boolean;
	cancelable?: boolean;
};

declare global {
	// eslint-disable-next-line no-unused-vars
	interface WindowEventMap {
		[EVENT_NAME]: CustomEvent;
	}
}

export type Listener = (event: CustomEvent<Options['detail']>) => void;

export class SelectionHintEvent {
	/**
	 * @desc common usage is to force update of SelectionHint
	 */
	static triggerFocus(props: WithRequired<BBCommonProps, 'editableModeProps'>) {
		new SelectionHintEvent({
			options: {
				detail: {
					trigger: SelectionHintController.FOCUS,
					selectionType: SELECTION_TYPES.clicked,
					componentProps: props,
					originalEvent: {
						totalSelected: 1,
						shiftKey: false,
						currentTarget: { id: props.uiConfig.nodeProps.id },
					},
				},
			},
		}).dispatch();
	}

	options: Partial<Options>;

	listener?: Listener;

	constructor(props: { options?: Options; listener?: Listener }) {
		this.options = {
			bubbles: true,
			cancelable: false,
			...(props.options || {}),
		};

		if (props.listener) this.listener = props.listener;
	}

	addListener() {
		if (this.listener) window.addEventListener(EVENT_NAME, this.listener);
	}

	removeListener() {
		if (this.listener) window.removeEventListener(EVENT_NAME, this.listener);
	}

	dispatch() {
		window.dispatchEvent(new CustomEvent(EVENT_NAME, this.options));
	}
}
