import React from 'react';
import { ITraverseTreeOptionsListeners } from 'client/components/common/BuildingBlocks/utils/traverse-tree-types';

export interface ISelectionHintContext {
	editor: {
		treeOptions: ITraverseTreeOptionsListeners;
	};
	default: {
		treeOptions: null;
	};
}

export const Context = React.createContext<ISelectionHintContext['editor'] | ISelectionHintContext['default']>({
	treeOptions: null,
});
