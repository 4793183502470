import { SET_CARD_HEIGHT, SET_EMBED_PARAMS, SET_STORY } from 'client/constants/actions';
import { setStory } from 'client/actions/set-story';
import { setCardHeight, setEmbedParams } from '../../actions';
import initialState from './initial-state';

type Action = ReturnType<typeof setCardHeight | typeof setEmbedParams | typeof setStory>;

export default function settingsReducer(state = initialState, action: Action): typeof initialState {
	if (action.type === SET_CARD_HEIGHT) {
		return {
			...state,
			cardHeight: action.payload,
		};
	}

	if (action.type === SET_EMBED_PARAMS) {
		return {
			...state,
			embed: action.payload,
		};
	}

	if (action.type === SET_STORY) {
		return {
			...state,
			embed: {
				...state.embed,
				autoHeight: action.payload.story.type !== 'standalone',
			},
		};
	}

	return state;
}
