/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prefer-stateless-function, react/no-multi-comp */
import React, { createRef, ComponentType } from 'react';
import produce from 'immer';
import _ from 'lodash';

import {
	COMPONENT_STATES,
	TRANSITION_TYPE,
	COMPONENT_TYPE,
	ANIMATION_STATE,
	ANIMATION_TRIGGER,
	DISABLED_ANSWER_ATTR,
} from 'common/constants';
import { isTouch } from 'common/utils/environment';
import { isLayerType } from 'common/utils/blocks/is-layer-type';
import type { BBUiConfig, BBEditableModeProps, BBSymbolInfo, BBCommonProps, ComponentTypes } from 'types/story';
import type { TransitionHandlerContextType } from 'client/components/common/CardTransition/TransitionHandler';
import { location } from 'common/utils/url-helper';
import { CardTransitionContext } from 'client/components/common/CardTransition';
import { ANIMATION_HOC_SENDER_ID, ANIMATION_SETTINGS_SENDER_ID } from 'client/constants/common';
import { getStateDOMAttrs } from 'client/components/common/BuildingBlocks/utils/common';
import SelectedComponentWatcher from 'client/components/common/SelectionHint/SelectedComponentWatcher';

import transitionSCSS from 'client/components/common/BuildingBlocks/Transition.scss';
import cardCSS from 'client/components/common/BuildingBlocks/Card/Card.scss';
import contentCSS from 'client/components/common/BuildingBlocks/Content/Content.scss';
import floatAboveCSS from 'client/components/common/BuildingBlocks/FloatAbove/FloatAbove.scss';

import {
	rafTicker,
	parseAnimation,
	AnimationData,
	AnimationSettingsEvent,
	AnimationEvEm,
	AnimationPlaybackEvent,
} from './animation';
import './BuildingBlock.scss';

// withCardTransitionContext > withExpose > withBuildingBlock > withAnimation > component

const TRANSITION_PRESET = {
	common: {
		[TRANSITION_TYPE.NONE]: {
			base: transitionSCSS.defaultBase,
			in: transitionSCSS.defaultIn,
			out: transitionSCSS.defaultOut,
		},
		[TRANSITION_TYPE.FADE]: {
			base: transitionSCSS.fadeBase,
			in: transitionSCSS.fadeIn,
			out: transitionSCSS.fadeOut,
		},
		[TRANSITION_TYPE.ZOOM]: {
			base: transitionSCSS.zoomBase,
			in: transitionSCSS.zoomIn,
			out: transitionSCSS.zoomOut,
		},
	},
	[COMPONENT_TYPE.CARD]: {
		[TRANSITION_TYPE.ZOOM]: {
			base: cardCSS.zoomBase,
			in: cardCSS.zoomIn,
			out: cardCSS.zoomOut,
		},
	},
	[COMPONENT_TYPE.CONTENT]: {
		[TRANSITION_TYPE.FADE]: {
			base: contentCSS.fadeBase,
			in: contentCSS.fadeIn,
			out: contentCSS.fadeOut,
		},
	},
	[COMPONENT_TYPE.FLOAT_ABOVE]: {
		[TRANSITION_TYPE.FADE]: {
			base: floatAboveCSS.fadeBase,
			in: floatAboveCSS.fadeIn,
			out: floatAboveCSS.fadeOut,
		},
		[TRANSITION_TYPE.ZOOM]: {
			base: floatAboveCSS.zoomBase,
			in: floatAboveCSS.zoomIn,
			out: floatAboveCSS.zoomOut,
		},
	},
	[COMPONENT_TYPE.FLOAT_BELOW]: {
		[TRANSITION_TYPE.FADE]: {
			base: floatAboveCSS.fadeBase,
			in: floatAboveCSS.fadeIn,
			out: floatAboveCSS.fadeOut,
		},
		[TRANSITION_TYPE.ZOOM]: {
			base: floatAboveCSS.zoomBase,
			in: floatAboveCSS.zoomIn,
			out: floatAboveCSS.zoomOut,
		},
	},
};

function isEditableMode(componentProps) {
	return !_.isEmpty(componentProps.editableModeProps);
}

function camelCaseStr(str: string) {
	if (str.includes('-')) {
		const arr = str.split('-');
		const capital = arr.map((item, index) =>
			index ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() : item.toLowerCase()
		);

		return capital.join('');
	}

	return str;
}

/**
 * HOC with basic functionality for all BuildingBlocks
 * @param {*} Component
 */
export const withBuildingBlock = Component => {
	const Block = withAnimation(Component);

	type Props = {
		type: string;
		uiConfig: BBUiConfig;
		children: ComponentType | ComponentType[];
		editableModeProps?: BBEditableModeProps;
		parentStates?: BBCommonProps['states'];
		getComputedComponentProps: BBCommonProps['getComputedComponentProps'];
	};

	type State = {
		states: BBCommonProps['states'];
	};

	return class WithBuildingBlock extends React.Component<Props, State> {
		static displayName = `withBuildingBlock(${Component.displayName || Component.name})`;

		static defaultProps = {
			editableModeProps: {},
			children: null,
		};

		constructor(props: Props, context: any) {
			super(props, context);

			this.onEditableMouseEnter = this.onEditableMouseEnter.bind(this);
			this.onEditableMouseLeave = this.onEditableMouseLeave.bind(this);
			this.onEditableMouseDown = this.onEditableMouseDown.bind(this);
			this.onEditableFocus = this.onEditableFocus.bind(this);
			this.onEditableMouseDownCapture = this.onEditableMouseDownCapture.bind(this);
			this.onDoubleClickCapture = this.onDoubleClickCapture.bind(this);

			// building block current states (hover, correct, etc.)
			const initialStates: State['states'] = _.reduce(
				_.omit(COMPONENT_STATES, ['DEFAULT']),
				(memo, value, key) => _.set(memo, value, false),
				{} as State['states']
			);

			this.state = {
				states: { ...initialStates, ...(props.parentStates ?? {}) },
			};
		}

		static getDerivedStateFromProps({ parentStates }: Props, state: State) {
			// Update states with a parentStates
			return parentStates ? { states: { ...state.states, ...parentStates } } : null;
		}

		onEditableMouseEnter(event: React.MouseEvent<Element, MouseEvent>) {
			_.invoke(this.props.editableModeProps, 'eventListeners.onMouseEnter', event, this.props);
		}

		onEditableMouseLeave(event: React.MouseEvent<Element, MouseEvent>) {
			_.invoke(this.props.editableModeProps, 'eventListeners.onMouseLeave', event);
		}

		onEditableMouseDown(event: React.MouseEvent<Element, MouseEvent>) {
			_.invoke(this.props.editableModeProps, 'eventListeners.onMouseDown', event, this.props);
		}

		onEditableFocus(event: React.FocusEvent<Element, Element>) {
			_.invoke(this.props.editableModeProps, 'eventListeners.onFocus', event, this.props);
		}

		onEditableMouseDownCapture(event: React.MouseEvent<Element, MouseEvent>) {
			_.invoke(this.props.editableModeProps, 'eventListeners.onMouseDownCapture', event, this.props);
		}

		onDoubleClickCapture(event: React.MouseEvent<Element, MouseEvent>) {
			_.invoke(this.props.editableModeProps, 'eventListeners.onDoubleClickCapture', event, this.props);
		}

		/**
		 * NOTE:
		 *   Unfortunately changing state doesn't update this.props.uiConfig.componentProps and after state changed
		 *   component will still receive computed props as it in default state.
		 *   This becomes a problem when some component inside of own code depends on "uiConfig.componentProps",
		 *   which are won't re-compute on state change.
		 *   (!) Think about, how to re-compute props for a component after his state is changed.
		 *
		 * NOTE:
		 *   Solution (!!!) -> see usage of this.props.getComputedComponentProps
		 */
		setStates = (states: State['states']) => {
			this.setState(prevState => ({ states: { ...prevState.states, ...states } }));
		};

		/**
		 * this.state.states converted as data attributes to assign onto DOM node
		 * @return {*}
		 */
		get stateAttrs() {
			if (_.isEmpty(this.state.states)) {
				console.error(`<${this.constructor.name} /> has no states assigned!`);
			}
			return getStateDOMAttrs(this.state.states);
		}

		get eventListeners(): BBCommonProps['eventListeners'] {
			const isUnselectable =
				this.props.type === COMPONENT_TYPE.CONTENT ||
				this.props.type === COMPONENT_TYPE.FLOAT_ABOVE ||
				this.props.type === COMPONENT_TYPE.FLOAT_BELOW;

			if (this.isEditableMode) {
				if (isUnselectable) {
					return undefined;
				}

				return {
					onMouseEnter: this.onEditableMouseEnter,
					onMouseLeave: this.onEditableMouseLeave,
					onMouseDown: this.onEditableMouseDown,
					onFocus: this.onEditableFocus,
					onMouseDownCapture: this.onEditableMouseDownCapture,
					onDoubleClickCapture: this.onDoubleClickCapture,
				};
			}

			const { HOVER, SELECTED, SELECTED_HOVER } = COMPONENT_STATES;
			const disabledAnswerAttr = `[${DISABLED_ANSWER_ATTR}="true"]`;

			// Non-editor listeners
			return {
				onMouseEnter: event => {
					if (isUnselectable || isTouch || (event.target as Element).closest(disabledAnswerAttr)) {
						return;
					}

					this.setState(
						produce(draft => {
							_.set(draft, ['states', HOVER], true);
							_.set(draft, ['states', SELECTED_HOVER], draft.states[SELECTED]);
						})
					);
				},
				onMouseLeave: e => {
					if (isUnselectable || isTouch) return;

					this.setState(
						produce(draft => {
							_.set(draft, ['states', HOVER], false);
							_.set(draft, ['states', SELECTED_HOVER], false);
						})
					);
				},
			};
		}

		get isEditableMode() {
			return isEditableMode(this.props);
		}

		render() {
			const bbProps = {
				states: this.state.states,
				setStates: this.setStates,
				stateAttrs: this.stateAttrs,
				eventListeners: this.eventListeners,
				isEditableMode: this.isEditableMode,
				uiConfig: this.props.uiConfig,
			};

			/*
			Re-compute component props for a current(not default) state

			IMPORTANT:
			 When `this.setStates` is called, traverse-tree won't be re-called, so `uiConfig.componentProps`
			 won't be re-computed for a current(new) state and will contain values computed for a `defaultState`.
			 Next lines of code are fixing this behaviour. Checking is there is active some state that is not "default",
			 and if yes, `componentProps` will be re-computed and result assigned to BB props
			 */
			if (!bbProps.isEditableMode) {
				const stateKeys = Object.keys(bbProps.states) as (keyof State['states'])[];
				const currentState = stateKeys.filter(key => bbProps.states[key])?.[0];
				if (currentState) {
					bbProps.uiConfig = produce(this.props.uiConfig, acc => {
						acc.componentProps = this.props.getComputedComponentProps(currentState).value;
					});
				}
			}

			// @ts-expect-error ts-migrate FIXME
			return <Block {...this.props} {...bbProps} />;
		}
	};
};

/**
 * HOC for animation functionality on BuildingBlocks
 * Animation data is parsed to regular css-animation keyframes,
 * which are appended to <style> next-to Component's resulting element. When animation is triggered, this HOC changes
 * props.uiConfig.nodeProps.style to start normal css-animation on Component's element.
 * For more details on animation concept see ./animation.js - parseAnimation description
 * Assumes Component attaches ref={props.containerRef} to its root HTMLElement
 *
 * @param {*} Component
 */
export const withAnimation = Component => {
	type Props = Omit<BBCommonProps, 'isEmbed'> & { animation?: Record<string, any> };

	type AnimationDataRecord = Record<string, AnimationData>;

	type ParsedAnimationDataRecord = Record<string, ReturnType<typeof parseAnimation>>;

	type State = {
		currentAnimation: string;
		animationState: Record<string, (typeof ANIMATION_STATE)[keyof typeof ANIMATION_STATE]>;
		animationDataRaw?: AnimationDataRecord;
		animationData: ParsedAnimationDataRecord;
	};

	return class WithAnimation extends React.Component<Props, State> {
		static displayName = `withAnimation(${Component.displayName || Component.name})`;

		static defaultProps = {
			animation: null,
			eventListeners: undefined,
			editableModeProps: {},
		};

		static getDerivedStateFromProps(props: Props, state: State) {
			const animation = _.get(props, 'uiConfig.componentProps.animation') as AnimationDataRecord | undefined;
			if (animation !== state.animationDataRaw) {
				return {
					animationDataRaw: animation,
					animationData: _.reduce(
						animation,
						(acc, current: AnimationData, key: string) => {
							acc[key] = parseAnimation(current, `animation-${key}-${props._id}`);
							return acc;
						},
						{}
					),
				};
			}
			return null;
		}

		containerRef = createRef<HTMLElement>();

		delayedEventTimeouts: any;

		isEmbed: boolean;

		constructor(props: Props) {
			super(props);

			this.delayedEventTimeouts = {};

			const animationDataRaw = _.get(props, 'uiConfig.componentProps.animation') as
				| AnimationDataRecord
				| undefined;

			const animationData = _.reduce(
				animationDataRaw,
				(acc, current, key) => {
					acc[key] = parseAnimation(current, `animation-${key}-${props._id}`);
					return acc;
				},
				{} as ParsedAnimationDataRecord
			);

			this.isEmbed =
				location.client.isEmbedDevPreview ||
				(window.parent !== window && !this.isEditableMode && !location.client.isPreview);

			const defaultTrigger = [
				ANIMATION_TRIGGER.ON_LOAD,
				ANIMATION_TRIGGER.ON_CARD_EXPOSE_START,
				ANIMATION_TRIGGER.ON_CARD_EXPOSE_COMPLETE,
				ANIMATION_TRIGGER.ON_VIEWPORT,
				ANIMATION_TRIGGER.ON_HOVER,
				ANIMATION_TRIGGER.ON_CLICK,
			].filter(trigger => !!_.find(animationData, { trigger }))[0];

			const currentAnimation = defaultTrigger
				? // @ts-expect-error ts-migrate FIXME
					_.find(animationData, { trigger: defaultTrigger }).id
				: Object.keys(animationData || {})[0];

			this.state = {
				currentAnimation: this.isEditableMode ? '' : currentAnimation,
				animationDataRaw, // eslint-disable-line react/no-unused-state
				animationData,
				animationState: _.reduce(
					animationData,
					(acc, data, key) => {
						acc[key] = data && !this.isEditableMode ? ANIMATION_STATE.NOT_STARTED : ANIMATION_STATE.NONE;
						return acc;
					},
					{}
				),
			};
		}

		componentDidMount() {
			if (!this.isEditableMode && this.animationData) {
				_.forEach(this.animationData, (animationData, triggerId) => {
					if (animationData === null) {
						return;
					}

					const { trigger } = animationData;
					const isCustomTrigger = !_.values(ANIMATION_TRIGGER).some(t => t === animationData.trigger);

					if (trigger) {
						if (trigger === ANIMATION_TRIGGER.ON_LOAD) {
							this.startAnimation(triggerId);
						}
						if (trigger === ANIMATION_TRIGGER.ON_VIEWPORT) {
							if (this.containerRef.current) {
								// listen
								this.scrollListeners.add();

								// handle case when initially "in view"
								this.onWindowScroll();
							} else {
								const componentType = (this.props as any).type;
								throw new Error(
									`withAnimation HOC — wrapped ${componentType} refferecne error.
								${componentType} should attach 'props.containerRef' to its root HTMLElement`
								);
							}
						}
						if (
							isCustomTrigger ||
							trigger === ANIMATION_TRIGGER.ON_CARD_EXPOSE_START ||
							trigger === ANIMATION_TRIGGER.ON_CARD_EXPOSE_COMPLETE
						) {
							if (trigger === triggerId) return;

							window.addEventListener(trigger, () => this.startAnimation(triggerId));
						}
					}
				});
			}

			if (this.animationData) {
				AnimationEvEm.addListener('playback', this.animationEvEmPlaybackListener);
			}

			// receiver message from Editor (run animation preview)
			window.addEventListener('message', this.onWindowMessage);
		}

		componentWillUnmount() {
			_.forEach(this.delayedEventTimeouts, timeouts => {
				_.forEach(timeouts, timeoutID => rafTicker.clearTimeout(timeoutID));
			});

			this.scrollListeners.remove();
			window.removeEventListener('message', this.onWindowMessage);
			AnimationEvEm.removeListener('playback', this.animationEvEmPlaybackListener);

			_.forEach(this.animationData, animationData => {
				if (_.get(animationData, 'trigger')) {
					window.removeEventListener<any>(animationData?.trigger, this.startAnimation);
				}
			});
		}

		get animationData() {
			return this.state.animationData;
		}

		get isEditableMode() {
			return isEditableMode(this.props);
		}

		get scrollListeners() {
			const events = ['scroll', 'pageScroll'];
			return {
				add: () => events.forEach(ev => window.addEventListener(ev, this.onWindowScroll)),
				remove: () => events.forEach(ev => window.removeEventListener(ev, this.onWindowScroll)),
			};
		}

		animationEvEmPlaybackListener = (event: AnimationPlaybackEvent) => {
			Object.values(this.animationData ?? {}).forEach(animation => {
				if (!animation) return;
				const { trigger, id } = animation;
				const startAnimation = () => this.startAnimation(id);
				switch (event.data.action) {
					case 'onMultipleAnswerSubmit':
					case 'onFormSubmit':
						if (trigger === ANIMATION_TRIGGER.ON_SUBMIT) startAnimation();
						break;
					case 'onAnswer':
					case 'onMultipleAnswerSelect': {
						const { isCorrect = false, answerNodeId = null } = event.data;
						const triggerConditions = {
							[ANIMATION_TRIGGER.ON_CARD_ANSWER]: true,
							[ANIMATION_TRIGGER.ON_CARD_ANSWER_CORRECT]: isCorrect,
							[ANIMATION_TRIGGER.ON_CARD_ANSWER_INCORRECT]: !isCorrect,
						};

						if (triggerConditions[trigger]) {
							const currentElement = this.containerRef.current ?? null;
							const selectedAnswer = answerNodeId ? document.getElementById(answerNodeId) : null;
							const isSelectedAnswerChild = selectedAnswer?.contains(currentElement);
							const isAnswerChild = currentElement?.closest(`[data-bb="${COMPONENT_TYPE.ANSWER}"]`);

							if (isSelectedAnswerChild || !isAnswerChild) {
								startAnimation();
							}
						}
						break;
					}
					default:
						break;
				}
			});
		};

		getCurrentAnimationStyles = () => {
			const { currentAnimation, animationState } = this.state;
			const animationData = this.animationData[currentAnimation];
			let style: { [key: string]: string } | undefined;

			if (!animationData) {
				return style;
			}

			const { name, reverse, duration, loop, sortedKeyframes } = animationData;

			switch (animationState[currentAnimation]) {
				case ANIMATION_STATE.NOT_STARTED:
					style = _.first(sortedKeyframes)?.[1];
					break;
				case ANIMATION_STATE.PLAYING: // const animationData = animationData;
					style = {
						..._.first(sortedKeyframes)?.[1],
						animationName: name,
						animationDuration: `${parseFloat(`${duration}`) * (reverse ? 0.5 : 1)}ms`,
						animationTimingFunction: 'linear', // it should always be linear
						animationFillMode: 'both',
						animationIterationCount: `${loop ? Number.MAX_VALUE : reverse ? 2 : 1}`,
						animationDirection: reverse ? 'alternate' : 'normal',
					};
					break;
				case ANIMATION_STATE.COMPLETE:
					if (reverse) {
						style = _.first(sortedKeyframes)?.[1];
					} else {
						style = _.last(sortedKeyframes)?.[1];
					}
					break;
				case ANIMATION_STATE.NONE:
					style = {
						transform: '',
						opacity: '',
					};
					break;
				default:
					break;
			}

			if (style) {
				style = Object.entries(style).reduce((acc, [key, value]) => {
					acc[camelCaseStr(key)] = value;
					return acc;
				}, {});
			}

			return style;
		};

		getEventListeners = () => {
			const listeners: Partial<BBCommonProps['eventListeners']> = {};

			_.forEach(this.animationData, animationData => {
				if (animationData && !this.isEditableMode) {
					if (animationData.trigger === ANIMATION_TRIGGER.ON_CLICK) {
						listeners.onClick = this.onClick;
					}
					if (animationData.trigger === ANIMATION_TRIGGER.ON_HOVER) {
						listeners.onMouseEnter = this.onMouseEnter;
						listeners.onMouseLeave = this.onMouseLeave;
					}
				}
			});

			return listeners;
		};

		startAnimation = async (trigger: string, p = {}) => {
			const animationState = this.state.animationState[trigger];
			const animationTriggerData = this.animationData[trigger];

			if (!animationTriggerData) {
				return;
			}

			const triggerName = animationTriggerData.trigger;
			const { ON_CARD_EXPOSE_START, ON_CARD_EXPOSE_COMPLETE } = ANIMATION_TRIGGER;

			// Prevent call of expose animation triggers on card expose OUT
			if (
				(triggerName === ON_CARD_EXPOSE_START || triggerName === ON_CARD_EXPOSE_COMPLETE) &&
				(animationState === 'COMPLETE' || animationState === 'PLAYING')
			) {
				return;
			}

			const defaultParams: { mode: 'normal' | 'preview' } = { mode: 'normal' };
			const params = { ...defaultParams, ...p };

			if (!this.delayedEventTimeouts[trigger]) this.delayedEventTimeouts[trigger] = [];
			_.forEach(this.delayedEventTimeouts[trigger], timeoutID => rafTicker.clearTimeout(timeoutID));

			if (this.state.animationState[trigger] === ANIMATION_STATE.PLAYING) {
				await new Promise<void>(resolve =>
					this.setState(
						state => ({
							animationState: { ...state.animationState, [trigger]: ANIMATION_STATE.NOT_STARTED },
						}),
						resolve
					)
				);
			}

			this.setState({ currentAnimation: trigger }, () => {
				const delayTimeoutId = rafTicker.setTimeout(() => {
					_.forEach(animationTriggerData.events, event => {
						const timeoutID = rafTicker.setTimeout(() => {
							window.dispatchEvent(new Event(event.eventId));
						}, event.delay);
						this.delayedEventTimeouts[trigger].push(timeoutID);
					});

					this.setState(state => ({
						animationState: { ...state.animationState, [trigger]: ANIMATION_STATE.PLAYING },
					}));

					this.delayedEventTimeouts[trigger].push(
						rafTicker.setTimeout(() => {
							const targetState =
								params.mode === 'normal'
									? animationTriggerData.loop
										? ANIMATION_STATE.PLAYING
										: ANIMATION_STATE.COMPLETE
									: ANIMATION_STATE.NONE;

							this.setState(
								state => ({
									animationState: { ...state.animationState, [trigger]: targetState },
								}),
								() => {
									window.parent.postMessage(
										{
											sender: ANIMATION_HOC_SENDER_ID,
											type: 'complete',
											trigger,
											elementId: this.props._id,
										} as AnimationSettingsEvent,
										'*'
									);
								}
							);
						}, animationTriggerData.duration)
					);
				}, animationTriggerData.delay);

				this.delayedEventTimeouts[trigger].push(delayTimeoutId);
			});
		};

		stopAnimation = (trigger: string) => {
			_.forEach(this.delayedEventTimeouts, timeouts => {
				_.forEach(timeouts, timeoutID => rafTicker.clearTimeout(timeoutID));
			});

			this.setState(state => ({
				animationState: { ...state.animationState, [trigger]: ANIMATION_STATE.NONE },
			}));
		};

		onWindowMessage = ({ data: e }: { data: AnimationSettingsEvent }) => {
			if (e.sender === ANIMATION_SETTINGS_SENDER_ID && e.elementId === this.props._id) {
				switch (e.type) {
					case 'start':
						this.startAnimation(e.trigger, { mode: 'preview' });
						break;

					case 'stop':
						this.stopAnimation(e.trigger);
						break;

					default:
						break;
				}
			}
		};

		onMouseEnter = event => {
			_.invoke(this.props, 'eventListeners.onMouseEnter', event);
			_.invoke(this.props, 'uiConfig.nodeProps.onMouseEnter', event);

			const animation = _.find(this.animationData, {
				trigger: ANIMATION_TRIGGER.ON_HOVER,
			});

			if (animation) {
				this.startAnimation(animation.id);
			}
		};

		onMouseLeave = event => {
			_.invoke(this.props, 'eventListeners.onMouseLeave', event);
			_.invoke(this.props, 'uiConfig.nodeProps.onMouseLeave', event);

			const animation = _.find(this.animationData, {
				trigger: ANIMATION_TRIGGER.ON_HOVER,
			});

			if (animation) {
				this.stopAnimation(animation.id);
			}
		};

		onClick = event => {
			_.invoke(this.props, 'eventListeners.onClick', event);
			_.invoke(this.props, 'uiConfig.nodeProps.onClick', event);

			const animation = _.find(this.animationData, {
				trigger: ANIMATION_TRIGGER.ON_CLICK,
			});

			if (animation) {
				this.startAnimation(animation.id);
			}
		};

		onWindowScroll = (e?: Event) => {
			const container = this.containerRef.current;

			if (container) {
				const vpH = this.isEmbed ? _.get(e, 'detail.viewport.h', window.innerHeight) : window.innerHeight;
				const bBox = container.getBoundingClientRect();
				const pagePosTop = _.get(e, 'detail.containerPosTop', 0);
				const controlPoint = pagePosTop + bBox.y + bBox.height;

				if (controlPoint >= 0 && controlPoint <= vpH) {
					const animation = _.find(this.animationData, { trigger: ANIMATION_TRIGGER.ON_VIEWPORT });

					if (animation) this.startAnimation(animation.id);

					this.scrollListeners.remove();
				}
			}
		};

		render() {
			const { currentAnimation } = this.state;
			const keyframes = _.get(this.animationData[currentAnimation], 'style');
			const style = this.getCurrentAnimationStyles();
			const eventListeners = this.getEventListeners();
			const bBProps = produce(
				{
					uiConfig: _.get(this.props, 'uiConfig'),
					eventListeners: { ...this.props.eventListeners, ...eventListeners },
					isEmbed: this.isEmbed,
				},
				draft => {
					_.set(
						draft,
						'uiConfig.nodeProps.style',
						_.merge({}, _.get(draft, 'uiConfig.nodeProps.style'), style)
					);
					_.set(draft, 'uiConfig.nodeProps.onClick', eventListeners.onClick);
					_.set(draft, 'uiConfig.nodeProps.onMouseEnter', eventListeners.onMouseEnter);
				}
			);

			return (
				<>
					{keyframes && <style>{keyframes}</style>}
					<Component {...this.props} {...bBProps} containerRef={this.containerRef} />
				</>
			);
		}
	};
};

/**
 * HOC for supporting CSSTransition between cards.
 * Each BuildingBlock receives callback which should be called when its transition finished.
 * Every parent creates its own one and passes to children,
 * only when all children finish their transitions — callback is called
 *
 * @param {*} Component
 */
export const withExpose = Component => {
	const Block = withBuildingBlock(Component);

	type Props = {
		type: string;
		in?: boolean;
		isCardTree?: boolean;
		exposeDuration?: number;
		exposeEffect?: string;
		children?: React.ReactNode;
		onTransitionEnd?: (...args: any[]) => any;
		editableModeProps?: BBEditableModeProps;
	};

	return class WithExpose extends React.Component<Props> {
		static displayName = `withExpose(${Component.displayName || Component.name})`;

		static defaultProps = {
			in: undefined,
			isCardTree: false,
			exposeDuration: 0,
			exposeEffect: '',
			children: '',
			onTransitionEnd: _.noop,
			editableModeProps: {},
		};

		childrenTransitions: any;

		constructor(props, context) {
			super(props, context);

			this.childrenTransitions = this.resetChildrenTransition();
		}

		componentDidUpdate(prevProps) {
			if (this.props.in !== prevProps.in) {
				this.childrenTransitions = this.resetChildrenTransition();
				if (!_.get(this.props, 'exposeEffect') && !_.size(this.childrenTransitions)) {
					_.invoke(this.props, 'onTransitionEnd', this.props);
				}
			}
		}

		onChildTransitionEnd = ({ _id, type }) => {
			if (this.childrenTransitions[_id]) {
				return;
			}

			this.childrenTransitions[_id] = true;

			if (_.values(this.childrenTransitions).every(isComplete => !!isComplete)) {
				// @ts-expect-error ts-migrate FIXME
				this.props.onTransitionEnd(this.props);
			}
		};

		get isEditableMode() {
			return isEditableMode(this.props);
		}

		get transitionCSS() {
			const { type, in: inProp } = this.props;
			const expose = this.props.exposeEffect || TRANSITION_TYPE.FADE;
			const preset = _.merge(
				{},
				_.get(TRANSITION_PRESET, ['common', expose]),
				_.get(TRANSITION_PRESET, [type, expose])
			);
			const transitionBaseCSS = preset.base;
			const transitionTypeCSS = preset[inProp ? 'in' : 'out'];
			const transitionCSS = { [transitionBaseCSS]: true, [transitionTypeCSS]: inProp !== undefined };

			return !this.isEditableMode && this.props.isCardTree ? transitionCSS : null;
		}

		resetChildrenTransition = () => {
			return _.reduce(
				// @ts-expect-error ts-migrate FIXME
				this.props.children,
				(acc, child) => {
					// @ts-expect-error ts-migrate FIXME
					if (_.get(child, 'props._id')) acc[child.props._id] = false;
					return acc;
				},
				{}
			);
		};

		renderChildren(): ComponentType | any {
			const { children } = this.props;
			const props = { onTransitionEnd: this.onChildTransitionEnd };

			if (typeof children !== 'string') {
				// @ts-expect-error ts-migrate FIXME
				return React.Children.map(children, child => React.cloneElement(child, props));
			}

			return children;
		}

		render() {
			const props = {
				...this.props,
				transitionCSS: this.transitionCSS,
				resetChildrenTransition: this.resetChildrenTransition,
			};

			/* @ts-expect-error ts-migrate FIXME */
			return <Block {...props}>{this.renderChildren()}</Block>;
		}
	};
};

export default function withCardTransitionContext<P>(Component: React.ComponentType<P & TransitionHandlerContextType>) {
	type Props = {
		_id: string;
		type: ComponentTypes;
		uiConfig: BBUiConfig;
		editableModeProps?: BBEditableModeProps;
		isCardTree: boolean;
		symbol?: BBSymbolInfo;
	};

	const Block = withExpose(Component);

	function WithCardTransitionContext(props: Omit<P & Props, keyof TransitionHandlerContextType>) {
		if (isLayerType(props).global && props.isCardTree) {
			/*
			 Global elements are rendered by StoryElements component where isCardTree is false.
			 In card tree stored only references to global elements. We should not to render them.
			 References are stored there to help to manage theirs z-index related to card.
			 */
			return null;
		}

		return (
			<>
				{isEditableMode(props) && props.editableModeProps && (
					<SelectedComponentWatcher
						_id={props._id}
						type={props.type}
						uiConfig={props.uiConfig}
						editableModeProps={props.editableModeProps}
						symbol={props.symbol}
					/>
				)}
				<CardTransitionContext.Consumer>
					{context => <Block in={context.in} onTransitionEnd={context.onTransitionEnd} {...props} />}
				</CardTransitionContext.Consumer>
			</>
		);
	}

	WithCardTransitionContext.displayName = `WithCardTransitionContext(${Component.displayName || Component.name})`;

	return WithCardTransitionContext;
}

// connect > withCardTransitionContext > withExpose > withBuildingBlock > WithAnimation
