import _ from 'lodash';
import produce from 'immer';
import { nanoid } from 'nanoid';

import type { SetStoryPayload } from 'client/actions/set-story';
import type { GetUserDataReturnResult, GameResponse } from 'client/actions/api';
import { API, SET_FORM_DATA, SET_OFFLINE_MODE, SET_STORY, UPDATE_ANSWERS } from 'client/constants/actions';
import type { UpdateUserAnswersPayload } from 'client/actions/update-answers';
import type { SetFormDataPayload } from 'client/actions/set-form-data';
import { getAvatar } from './avatar';

type UserState = {
	isRegistered: boolean;
	isOffline: boolean;
	storyPageViewId: null | string;
	answers: {
		[cardId: string]:
			| {
					selected: string[];
					votes?: { [answerId: string]: number /* percentage to total */ };
					data?: Partial<{
						// answer extra data
						[answerId: string]: {
							textContent?: string;
							embedCode?: string;
						};
					}>;
					submitted?: boolean; // whether card with multiple answers has been submitted
			  }
			| undefined;
	};
	// submitted forms data
	form: {
		[cardId: string]: SetFormDataPayload['data'];
	};
	email?: string;
	google?: {
		email: string;
		id: string;
		name: string;
		picture: string;
	};
	facebook?: { id: string };
	sms?: {
		id: string;
		phone: string;
		picture: string;
	};
	guest?: boolean;
	id?: string;
	country?: string;
	organizationId?: string;
	provider?: 'google' | 'sms';

	// available only if "oncePerUser: true"
	checkpoint?: {
		cardId: string;
		date: string;
	};
	gameResponse?: {
		[cardId: string]: 1;
	};
};

const initialState: UserState = {
	isRegistered: false,
	isOffline: false,
	storyPageViewId: null,
	answers: {},
	form: {},
};

type ActionTypes =
	| { type: typeof UPDATE_ANSWERS; payload: UpdateUserAnswersPayload }
	| { type: typeof SET_FORM_DATA; payload: SetFormDataPayload }
	| { type: typeof API.GET_USER_DATA.SUCCESS; payload: GetUserDataReturnResult }
	| { type: typeof API.REGISTER_GUEST.SUCCESS; payload: boolean | object }
	| { type: typeof SET_OFFLINE_MODE; payload: boolean }
	| { type: typeof SET_STORY; payload: SetStoryPayload }
	| { type: typeof API.GET_GAME.SUCCESS; payload: GameResponse }
	| { type: typeof API.START_GAME.SUCCESS; payload: GameResponse };

export default function userReducer(state = initialState, { type, payload }: ActionTypes): UserState {
	return produce(state, draft => {
		switch (type) {
			case API.GET_USER_DATA.SUCCESS:
				if (typeof payload === 'object') {
					draft.isRegistered = _.has(payload, 'id');

					Object.keys(payload).forEach(key => {
						draft[key] = payload[key];
					});

					if ('provider' in payload && payload.provider === 'sms') {
						draft.sms = {
							id: payload.id,
							phone: payload.phone,
							picture: getAvatar(),
						};
					}
				}
				break;

			case API.REGISTER_GUEST.SUCCESS:
				_.set(draft, 'isRegistered', true);
				break;

			case SET_OFFLINE_MODE:
				_.set(draft, 'isOffline', payload);
				break;

			case SET_STORY:
				_.set(draft, 'storyPageViewId', nanoid());
				break;

			case API.GET_GAME.SUCCESS:
			case API.START_GAME.SUCCESS:
				if (payload?.game) {
					_.set(draft, 'storyPageViewId', payload.game.pageViewId);
					_.set(draft, 'checkpoint', payload.game.checkpoint);
					_.set(draft, 'gameResponse', payload.game.response);
				}
				break;

			case SET_FORM_DATA:
				draft.form = {
					...draft.form,
					[payload.cardId]: payload.data,
				};
				break;

			case UPDATE_ANSWERS: {
				const cardAnswers = {
					...draft.answers[payload.cardId],
					selected: draft.answers[payload.cardId]?.selected || [],
				};

				const isSortablePoll = 'answerIds' in payload;

				if (isSortablePoll) {
					cardAnswers.selected = payload.answerIds;
					cardAnswers.data = { ...cardAnswers.data, [payload.answerId]: payload.data };
				} else if ('answerId' in payload) {
					cardAnswers.selected = cardAnswers.selected.includes(payload.answerId)
						? cardAnswers.selected.filter(answerId => answerId !== payload.answerId) // exclude
						: [...cardAnswers.selected, payload.answerId]; // add

					if ('data' in payload) {
						const hasAnswerData = cardAnswers.data && cardAnswers.data[payload.answerId] !== undefined;
						if (hasAnswerData) delete cardAnswers.data![payload.answerId];
						else cardAnswers.data = { ...cardAnswers.data, [payload.answerId]: payload.data };
					}
				}

				draft.answers[payload.cardId] = {
					...cardAnswers,
					submitted: 'submitted' in payload ? payload.submitted : cardAnswers.submitted,
					votes: 'votes' in payload ? payload.votes : cardAnswers.votes,
				};

				break;
			}

			default:
				break;
		}
	});
}
