import type { DictionaryType } from 'types/story';
import {
	COMPONENT_STATES,
	COMPONENT_TYPE as TYPE,
	DEFAULT_MEDIA_QUERY_PLATFORMS,
	LAYER_TYPE,
	COMPONENT_DISPLAY_NAME,
} from 'common/constants';
import { CSS_PROPS, UNIFORM_PROPS } from 'common/constants/component-props';
import { getText } from 'utils/dictionary';
import { generateElementId, generateNodeId } from 'utils/generate-id';
import View from 'client/components/common/BuildingBlocks/Fields/Checkbox/Checkbox';
import { FieldBBErrorType } from '../constants';

type Props = {
	dictionary?: DictionaryType;
};

export const CHECKBOX = ({ dictionary = {} }: Props = {}) => {
	const _id = generateElementId();

	return {
		view: View,
		get model() {
			const type = TYPE.CHECKBOX;

			return {
				_id,
				type,
				uiConfig: {
					layer: LAYER_TYPE.CONTENT,
					editorProps: {
						name: COMPONENT_DISPLAY_NAME[type],
						selectable: true,
						draggable: true,
						expandable: false,
						expanded: false,
						[UNIFORM_PROPS.fieldErrorShow]: FieldBBErrorType.required,
					},

					componentProps: {
						styles: {
							[COMPONENT_STATES.DEFAULT]: {
								[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]: {
									[CSS_PROPS.field.style.box.stroke]: 'rgba(204, 204, 204, 1)',
									[CSS_PROPS.field.style.box.fill]: 'rgba(255, 255, 255, 1)',
								},
							},
							[COMPONENT_STATES.HOVER]: {
								[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]: {
									[CSS_PROPS.field.style.box.stroke]: 'rgba(77, 156, 255, 1)',
									[CSS_PROPS.field.style.box.fill]: 'rgba(255, 255, 255, 1)',
								},
							},
							[COMPONENT_STATES.SELECTED]: {
								[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]: {
									[CSS_PROPS.field.style.box.stroke]: 'rgba(77, 156, 255, 1)',
									[CSS_PROPS.field.style.box.fill]: 'rgba(77, 156, 255, 1)',
									[CSS_PROPS.field.style.box.iconColor]: 'rgba(255, 255, 255, 1)',
								},
							},
						},
						other: {},
					},
					nodeProps: {
						id: generateNodeId(_id, type),
					},
				},
				children: {
					[COMPONENT_STATES.DEFAULT]: {
						[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]: getText(dictionary, type, 'text'),
					},
				},
			};
		},
	};
};
