/* eslint-disable react/no-unused-state */
import React, { RefObject } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import cn from 'classnames';
import { createPortal } from 'react-dom';
import { get } from 'lodash';

import { location } from 'common/utils/url-helper';
import type { BBCommonProps, BBEditableModeProps, CardData } from 'types/story';
import PhoneIcon from 'common/components/Icon/Phone';
import type { ClientReducerState } from 'client/reducers';
import { sendForm } from 'client/actions/api';
import { selectStory } from 'client/reducers/story/selectors';
import { getPhoneVerificationCode, verifyPhoneCode } from 'client/actions';
import { isPhoneNumber } from 'client/components/common/StoryCard/Form/validations';
import { EventProviderContextT, withEventProvider } from 'client/components/pages/Story/EventProvider/Context';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import { phoneToE164 } from 'client/components/common/PhoneInput/common';

import translations from './translations.json';
import css from './SMSLogin.scss';

const { isPreview } = location.client;

const PhoneInput = React.lazy(() => import('client/components/common/PhoneInput'));

const connector = connect(
	(state: ClientReducerState) => {
		const { type: cardType, _id: cardId } = state.card as CardData;
		const story = selectStory(state)!;
		const { user } = state;

		return {
			cardSettings: story.storyVersions.latest.settings.cards?.[cardId],
			storyId: story.id,
			story,
			cardType,
			user,
			cardId,
			organizationId: story.organizationId,
		};
	},
	{
		sendForm,
		getPhoneVerificationCode,
		verifyPhoneCode,
	}
);

enum POP_UP_VIEW {
	PHONE_INPUT = 'PHONE_INPUT',
	VERIFICATION_CODE_INPUT = 'VERIFICATION_CODE_INPUT',
	SUCCESS = 'SUCCESS',
}

type State = {
	phone: string;
	phoneE164Format: string;
	confirmationCode: string[];
	popUpView?: POP_UP_VIEW;
	error: string;
};

type Props = BBCommonProps &
	ConnectedProps<typeof connector> &
	EventProviderContextT & { className?: string; children?: string };

const CODE_DIGITS_AMOUNT = 6;

class SMSLogin extends React.Component<Props, State> {
	digitInputRefs = Array.from({ length: CODE_DIGITS_AMOUNT }).map(o => React.createRef<HTMLInputElement>());

	state: State = {
		phone: '',
		phoneE164Format: '',
		confirmationCode: Array.from({ length: CODE_DIGITS_AMOUNT }).map(o => ''),
		popUpView: undefined,
		error: '',
	};

	componentDidMount() {
		if (this.props.user.provider && !isPreview && !this.props.isEditableMode) {
			this.props.eventProvider.events.flow(this.props._id, { reload: false });
		}
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		if (
			this.state.popUpView !== prevState.popUpView &&
			this.state.popUpView === POP_UP_VIEW.VERIFICATION_CODE_INPUT
		) {
			this.digitInputRefs[0].current?.focus();
		}
	}

	setPopUpView = (view?: POP_UP_VIEW) => {
		this.setState({
			popUpView: view,
			error: '',
		});
	};

	onEditableFocus = (event: React.FocusEvent) => {
		// prevent this block from being selected when clicking on its parent
		event.stopPropagation();
	};

	handleLogin = async (event: React.MouseEvent<HTMLLinkElement | HTMLButtonElement>) => {
		this.props.eventListeners?.onClick?.(event);

		if (isPreview) {
			this.props.eventProvider.events.flow(this.props._id, { reload: true });
			return;
		}

		this.setPopUpView(POP_UP_VIEW.PHONE_INPUT);
	};

	get eventListeners() {
		if (this.props.isEditableMode) {
			return {
				...this.props.eventListeners,
				onFocus: this.onEditableFocus,
			};
		}

		return {
			...this.props.eventListeners,
			onClick: this.handleLogin,
		};
	}

	get texts() {
		const { story } = this.props;
		return translations[story.language];
	}

	translate = (path: string, options: Record<string, string> = {}) => {
		let str: string = get(this.texts, path, path);
		Object.entries(options).forEach(([key, value]) => {
			str = str.replaceAll(`{{ ${key} }}`, value);
		});

		return str;
	};

	onCodeDigitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const {
			dataset: { index },
			value,
		} = e.currentTarget;
		const { confirmationCode: currentConfirmationCode } = this.state;

		if (index) {
			this.setState(
				state => {
					const confirmationCode = [...state.confirmationCode];

					if (value.length <= 1) {
						confirmationCode[+index - 1] = value;
					} else {
						confirmationCode.splice(+index - 1, 0, ...value.split(''));
					}

					return { confirmationCode };
				},
				() => {
					if (value) {
						const { confirmationCode } = this.state;
						const nextIndex = Math.min(confirmationCode.join('').length, CODE_DIGITS_AMOUNT - 1);

						if (!currentConfirmationCode[nextIndex]) {
							const nextDigit = this.digitInputRefs[nextIndex]?.current;
							nextDigit?.focus();
						}
					} else {
						const prevIndex = Math.max(0, +index - 2);
						const prevDigit = this.digitInputRefs[prevIndex]?.current;
						prevDigit?.focus();
					}
				}
			);
		}
	};

	onCodeDigitKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const { index } = e.currentTarget.dataset;

		if (index && e.key === 'Backspace') {
			if (!this.digitInputRefs[+index - 1]?.current?.value) {
				const digit = this.digitInputRefs[+index - 2]?.current;
				digit?.select();
			}
		} else if (index) {
			if (this.digitInputRefs[+index - 1]?.current?.value) {
				const digit = this.digitInputRefs[+index]?.current;
				digit?.select();
			}
		}
	};

	onCodeDigitFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { index } = e.currentTarget.dataset;

		if (index) {
			const digit = this.digitInputRefs[+index - 1]?.current;
			digit?.select();
		}
	};

	onSubmitPhone = async () => {
		const { organizationId } = this.props;
		const { phoneE164Format } = this.state;

		const response = await this.props.getPhoneVerificationCode({
			organizationId,
			phone: phoneE164Format.replace('+', ''),
		});
		if (response.body?.success) {
			this.setPopUpView(POP_UP_VIEW.VERIFICATION_CODE_INPUT);
		} else {
			this.setState({ error: response.errors[0]?.message || 'Something went wrong' });
		}
	};

	onSubmitCode = async () => {
		const { organizationId } = this.props;
		const { phoneE164Format, confirmationCode } = this.state;

		const response = await this.props.verifyPhoneCode({
			organizationId,
			phone: phoneE164Format.replace('+', ''),
			code: confirmationCode.join(''),
		});

		if (response?.text === 'OK' || response?.body?.success) {
			this.setPopUpView(POP_UP_VIEW.SUCCESS);
		} else {
			this.setState({ error: response.errors[0]?.message || 'Something went wrong' });
		}
	};

	onRetry = () => {
		this.setState({ confirmationCode: Array.from({ length: CODE_DIGITS_AMOUNT }).map(o => '') });
		this.setPopUpView(POP_UP_VIEW.PHONE_INPUT);
	};

	onVerificationDone = async () => {
		this.props.eventProvider.events.flow(this.props._id, { reload: true });
	};

	onPopUpCloseClick = () => {
		this.setPopUpView(undefined);
	};

	renderPopUp() {
		const { popUpView } = this.state;

		switch (popUpView) {
			case POP_UP_VIEW.PHONE_INPUT:
				return this.renderPhoneInput();

			case POP_UP_VIEW.VERIFICATION_CODE_INPUT:
				return this.renderVerificationCodeInput();

			case POP_UP_VIEW.SUCCESS:
				return this.renderSuccess();

			default:
				return null;
		}
	}

	renderPhoneInput() {
		const { error } = this.state;
		const title = this.translate('popUp.phoneNumber.title');
		const description = this.translate('popUp.phoneNumber.description');
		const phoneInputLabel = this.translate('popUp.phoneNumber.phoneNumber');
		const submitLabel = this.translate('popUp.phoneNumber.next');

		return (
			<div className={css.popUpWrap}>
				<div className={css.bg} />
				<div className={css.popUp}>
					<button className={css.closeBtn} onClick={this.onPopUpCloseClick} type="button" />
					<p className={css.popUpTitle}>{title}</p>
					<p className={css.popUpDescription}>{description}</p>
					<p className={css.phoneInputLabel}>{phoneInputLabel}</p>
					<div className={css.phoneInput}>
						<React.Suspense fallback={null}>
							<PhoneInput
								value={this.state.phone}
								onChange={(phone, country) => {
									this.setState({
										phone,
										phoneE164Format: phoneToE164(`+${country.dialCode}${phone}`),
									});
								}}
							/>
						</React.Suspense>
						{error && (
							<div className={css.errorWrap}>
								<p className={css.error}>{error}</p>
							</div>
						)}
					</div>
					<button
						className={css.submitBtn}
						disabled={!isPhoneNumber(this.state.phoneE164Format)}
						onClick={this.onSubmitPhone}
						type="button"
					>
						{submitLabel}
					</button>
				</div>
			</div>
		);
	}

	renderVerificationCodeInput() {
		const { phoneE164Format, error } = this.state;
		const maskedPhone = `<span dir="ltr">${phoneE164Format.substring(0, 3)}****${phoneE164Format.substring(
			7
		)}</span>`;
		const title = this.translate('popUp.verification.title');
		const description = this.translate('popUp.verification.description', { phone: maskedPhone });
		const submitLabel = this.translate('popUp.verification.next');
		const reSendLabel = this.translate('popUp.verification.resendCode');
		const retryLabel = this.translate('popUp.verification.retry');
		const inputProps = {
			maxLength: 4,
			type: 'number',
			controls: false,
			onChange: this.onCodeDigitChange,
			onKeyUp: this.onCodeDigitKeyUp,
			onFocus: this.onCodeDigitFocus,
		};
		const { confirmationCode } = this.state;
		const isDisabled = confirmationCode.slice(0, CODE_DIGITS_AMOUNT).some(d => !d);

		return (
			<div className={css.popUpWrap}>
				<div className={css.bg} />
				<div className={cn(css.popUp, error && css.error)}>
					<button className={css.closeBtn} onClick={this.onPopUpCloseClick} type="button" />
					<p className={css.popUpTitle}>{title}</p>
					{/* eslint-disable-next-line react/no-danger */}
					<p className={css.popUpDescription} dangerouslySetInnerHTML={{ __html: description }} />

					<form className={css.verificationCodeForm}>
						{Array.from({ length: CODE_DIGITS_AMOUNT }).map((o, index) => (
							<input
								{...inputProps}
								data-index={(index + 1).toString()}
								value={confirmationCode[index]}
								ref={this.digitInputRefs[index]}
								// eslint-disable-next-line react/no-array-index-key
								key={`sms-input-${index}`}
							/>
						))}
						{error && (
							<div className={css.errorWrap}>
								<p className={css.error}>{error}</p>
							</div>
						)}
					</form>

					<button
						className={css.submitBtn}
						onClick={!error ? this.onSubmitCode : this.onRetry}
						disabled={isDisabled}
						type="button"
					>
						{!error ? submitLabel : retryLabel}
					</button>
					<button className={css.reSendBtn} onClick={this.onSubmitPhone} type="button">
						<span>{reSendLabel}</span>
					</button>
				</div>
			</div>
		);
	}

	renderSuccess() {
		const title = this.translate('popUp.success.title');
		const done = this.translate('popUp.success.done');

		return (
			<div className={css.popUpWrap}>
				<div className={css.bg} />
				<div className={css.popUp}>
					<button className={css.closeBtn} type="button" />
					<div className={css.successIcon} />
					<p className={cn(css.popUpTitle, css.success)}>{title}</p>
					<button className={css.submitBtn} onClick={this.onVerificationDone} type="button">
						{done}
					</button>
				</div>
			</div>
		);
	}

	renderEditable() {
		const props = { ...this.props.editableModeProps?.nodeProps };
		return this.renderDefault(props);
	}

	renderDefault(props: Partial<BBEditableModeProps['nodeProps']> = {}) {
		const { nodeProps } = this.props.uiConfig;
		const className = cn(css.btn, nodeProps.className, props.className);

		return (
			<button
				{...nodeProps}
				{...props}
				{...this.props.stateAttrs}
				{...this.eventListeners}
				type="button"
				className={className}
				ref={this.props.containerRef as RefObject<HTMLButtonElement>}
			>
				<PhoneIcon className={css.icon} width={30} color="#FFF" />
				{this.props.children}
			</button>
		);
	}

	render() {
		const { popUpView } = this.state;
		return (
			<>
				{this.props.isEditableMode ? this.renderEditable() : this.renderDefault()}
				{popUpView && createPortal(this.renderPopUp(), document.body)}
			</>
		);
	}
}

const WithRedux = connector(SMSLogin);
const WithCardTransitionContext = withCardTransitionContext(WithRedux);

export default withEventProvider(WithCardTransitionContext);
