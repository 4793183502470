import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import css from './AnimatedNumber.scss';

const Span = props => <span style={{ position: 'relative' }} className={css.animatedNumber} {...props} />;
const AnimatedNumber = (props: { number: string | number }) => {
	return (
		<TransitionGroup component={Span}>
			<CSSTransition in key={props.number} timeout={1000} classNames="slide" unmountOnExit>
				<span>{props.number}</span>
			</CSSTransition>
		</TransitionGroup>
	);
};

export default AnimatedNumber;
