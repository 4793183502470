import { SET_FORM_DATA } from 'client/constants/actions';
import { FormDataT } from 'client/components/common/StoryCard/Form/types';

type FieldId = string;
type FormCardData = Record<FieldId, FormDataT[string]['data'] /* field value */>;

export type SetFormDataPayload = {
	cardId: string;
	data: FormCardData;
};

// Store form field values
export function setFormData(payload: SetFormDataPayload) {
	return {
		type: SET_FORM_DATA,
		payload,
	};
}
