import React, { FC, useContext } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { clientLog } from 'common/utils/helpers';
import { selectCardElements } from 'client/reducers/card/selectors';
import { selectEditorState } from 'client/reducers/editor/selectors';
import { IFRAME_ACTIONS, transmitTo } from 'common/utils/iframe-tunnel';
import { selectStorycardsDomain } from 'client/reducers/story/selectors';
import { TRAVERSE_TREE_TARGETS } from 'client/components/common/BuildingBlocks/utils/common';
import { traverseTree } from 'client/components/common/BuildingBlocks/utils/traverse-tree';
import { Context as ResponsiveProviderContext } from 'client/components/common/ResponsiveProvider/Context';
import { BBModel, StorySymbols } from 'types/story';
import { ClientReducerState, useClientSelector } from 'client/reducers';
import {
	ITraverseTreeOptions,
	ITraverseTreeOptionsListeners,
} from 'client/components/common/BuildingBlocks/utils/traverse-tree-types';

const log = clientLog.extend('TreeRenderer');

const mapStateToProps = (state: ClientReducerState) => ({
	editorMode: state.editor.editorMode,
	state: selectEditorState(state),
	cardElements: selectCardElements(state)!,
});

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & {
	treeOptions:
		| { isCardTree: ITraverseTreeOptions['isCardTree']; target: ITraverseTreeOptions['target'] }
		| ({ isCardTree: ITraverseTreeOptions['isCardTree']; target: 'editor' } & ITraverseTreeOptionsListeners);
	elements: BBModel[];
	symbols?: StorySymbols;
};

type ExtraProps = {
	state?: Props['state'];
};

const TreeRenderer: FC<Props> = props => {
	const isEditor = props.treeOptions.target === TRAVERSE_TREE_TARGETS.EDITOR;
	const { state, elements, treeOptions, cardElements, symbols, editorMode } = props;
	const { mediaQuery, currentMediaQuery } = useContext(ResponsiveProviderContext);
	const storycardsDomain = useClientSelector(selectStorycardsDomain);

	log(`%c${treeOptions.isCardTree ? 'cardElements' : 'storyElements'}`, 'font-weight: bold');

	if (!mediaQuery || !currentMediaQuery) {
		return null;
	}

	try {
		const extraProps: ExtraProps = isEditor ? { state } : {};
		const options = {
			...treeOptions,
			...extraProps,
			mediaQuery,
			currentMediaQuery,
			cardElements,
			symbols,
			editorMode,
			storycardsDomain,
		};
		return traverseTree({ tree: elements, options });
	} catch (e) {
		console.error(e);

		if (isEditor)
			transmitTo({
				id: 'TreeRenderer',
				target: 'admin',
				action: IFRAME_ACTIONS.STORY_RENDER_FAILED,
				payload: { error: e instanceof Error ? e.message : 'Failed to render story' },
			});

		return null;
	}
};

function areEqual(prevProps: Props, nextProps: Props) {
	const isEditorModeEqual = prevProps.editorMode === nextProps.editorMode;
	const isElementsEqual = prevProps.elements === nextProps.elements;
	const isSymbolsEqual = prevProps.symbols === nextProps.symbols;
	const isStateEqual =
		Object.keys(nextProps.state.source).some(key => nextProps.state.source[key] === prevProps.state.source[key]) &&
		prevProps.state.state === nextProps.state.state;

	const isEqual = isEditorModeEqual && isStateEqual && isElementsEqual && isSymbolsEqual;

	if (!nextProps.treeOptions.isCardTree) {
		const isCardElementsEqual = prevProps.cardElements === nextProps.cardElements;
		return isEqual && isCardElementsEqual;
	}

	return isEqual;
}

export default connector(React.memo(TreeRenderer, areEqual));
