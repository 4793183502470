import React, { RefObject } from 'react';
import cn from 'classnames';

import type { BBCommonProps, BBEditableModeProps } from 'types/story';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import { EventProviderContext } from 'client/components/pages/Story/EventProvider/Context';
import {
	UNIT_VALUES,
	CounterDivider,
	COUNTER_DIVIDER_DATA_ATTR,
	defaultProps,
	CounterType,
	CounterAnimation,
} from './constants';
import { useCounter } from './use-counter';
import { parseUnits } from './utils';

import css from './Counter.scss';
import Unit from './Unit';

const Counter: React.FC<BBCommonProps> = props => {
	const { eventProvider } = React.useContext(EventProviderContext);
	const isEditableMode = Boolean(props.isEditableMode);
	const { counterType = CounterType.CountDown, counterDate } = props.uiConfig.componentProps;
	const {
		counterUnits,
		counterLabels,
		counterDivider = defaultProps.counterDivider,
		counterAnimation = CounterAnimation.SwipeDown,
	} = props.uiConfig.componentProps.other;

	const onComplete = React.useCallback(() => {
		eventProvider.events.flow(props._id);
	}, [props._id, eventProvider]);

	const { currentValue } = useCounter({
		isEditableMode,
		counterType,
		counterDate,
		onComplete,
	});
	const paddingTop = props.uiConfig.componentProps.styles.counterPaddingTop ?? '0';

	React.useEffect(() => {
		props.containerRef?.current.style.setProperty('--unit-padding-top', paddingTop);
	}, [props.containerRef, paddingTop]);

	const renderCounter = () => {
		const selectedUnits = parseUnits(counterUnits).sort((a, b) => a - b);
		let time = currentValue;

		return selectedUnits.map((unit, index) => {
			const value = Math.floor(time / UNIT_VALUES[unit]);
			time -= value * UNIT_VALUES[unit];

			return (
				<React.Fragment key={unit}>
					<Unit
						type={unit}
						value={value}
						label={Boolean(counterLabels)}
						animation={isEditableMode ? CounterAnimation.None : counterAnimation}
					/>
					{index < selectedUnits.length - 1 && (
						<div className={css.divider} {...{ [COUNTER_DIVIDER_DATA_ATTR]: '' }}>
							<span>{counterDivider === CounterDivider.Colon ? ':' : ''}</span>
						</div>
					)}
				</React.Fragment>
			);
		});
	};

	const renderEditable = () => {
		return renderDefault({ ...props.editableModeProps?.nodeProps });
	};

	const renderDefault = (params: Partial<BBEditableModeProps['nodeProps']> = {}) => {
		const { nodeProps } = props.uiConfig;

		return (
			<div
				{...nodeProps}
				{...props.stateAttrs}
				{...props.eventListeners}
				{...params}
				data-box-type={props.uiConfig.componentProps.other?.boxType}
				className={cn(css.counter, nodeProps.className, params.className)}
				ref={props.containerRef as RefObject<HTMLDivElement>}
			>
				{renderCounter()}
			</div>
		);
	};

	return props.isEditableMode ? renderEditable() : renderDefault();
};

export default withCardTransitionContext(Counter);
