import React, { useContext } from 'react';
import cn from 'classnames';
import type { BBCommonProps } from 'types/story';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import css from 'src/routes/client/components/common/BuildingBlocks/Answer/ResultText/ResultText.scss';
import AnimatedCounter from 'common/components/AnimatedCounter';
import AnswerContext from '../Context';

const ResultText: React.FC<BBCommonProps> = props => {
	const ctx = useContext(AnswerContext);
	const { isEditableMode, uiConfig } = props;
	const start = isEditableMode ? 100 : 0;
	const isHidden = isEditableMode ? false : !ctx?.answerVote?.isShow;

	return (
		<div
			{...uiConfig.nodeProps}
			{...props.stateAttrs}
			{...props.eventListeners}
			{...(props.isEditableMode ? props.editableModeProps?.nodeProps : null)}
			className={cn(css.text, uiConfig.nodeProps.className, { [css.hidden]: !ctx?.showResults })}
		>
			<div className={cn(css.counter, { [css.hidden]: isHidden })}>
				<AnimatedCounter
					animate={ctx?.answerVote?.isShow}
					duration={ctx?.answerVote?.duration}
					start={start}
					end={ctx?.answerVote?.value || start}
				/>
				%
			</div>
		</div>
	);
};

export default withCardTransitionContext(ResultText);
