import type { BBModel, BBSymbolInstance, BBSymbolLink } from 'types/story';

type AllowedTypes = BBModel | BBSymbolInstance | BBSymbolLink;

/**
 * Walk components depth-first and call a callback on each
 *
 * @function componentWalk
 * @param components  - Array of components
 * @param cb          - callback to run on each component
 * @param path        - dot-notated path to component in components param
 * @param parent      - parent component
 * @example
 *      const arr: BBSymbolInstance[] = [];
 *
 *      componentWalk<BBSymbolInstance>(arr, function step({ component }) {
 *          componentWalk<BBSymbolInstance>(component.children, step);
 *      });
 *
 * @return void
 */

// TODO: refactor and pass params as a single object componentWalk({ components, cb, path, parent })
export const componentWalk = <T extends AllowedTypes = BBModel>(
	components: T[] | BBModel['children'],
	cb: (props: { component: T; index: number; path: string; parent?: BBModel }) => void,
	path: string = '',
	parent?: BBModel
) => {
	if (!Array.isArray(components)) {
		return;
	}
	components.forEach((component, index) => {
		if (!component) {
			return;
		}

		cb({
			component,
			index,
			path: path === '' ? `${index}` : `${path}.children.${index}`,
			parent,
		});
	});
};
