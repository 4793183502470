import React from 'react';
import { nanoid } from 'nanoid';
import { get, forOwn } from 'lodash';
import parse, { DOMNode } from 'html-react-parser';
import css from './ScriptRenderer.scss';

type Attrs = {
	[name: string]: string;
};

class ScriptManager {
	scripts = new Map<string, DOMNode>([]);

	addScript = (domNode: DOMNode) => {
		this.scripts.set(nanoid(), domNode);
	};

	loadScript = (props: { attrs: Attrs; innerHTML: string; targetNode: HTMLDivElement | null }) => {
		const script = document.createElement('script');

		forOwn(props.attrs, (value, key) => {
			script.setAttribute(key, value);

			if (['async', 'defer'].includes(key)) {
				script[key] = ['', 'true'].includes(value);
			}
		});

		if (!props.attrs.src && props.innerHTML) {
			script.innerHTML = `(function() {${props.innerHTML}})()`;
		}

		if (props.targetNode) {
			props.targetNode.appendChild(script);
		}
	};

	clear() {
		this.scripts.clear();
	}
}

type Props = {
	value: string; // html|script string
	disabled?: boolean;
	scriptOnly?: boolean; // parse only valid javascript and html script tag
};

export class ScriptRenderer extends React.PureComponent<Props> {
	ScriptManager: ScriptManager = new ScriptManager();

	scriptsRef = React.createRef<HTMLDivElement>();

	componentDidMount() {
		this.renderScripts();
	}

	componentDidUpdate(prevProps: any, prevState: any, snapshot) {
		if (this.getDecodedValue() !== this.getDecodedValue(prevProps.value)) {
			this.renderScripts();
		}
	}

	getDecodedValue = (value = this.props.value) => {
		return decodeURI(value);
	};

	clearScriptHTML = () => {
		if (this.scriptsRef.current) {
			this.scriptsRef.current.innerHTML = '';
		}
	};

	renderScripts() {
		const { scripts, loadScript } = this.ScriptManager;

		// clear
		this.clearScriptHTML();

		// add scripts
		scripts.forEach(script => {
			const attrs = 'attribs' in script ? script.attribs : {};
			const innerHTML: string = script.type === 'text' ? script.data : get(script, 'children.0.data', '');

			loadScript({
				attrs,
				innerHTML,
				targetNode: this.scriptsRef.current,
			});
		});
	}

	renderHTML = (html: string) =>
		parse(html, {
			replace: domNode => {
				if (domNode.type === 'script') {
					this.ScriptManager.addScript(domNode);
					return <></>; // replace script with an empty fragment
				}

				if (
					this.props.scriptOnly &&
					domNode.type === 'text' &&
					typeof domNode.data === 'string' &&
					domNode.data.trim() &&
					domNode.parent === null
				) {
					this.ScriptManager.addScript(domNode);
					return <></>; // replace script with an empty fragment
				}

				if (this.props.scriptOnly) {
					return <></>; // replace all with empty fragment to avoid render other html markup
				}

				return false;
			},
		});

	render() {
		const value = this.getDecodedValue();
		this.ScriptManager.clear();

		if (!value || this.props.disabled) {
			return null;
		}

		return (
			<>
				<div className={css.scripts} ref={this.scriptsRef} />
				{value ? this.renderHTML(value) : null}
			</>
		);
	}
}
