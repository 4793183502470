import { BBCommonProps } from 'types/story';

export const initialState: {
	isValid: boolean;
	errors: {
		[key: string]: string[];
	};
} = {
	isValid: true,
	errors: {},
};
export type FormDataT = {
	[fieldId: string]: {
		name: string;
		type: string;
		// field value
		data: string;
		attributes: { id: string; checked?: boolean };
	};
};
export type State = typeof initialState;
export type ValidationResultType = { isValid: boolean; errors: State['errors'] };
export type ValidateFnType = (formData: FormDataT) => Promise<ValidationResultType>;
export type RegisterFieldFnType = (field: BBCommonProps) => void;
export type FormCardContextType = typeof initialState &
	Partial<{ validate: ValidateFnType; registerField: RegisterFieldFnType }>;
