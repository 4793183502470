import { assign, isObject } from 'lodash';
import { replaceUrlParams } from 'utils/route';
import { Http } from './http';
import { Resource } from './resource';
import { RequestMethod, IResource, IEndpoint, SendOptions, QueryOption, DataOption } from './types';

export class Endpoint extends Resource {
	options: IResource['options'] & IEndpoint['options'];

	service = Http;

	resource: IEndpoint['resource'];

	method: RequestMethod = RequestMethod.POST;

	_params: null | Record<string, string | number | null> = null;

	constructor(resource: Resource, url: string, options?: IEndpoint['options']) {
		super(url, options);

		this.resource = resource;
		this.options = { ...this.resource.options, ...options };
		if (this.options.type) this.method = this.options.type;
	}

	/**
	 * fullUrl - resource url + endpoint url
	 */
	get fullUrl() {
		let { url } = this;
		if (isObject(this._params)) {
			url = replaceUrlParams(url, this._params);
		}
		return `${this.resource.url}${url}`;
	}

	/**
	 * send - sends request with options
	 * if options do not contain data or query then the options object will be
	 * considered to be request payload
	 *
	 * @param  optionsParam request options
	 * @param  optionsParam.data request data (for POST requests)
	 * @param  optionsParam.query request query data (for GET requests)
	 * @param  optionsParam.headers request headers
	 * @param  optionsParam.requestInterceptors request interceptors
	 * @param  optionsParam.responseInterceptors response interceptors
	 * @return resolved with response or rejects with error
	 */
	send(optionsParam?: SendOptions) {
		let _options = optionsParam;
		// allow sending data or query as only argument
		if (_options && !('data' in _options) && !('query' in _options)) {
			_options =
				this.method === RequestMethod.GET
					? { query: _options as QueryOption['query'] }
					: { data: _options as DataOption['data'] };
		}
		return this.service.send(this, _options);
	}

	/**
	 * params - Add route params to request url
	 *
	 * @param value route params
	 * @return new endpoint instance
	 */
	params(value?: Record<string, string | number | null>) {
		const endpoint = Endpoint.from(this);
		if (value) endpoint._params = value;

		return endpoint;
	}

	/**
	 * @static from - clone Endpoint
	 *
	 * @param  {Endpoint} endpoint instance to clone
	 * @return {Endpoint} new endpoint instance
	 */
	static from(endpoint: Endpoint) {
		const { resource, url, options } = endpoint;
		const newEndpoint = new this(resource, url, options);
		assign(newEndpoint, endpoint);

		return newEndpoint;
	}
}
