import { IResource, IInterceptorOptions, RequestInterceptor, ResponseInterceptor, ErrorInterceptor } from './types';

export class Resource {
	url: IResource['url'];

	options: IResource['options'];

	constructor(url: IResource['url'], options: IResource['options'] = {}) {
		this.url = url;
		this.options = options;
	}

	addInterceptor(
		type: keyof Pick<IInterceptorOptions, 'responseInterceptors' | 'requestInterceptors' | 'errorInterceptors'>,
		interceptor: IResource['interceptor']
	) {
		const interceptors = this.options[type] || [];
		// @ts-ignore FIXME
		this.options[type] = interceptors.concat(interceptor);
	}

	addRequestInterceptors(...interceptors: RequestInterceptor[]) {
		interceptors.forEach(interceptor => this.addInterceptor('requestInterceptors', interceptor));
	}

	addResponseInterceptors(...interceptors: ResponseInterceptor[]) {
		interceptors.forEach(interceptor => this.addInterceptor('responseInterceptors', interceptor));
	}

	addErrorInterceptors(...interceptors: ErrorInterceptor[]) {
		interceptors.forEach(interceptor => this.addInterceptor('errorInterceptors', interceptor));
	}
}
