import * as Sentry from '@sentry/react';
import React, { Component } from 'react';
import css from './ErrorBoundary.scss';

type State = {
	error: null | typeof Error;
	info: null | React.ErrorInfo;
	eventId?: string;
};

type Props = {
	content?: () => React.ReactNode;
	children: React.ReactNode;
};

class ErrorBoundary extends Component<Props, State> {
	state: State = { error: null, info: null };

	static getDerivedStateFromError = (error, stack) => {
		return { error, info: error?.stack };
	};

	componentDidCatch(error, info) {
		this.logToService(error, info);
	}

	logToService(error, info) {
		Sentry.withScope(scope => {
			scope.setExtras(info);
			const eventId = Sentry.captureException(error);
			this.setState({ eventId });
		});
	}

	render() {
		const { error, info } = this.state;

		if (error) {
			return (
				<div className={css.root}>
					<div className={css.hero}>
						<h2 className={css.title}>
							Something went wrong...
							<span role="img" aria-labelledby="error">
								🙊🙈🙉
							</span>
						</h2>
						<button
							className={css.btn}
							type="button"
							onClick={() => window.location.reload()}
							data-text="reload page"
						>
							Reload page
						</button>
						<button
							type="button"
							data-text="report issue"
							className={`${css.btn} ${css.btnReport}`}
							onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}
						>
							Report issue
						</button>
					</div>

					<br />

					<details style={{ whiteSpace: 'pre-wrap', outline: 'none', cursor: 'pointer' }}>
						<>
							<summary style={{ outline: 'none' }}>Details - {error.toString()}</summary>
							<br />
							<br />
							{info}
						</>
					</details>

					<br />

					{this.props.content && this.props.content()}
				</div>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
