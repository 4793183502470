import React, { ComponentProps, RefObject, useEffect } from 'react';
import cn from 'classnames';
import type { BBCommonProps, BBUiConfig } from 'types/story';
import { UNIFORM_PROPS } from 'common/constants/component-props';
import { useCountDown } from 'common/components/useCountDown';
import { ClientReducerState, useClientSelector } from 'client/reducers';
import { withEventProvider, EventProviderContextT } from 'client/components/pages/Story/EventProvider/Context';
import AnimatedNumber from 'client/components/common/AnimatedNumber';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import { TimerEvEm, TimerPauseEvent, TimerStartEvent } from './utils';
import css from './Timer.scss';

export enum TimerType {
	num = 'num',
	bar = 'bar',
	circ = 'circ',
}

// Get css property of the track|progress color
export function getTimerColorProperty(uiConfig?: BBUiConfig) {
	if (!uiConfig) return 'background-color';
	const type = uiConfig.componentProps[UNIFORM_PROPS.timerType];
	switch (type) {
		case 'circ':
			return 'stroke';
		default:
			return 'background-color';
	}
}

const getTime = (ms: number) => {
	const minutes = Math.floor(ms / 60000);
	const seconds = +((ms % 60000) / 1000).toFixed(0);
	const format = (v: number) => (v < 10 ? `0${v}` : `${v}`);

	return {
		minutes: format(minutes),
		seconds: format(seconds),
		secondsTotal: (ms / 1000).toFixed(0),
	};
};

type Props = BBCommonProps & EventProviderContextT;

type TimerComponentProp = {
	timeToCount: number;
	timeLeft: number;
	progress: number;
	id: string;
};

export const timerDataSelector = {
	track: 'timer-track',
	progress: 'timer-progress',
};

const TimerBar: React.FC<TimerComponentProp> = (props: ComponentProps<typeof TimerBar>) => {
	const time = getTime(props.timeLeft);
	return (
		<div className={cn(css.bar)}>
			<div className={css.barTrack} data-selector={timerDataSelector.track}>
				<div
					className={css.barProgress}
					data-selector={timerDataSelector.progress}
					style={{ transform: `scaleX(${props.progress})` }}
				/>
			</div>
			<span className={css.barText}>
				<AnimatedNumber number={time.minutes} />:<AnimatedNumber number={time.seconds} />
			</span>
		</div>
	);
};

const TimerCirc: React.FC<TimerComponentProp> = (props: ComponentProps<typeof TimerCirc>) => {
	const id = {
		c1: `c1-${props.id}`,
		c2: `c2-${props.id}`,
		clip1: `clip1-${props.id}`,
		clip2: `clip2-${props.id}`,
	};
	const attrsCommon = { cx: 16, cy: 16, r: 16 };
	const attrs = {
		c1: { ...attrsCommon, id: id.c1, className: css.circTrack, 'data-selector': timerDataSelector.track },
		c2: { ...attrsCommon, id: id.c2, className: css.circProgress, 'data-selector': timerDataSelector.progress },
	};
	return (
		<div className={css.circ}>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
				<defs>
					<circle {...attrs.c1} />
					<circle {...attrs.c2} />

					{/* clip outside area, stroke-width will grow only inside */}
					<clipPath id={id.clip1}>
						<use href={`#${id.c1}`} />
					</clipPath>
					<clipPath id={id.clip2}>
						<use href={`#${id.c2}`} />z
					</clipPath>
				</defs>
				<circle {...attrs.c1} clipPath={`url(#${id.clip1})`} />
				<circle
					{...attrs.c2}
					clipPath={`url(#${id.clip2})`}
					style={{ strokeDashoffset: 101 - props.progress * 101 }}
				/>
			</svg>
			<span className={css.circText}>
				<AnimatedNumber number={getTime(props.timeLeft).secondsTotal} />
			</span>
		</div>
	);
};

const TimeText: React.FC<TimerComponentProp> = (props: ComponentProps<typeof TimeText>) => {
	const time = getTime(props.timeLeft);
	return (
		<div className={css.numText}>
			<AnimatedNumber number={time.minutes} />:<AnimatedNumber number={time.seconds} />
		</div>
	);
};

const componentMap = {
	[TimerType.num]: TimeText,
	[TimerType.bar]: TimerBar,
	[TimerType.circ]: TimerCirc,
};

const Timer = (props: Props) => {
	// props
	const { editableModeProps: EMP, stateAttrs, uiConfig, isEditableMode, eventProvider, _id } = props;
	const callFlowEvent = eventProvider.events.flow;
	const type = uiConfig.componentProps[UNIFORM_PROPS.timerType];
	const timeToCount = (uiConfig.componentProps[UNIFORM_PROPS.timerTime] || 0) * 1000;

	const cardId = useClientSelector((state: ClientReducerState) => state.card._id);
	const {
		isComplete,
		start: startTimer,
		pause: pauseTimer,
		isPause: isTimerPaused,
		...countDown
	} = useCountDown(timeToCount, 1000 / 60);
	const timeLeft = isEditableMode ? timeToCount * 0.75 : countDown.timeLeft;
	const progress = 1 - timeLeft / timeToCount; // 0-1

	useEffect(() => {
		const pauseListener = (event: TimerPauseEvent) => {
			if (event.data?.cardId === cardId) {
				pauseTimer();
			}
		};

		const startTimerListener = (event: TimerStartEvent) => {
			if (event.data?.cardId === cardId) {
				startTimer();
			}
		};

		TimerEvEm.addListener('start', startTimerListener);
		TimerEvEm.addListener('pause', pauseListener);

		return () => {
			TimerEvEm.removeListener('start', startTimerListener);
			TimerEvEm.removeListener('pause', pauseTimer);
		};
	}, [cardId, pauseTimer, startTimer]);

	// run timer
	useEffect(() => {
		if (!isEditableMode && cardId) setTimeout(() => TimerEvEm.emit('start', { cardId }), 500);
	}, [cardId, isEditableMode]);

	// change card
	useEffect(() => {
		if (!isEditableMode && isComplete) {
			callFlowEvent(_id);
		}
	}, [isEditableMode, isComplete, _id, callFlowEvent]);

	if (!type) {
		return null;
	}

	const Component = componentMap[type];
	const secondsLeft = +getTime(timeLeft).secondsTotal;

	return (
		<div
			{...uiConfig.nodeProps}
			{...stateAttrs}
			{...props.eventListeners}
			{...EMP?.nodeProps}
			style={uiConfig.nodeProps?.style}
			className={cn(css.timer, css[`timer-${type}`], uiConfig.nodeProps.className, EMP?.nodeProps?.className, {
				[css.animate]: secondsLeft !== 0 && secondsLeft <= 5 && !isEditableMode && !isTimerPaused,
			})}
			ref={props.containerRef as RefObject<HTMLDivElement>}
		>
			<Component timeToCount={timeToCount} timeLeft={timeLeft} progress={progress} id={props._id} />
		</div>
	);
};

export default withEventProvider(withCardTransitionContext(Timer));
