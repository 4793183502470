import { DEFAULT_PARAMS, EmbedStoryParams } from 'src/sdk';

type SettingsReducer = {
	cardHeight: number;
	embed: EmbedStoryParams;
};

const initialState: SettingsReducer = {
	cardHeight: 0,
	embed: { ...DEFAULT_PARAMS },
};

export default initialState;
