import { UNIFORM_PROPS } from 'common/constants/component-props';

export enum ContainerId {
	Main = 'container1',
	Secondary = 'container2',
}

export enum SortableType {
	// order items within a single container
	singleContainer = 'singleContainer',
	// move items ony-by-one between containers and sort
	multiContainer = 'multiContainer',
}

export enum SortableMode {
	custom = 'custom',
	random = 'random',
}

export const TIMEOUT = {
	SHOW_SECONDARY_CONTAINER: 500, // show secondary container 1st time
	ENTER_SECONDARY_CONTAINER: 1000, // enter secondary container on after sort end
	CORRECT_SORT: 2000,
	NEXT_CARD: 3000,
	SORT_TRANSITION: 400,
};

export const defaultProps = {
	[UNIFORM_PROPS.sortableMode]: SortableMode.random,
	[UNIFORM_PROPS.sortableNavTimeout]: TIMEOUT.NEXT_CARD / 1_000,
};
