/* eslint-disable react/no-unused-state */
import _ from 'lodash';
import React, { RefObject } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router';
import type { RouteComponentProps } from 'react-router-dom';

import cn from 'classnames';
import ContentEditable from 'react-contenteditable';

import type { BBCommonProps, BBEditableModeProps, BBUiConfig } from 'types/story';
import type { ClientReducerState } from 'client/reducers';
import { isGradient } from 'common/utils/assets';
import { isLayerType } from 'common/utils/blocks/is-layer-type';
import { COMPONENT_STATES, COMPONENT_TYPE } from 'common/constants';
import { pastePlainText, prependUrlProtocol, triggerFocus } from 'common/utils/helpers';
import XIcon from 'common/components/Icon/X';
import EmailIcon from 'common/components/Icon/Email';
import FacebookIcon from 'common/components/Icon/Facebook';
import WhatsappIcon from 'common/components/Icon/Whatsapp';
import { STORY_PATH } from 'client/constants/routes';
import { UNIFORM_PROPS } from 'common/constants/component-props';
import { selectStoryFacade } from 'client/reducers/story/selectors';
import css from 'client/components/common/BuildingBlocks/Share/Share.scss';
import { transmitToAdminBbUpdate } from 'client/utils/transmit-to-admin-bb-update';
import { CHILDREN_KEY } from 'client/components/common/BuildingBlocks/utils/common';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import { ApiEvents } from 'client/ApiEvents';
import { RawImageComponent } from '../Img/Img';
import { DEFAULT_SHARE_OG_SOURCE, DEFAULT_SHARE_TARGET, ShareOgSource, ShareTarget, ShareType } from './types';

const imgCustomProps = { style: { width: '100%', height: '100%' } };

const getIcon = (type?: ShareType) => {
	switch (type) {
		case ShareType.email:
			return <EmailIcon />;
		case ShareType.fb:
			return <FacebookIcon />;
		case ShareType.tw:
			return <XIcon />;
		case ShareType.wa:
			return <WhatsappIcon />;
		default:
			return null;
	}
};

const mapStateToProps = (state: ClientReducerState) => ({
	story: selectStoryFacade(state)!,
	memberId: state.user.id,
	cardId: state.card._id!,
});

const connector = connect(mapStateToProps);

type ReduxProps = ConnectedProps<typeof connector>;

type State = {
	htmlPrev: string;
	html: string;
	editing: boolean;
};

type Props = RouteComponentProps & ReduxProps & BBCommonProps & { className?: string; children: string };

class Share extends React.Component<Props, State> {
	editableRef = React.createRef<HTMLElement>();

	state = {
		htmlPrev: '',
		html: '',
		editing: false,
	};

	static getDerivedStateFromProps({ children }: Props, state: State) {
		const isUpdatedChildrenFromProp = children !== state.htmlPrev;
		if (isUpdatedChildrenFromProp) {
			return { html: children, htmlPrev: children };
		}
		return null;
	}

	onEditableBlur = (event: React.FocusEvent) => {
		const path = _.get(this.props.editableModeProps, ['nodeProps', 'data-path']);
		const { currentMediaQuery } = this.props;

		// NOTE: children is always writes to defaultState, but for certain media query
		transmitToAdminBbUpdate({
			id: Share.name,
			path: `elements.${path}.${CHILDREN_KEY}.${COMPONENT_STATES.DEFAULT}.${currentMediaQuery}`,
			value: this.state.html,
			isStory: isLayerType(this.props).global,
		});

		this.setState({ editing: false });
	};

	onEditableChange = event => {
		this.setState({ html: event.target.value });
		// trigger SelectionHint update to set current editable node sizes
		triggerFocus(this.editableRef.current);
	};

	onEditableDoubleClick = () => {
		this.setState({ editing: true }, () => {
			this.editableRef.current?.focus();
		});
	};

	onEditableFocus = (event: React.FocusEvent) => {
		// prevent this block from being selected when clicking on its parent
		event.stopPropagation();
	};

	handleShare = (event: React.MouseEvent) => {
		_.invoke(this.props.eventListeners, 'onClick', event);

		const { componentProps } = this.props.uiConfig;
		const type = componentProps[UNIFORM_PROPS.shareType];
		const shareTarget = componentProps[UNIFORM_PROPS.shareTarget] || DEFAULT_SHARE_TARGET;
		const shareOgSource = componentProps[UNIFORM_PROPS.shareOgSource] || DEFAULT_SHARE_OG_SOURCE;
		const shareCustomUrl = componentProps[UNIFORM_PROPS.shareCustomUrl];
		const isShareCard =
			shareOgSource === ShareOgSource.card &&
			(shareTarget === ShareTarget.story || shareTarget === ShareTarget.parent);
		const shareMsg = isShareCard
			? this.props.story.settings.cards?.[this.props.cardId]?.share?.msg
			: this.props.story.settings.share?.title;
		if (type) {
			share({
				type,
				url: getShareUrl({ shareTarget, shareCustomUrl, shareOgSource }),
				msg: this.props.story.getTransformedDynamicText(shareMsg),
			});
			let sharePlatform: Parameters<typeof ApiEvents.sendShareEvent>[0]['sharePlatform'] | null = null;

			switch (type) {
				case ShareType.fb:
					sharePlatform = 'facebook';
					break;

				case ShareType.tw:
					sharePlatform = 'twitter';
					break;

				case ShareType.wa:
					sharePlatform = 'whatsapp';
					break;

				case ShareType.email:
					sharePlatform = 'email';
					break;

				default:
					break;
			}

			if (sharePlatform) {
				ApiEvents.sendShareEvent({
					organizationId: this.props.story.base.organizationId,
					storyId: this.props.story.storyId,
					cardId: this.props.cardId,
					teamId: this.props.story.base.teamId,
					memberId: this.props.story.base.createdBy,
					sharePlatform,
				});
			}
		}
	};

	get eventListeners() {
		if (this.props.isEditableMode) {
			return {
				...this.props.eventListeners,
				onDoubleClick: this.onEditableDoubleClick,
				onFocus: this.onEditableFocus,
			};
		}

		return {
			...this.props.eventListeners,
			onClick: this.handleShare,
		};
	}

	renderEditable() {
		const { editableModeProps, uiConfig } = this.props;
		const nodeProps = _.merge({}, uiConfig.nodeProps, editableModeProps?.nodeProps);

		/**
		 * Why do we assign "updater" to className?
		 * This is a temporary fix, to update <ContentEditable> in case of
		 * some state was provided via nodeProps. Otherwise <ContentEditable> won't be
		 * updated, because of his own shouldComponentUpdate logic, will return false
		 * if there were changed some data attributes only.
		 */
		let updater = '';
		const updaterObj = { ...nodeProps, ...this.props.stateAttrs };
		Object.keys(updaterObj).forEach(k => {
			const res = k.match(/^(data-)/);
			if (res) updater += `${k}=${updaterObj[k]}`;
		});

		return this.renderDefault(
			nodeProps,
			<ContentEditable
				innerRef={this.editableRef}
				className={cn(css.btnContent, updater)}
				tagName="div"
				html={this.state.html}
				// disabled={!this.state.editing}
				disabled
				onChange={this.onEditableChange}
				onBlur={this.onEditableBlur}
				onPaste={pastePlainText}
			/>
		);
	}

	renderDefault(
		nodeProps: Partial<BBEditableModeProps['nodeProps'] & BBUiConfig['nodeProps']> = this.props.uiConfig.nodeProps,
		editableContent?: React.ReactNode
	) {
		const bgImage = this.props.uiConfig.componentProps.styles.backgroundImage;
		const hasBgImage = bgImage && bgImage !== 'none' && !isGradient(bgImage);

		return (
			<button
				type="button"
				{...nodeProps}
				{...this.props.stateAttrs}
				{...this.eventListeners}
				className={cn(css.btn, nodeProps.className, nodeProps.className, {
					[css.editable]: !!editableContent,
					[css.transparent]: hasBgImage,
				})}
				ref={this.props.containerRef as RefObject<HTMLButtonElement>}
			>
				{hasBgImage ? (
					<RawImageComponent {...this.props} isRaw type={COMPONENT_TYPE.IMG} custom={imgCustomProps} />
				) : (
					<div className={css.btnIcon}>{getIcon(this.props.uiConfig.componentProps.shareType)}</div>
				)}
				{editableContent || (
					<div
						className={css.btnContent}
						/* eslint-disable-next-line react/no-danger */
						dangerouslySetInnerHTML={{ __html: this.state.html }}
					/>
				)}
			</button>
		);
	}

	render() {
		return this.props.isEditableMode ? this.renderEditable() : this.renderDefault();
	}
}

export default withCardTransitionContext(withRouter(connector(Share)));

function getShareUrl(props: {
	shareTarget: BBUiConfig['componentProps']['shareTarget'];
	shareOgSource: BBUiConfig['componentProps']['shareOgSource'];
	shareCustomUrl: BBUiConfig['componentProps']['shareCustomUrl'];
}) {
	const storyUrl = `${window.location.protocol}//${window.location.hostname}${STORY_PATH.base}`;

	switch (props.shareTarget) {
		case ShareTarget.story:
		case ShareTarget.parent: {
			const url = new URL(props.shareOgSource === ShareOgSource.card ? window.location.href : storyUrl);
			if (props.shareTarget === ShareTarget.parent && window.parent !== window) {
				url.searchParams.set('redirectUrl', window.parent.location.href);
			}
			return url.href;
		}
		case ShareTarget.custom:
			return props.shareCustomUrl ? prependUrlProtocol(props.shareCustomUrl, 'https') : storyUrl;
		default:
			return storyUrl;
	}
}

function share(props: { type: BBUiConfig['componentProps']['shareType']; url: string; msg?: string }) {
	const url = encodeURIComponent(props.url);
	const title = encodeURIComponent(document.title);
	const lineBreak = encodeURIComponent('\n');
	const shareMsg = props.msg ? encodeURIComponent(props.msg) : title;

	switch (props.type) {
		case ShareType.email:
			window.open(
				`mailto:?subject=${encodeURIComponent('I wanted you to see this site')}&body=${encodeURIComponent(
					'Check out this site'
				)}${props.msg ? lineBreak + shareMsg : ''}${lineBreak}${url}`
			);
			break;
		case ShareType.fb:
			window.open(`https://www.facebook.com/sharer.php?u=${url}`, 'sharer', 'width=555,height=600');
			break;
		case ShareType.wa:
			window.open(
				`${
					window.screen.width > 1024 ? 'https://web.whatsapp.com/' : 'https://api.whatsapp.com/'
				}send?text=${shareMsg}${lineBreak}${url}`
			);
			break;
		case ShareType.tw:
			window.open(`https://twitter.com/share?text=${shareMsg}&url=${lineBreak}${url}&tags=storycards`);
			break;
		default:
			break;
	}
}
