import React, { ReactNode } from 'react';
import { gsap } from 'gsap';
import cn from 'classnames';
import { useSwipeable, SwipeEventData, SwipeableHandlers, SwipeDirections } from 'react-swipeable';
import type { BBCommonProps, BBEditableModeProps } from 'types/story';
import { sleep } from 'common/utils/helpers';
import { COMPONENT_STATES } from 'common/constants';
import { UNIFORM_PROPS } from 'common/constants/component-props';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import { ChildrenWithParentState } from 'client/components/common/BuildingBlocks/ChildrenWithParentState';
import { EventProviderContextT, withEventProvider } from 'client/components/pages/Story/EventProvider/Context';
import { AUTO_HEIGHT_ATTR } from 'client/components/common/BuildingBlocks/Content/AutoHeight';
import css from './Swipe.scss';

type Props = BBCommonProps & EventProviderContextT & { children: ReactNode };

const xDeltaMax = window.innerWidth >= 1200 ? 150 : 75;
const rMax = 30;
const rSpeed = rMax / (window.innerWidth * 0.75);
const timeout = {
	out: 0.7,
	in: 0.3,
};

const Swipeable = props => {
	const handlers = useSwipeable({
		onSwiping: props.onSwiping,
		onSwiped: props.onSwiped,
		trackMouse: true,
	});

	return props.children(handlers);
};

class Swipe extends React.Component<Props> {
	swiped: boolean = false;

	componentDidMount() {
		const { left, right } = this.onSwipeProp;

		/* Register component events in provider, potentially Answer BB can use it.
		   "left" and "right" values is an ID of some component */

		if (left)
			this.props.eventProvider.setComponentEvents(left, {
				onSwipe: () => this._onSwiped({ dir: 'Left' }),
			});

		if (right)
			this.props.eventProvider.setComponentEvents(right, {
				onSwipe: () => this._onSwiped({ dir: 'Right' }),
			});
	}

	get onSwipeProp() {
		return this.props.uiConfig.componentProps[UNIFORM_PROPS.onSwipe] as { left: string; right: string };
	}

	setAutoHeightByContentBB = (action: 'off' | 'on') => {
		const c = this.props.containerRef?.current;
		if (!c) return;

		switch (action) {
			case 'off':
				c.setAttribute(AUTO_HEIGHT_ATTR.name, AUTO_HEIGHT_ATTR.value.preserve);
				break;
			case 'on':
				c.removeAttribute(AUTO_HEIGHT_ATTR.name);
				break;
			default:
				break;
		}
	};

	_animate = (vars: gsap.TweenVars) => {
		if (this.props.containerRef) {
			// disable css transition because of conflict with "card expose" inherited transition
			gsap.set(this.props.containerRef?.current, { css: { transition: 'none' } });
			gsap.to(this.props.containerRef.current, vars);
		}
	};

	_onSwiping = (eventData: SwipeEventData) => {
		if (this.swiped) return;

		if (this.props.containerRef) {
			const { deltaX } = eventData;
			const x = Math.abs(deltaX) > xDeltaMax ? xDeltaMax * (deltaX < 0 ? -1 : 1) : deltaX;
			const r = rSpeed * deltaX;

			// move to swipe delta
			this._animate({ rotation: r, x, onStart: () => this.setAutoHeightByContentBB('off') });
		}
	};

	_onSwiped = ({ dir, deltaX }: SwipeEventData | { dir: SwipeEventData['dir']; deltaX?: number }) => {
		// --> Discard
		if (deltaX !== undefined && Math.abs(deltaX) < xDeltaMax) {
			// animate to orin position
			this._animate({
				x: 0,
				rotation: 0,
				duration: 0.2,
				ease: 'power1.easeIn',
				overwrite: true,
				onComplete: () => this.setAutoHeightByContentBB('on'),
			});
			return;
		}

		const onStart = async () => {
			this.swiped = true;
			this.setAutoHeightByContentBB('off');
			await sleep(timeout.out * 1000 * 0.1);
			this.props.setStates({ [COMPONENT_STATES.SELECTED]: true });
		};

		// --> Apply
		const d = dir === 'Right' ? 1 : -1;
		this._animate({
			rotation: rMax * d,
			x: window.innerWidth * 0.5 * d,
			opacity: 0,
			duration: timeout.out,
			ease: 'expo.out',
			overwrite: true,
			onStart: () => {
				onStart();
			},
			onComplete: () => {
				gsap.set(this.props.containerRef!.current, { x: 0, y: 0, rotate: 0, opacity: 0 });
				this.setAutoHeightByContentBB('on');
				// Call event handler of component that is bound to current Swipe action
				const boundId = this.onSwipeProp[dir.toLowerCase() as SwipeDirections];
				this.props.eventProvider.events.component[boundId]?.onAnswerClick?.('swipe');
			},
		});
	};

	renderEditable() {
		const props = { ...this.props.editableModeProps?.nodeProps };
		return this.renderDefault(props);
	}

	renderDefault(props: Partial<BBEditableModeProps['nodeProps']> = {}) {
		const { nodeProps } = this.props.uiConfig;
		const className = cn(css.swipe, nodeProps.className, props.className, {
			[css.editable]: this.props.isEditableMode,
			[css.swiped]: this.props.states[COMPONENT_STATES.SELECTED],
		});

		const children = this.props.isEditableMode ? (
			this.props.children
		) : (
			<ChildrenWithParentState states={this.props.states}>{this.props.children}</ChildrenWithParentState>
		);

		return (
			<div
				{...nodeProps}
				{...this.props.stateAttrs}
				{...this.props.eventListeners}
				{...props}
				className={className}
			>
				{this.props.isEditableMode ? (
					children
				) : (
					<>
						{/* keep the same content under the swipe area on swipe */}
						<div data-bb="" className={cn(css.inner, css.underlay)}>
							{children}
						</div>

						{/* content swipe area */}
						<Swipeable onSwiped={this._onSwiped} onSwiping={this._onSwiping}>
							{(handlers: SwipeableHandlers) => (
								<div
									className={css.inner}
									data-bb=""
									{...handlers}
									ref={el => {
										handlers.ref(el);
										if (this.props.containerRef)
											this.props.containerRef.current = el as HTMLElement;
									}}
								>
									{children}
								</div>
							)}
						</Swipeable>
					</>
				)}
			</div>
		);
	}

	render() {
		return this.props.isEditableMode ? this.renderEditable() : this.renderDefault();
	}
}

export default withEventProvider(withCardTransitionContext(Swipe));
