/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

export default ({ color = '#FFF', ...props }: SVGAttributes<SVGElement> & { color?: string }) => (
	<svg width="24px" viewBox="0 0 24 24" {...props}>
		<path
			fill={color}
			d="M20,11.5692308 C19.8769231,7.38461538 16.3692308,4 12.1230769,4 C7.87692308,4 4.43076923,7.32307692 4.24615385,11.4461538 C4.24615385,11.5692308 4.24615385,11.6923077 4.24615385,11.8153846 C4.24615385,13.2923077 4.67692308,14.6461538 5.35384615,15.8153846 L4,20 L8.30769231,18.6461538 C9.41538462,19.2615385 10.7076923,19.6307692 12.1230769,19.6307692 C16.4923077,19.6307692 20,16.1230769 20,11.8153846 C20,11.7538462 20,11.6923077 20,11.5692308 Z M12.1230769,18.3384615 C10.7692308,18.3384615 9.53846154,17.9692308 8.49230769,17.2307692 L5.96923077,18.0307692 L6.76923077,15.5692308 C5.96923077,14.4615385 5.53846154,13.1692308 5.53846154,11.7538462 C5.53846154,11.5692308 5.53846154,11.3230769 5.6,11.1384615 C5.84615385,7.81538462 8.67692308,5.23076923 12.1230769,5.23076923 C15.5692308,5.23076923 18.4615385,7.87692308 18.7076923,11.2615385 C18.7076923,11.4461538 18.7076923,11.6307692 18.7076923,11.7538462 C18.7076923,15.3846154 15.7538462,18.3384615 12.1230769,18.3384615 Z"
		/>
		<path
			fill={color}
			d="M15.5863213,12.8758872 C15.3982307,12.7566889 14.3950807,12.339495 14.269687,12.2798959 C14.0815964,12.2202968 13.9562027,12.1606977 13.8308089,12.3990942 C13.7054152,12.5778916 13.329234,12.9950854 13.2038402,13.1142837 C13.0784465,13.2334819 12.9530527,13.2334819 12.7649621,13.1738828 C12.5768715,13.0546846 11.9499028,12.8758872 11.1975404,12.2798959 C10.6332685,11.8031029 10.1943905,11.2071117 10.1316936,11.0283143 C10.0062998,10.8495169 10.1316936,10.7303187 10.1943905,10.6707195 C10.2570873,10.6111204 10.3824811,10.432323 10.5078748,10.3727239 C10.5078748,10.3131248 10.5705717,10.3131248 10.5705717,10.3131248 C10.6332685,10.2535257 10.6332685,10.1939265 10.6959654,10.0747283 C10.7586623,9.95553004 10.7586623,9.83633179 10.6959654,9.77673266 C10.6332685,9.65753441 10.2570873,8.76354754 10.0689967,8.40595278 C9.8809061,8.04835803 9.75551236,8.10795716 9.63011862,8.10795716 C9.50472488,8.10795716 9.37933114,8.10795716 9.2539374,8.10795716 C9.06584679,7.92915978 8.87775618,7.98875891 8.68966557,8.16755628 C8.50157496,8.34635366 8,8.76354754 8,9.71713354 C8,9.95553004 8.06269687,10.1343274 8.12539374,10.3727239 C8.31348435,11.0283143 8.75236244,11.5647064 8.81505931,11.6839047 C8.94045305,11.8031029 10.1943905,13.7102749 12.1379934,14.4850636 C14.1442933,15.2002531 14.1442933,14.9618566 14.5204745,14.9618566 C14.8966557,14.9618566 15.711715,14.5446627 15.8371088,14.0678697 C16.0251994,13.6506758 16.0251994,13.2930811 15.9625025,13.1738828 C15.8998056,13.0546846 15.7744119,12.9950854 15.5863213,12.8758872 Z"
		/>
	</svg>
);
