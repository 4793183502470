import React from 'react';
import cn from 'classnames';

import css from './Loader.scss';

const Size = {
	tiny: 'tiny',
	small: 'small',
	medium: 'medium',
	large: 'large',
} as const;

type Props = {
	size?: ValuesType<typeof Size>;
	color?: string;
	className?: string;
	classNameRoot?: string;
	bgColor?: string;
	fullFrame?: boolean;
	fullScreen?: boolean;
};

const Loader: React.FC<Props> & {
	size: typeof Size;
} = ({
	size = 'medium',
	color = '#f9fffe',
	bgColor = '#fff',
	className = '',
	classNameRoot = '',
	fullFrame,
	fullScreen,
}: React.ComponentProps<typeof Loader>) => {
	const loaderIcon = <div style={{ borderLeftColor: color }} className={cn(css.loader, css[size], className)} />;

	if (!fullFrame && !fullScreen) return loaderIcon;

	return (
		<div
			className={cn(classNameRoot, { [css.fullFrame]: fullFrame, [css.fullScreen]: fullScreen })}
			style={{ backgroundColor: bgColor }}
		>
			{loaderIcon}
		</div>
	);
};

Loader.size = Size;

export default Loader;
