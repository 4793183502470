import { merge } from 'lodash';
import { CARD_DEFAULT_TEMPLATE_NAME } from 'common/constants';
import { defaultTemplate } from 'utils/facades/templates/default-template';
import { CardFacadeBase } from 'utils/facades/card-facade-base';
import { CountType } from 'utils/facades/navigation-card-facade';
import type { StorySettingsType } from 'types/story';

const personalityTestDefaultTemplate = (params: Parameters<typeof defaultTemplate>[0]) => {
	const template = defaultTemplate(params);

	template.metadata.storySettings.cards = merge<StorySettingsType['cards'], StorySettingsType['cards']>(
		template.metadata.storySettings.cards ?? {},
		{
			// set CountType.character by default for a new cards created from scratch
			[template.editor.card._id]: { countType: CountType.character },
		}
	);

	return template;
};

class PersonalityTestFacade extends CardFacadeBase {
	static template = {
		[CARD_DEFAULT_TEMPLATE_NAME]: personalityTestDefaultTemplate,
	};

	getCountType = (storySettings: StorySettingsType) => {
		// use CountType.score to fallback old stories, because of it was initial type
		return storySettings.cards?.[this.data._id]?.countType ?? CountType.score;
	};
}

export { PersonalityTestFacade };
