import { OTHER_PROPS } from 'common/constants/component-props';
import { IS_PROD } from 'src/common/utils/environment';

const DEFAULT_VIDEO_SRC_DEV =
	'https://assets.dev.story-cards.com/abr_vods/storycards/a93ba043-4ee3-4653-b67a-75068482d1af/vod.m3u8';
const DEFAULT_VIDEO_POSTER_DEV =
	// eslint-disable-next-line max-len
	'https://assets.dev.story-cards.com/abr_vods/storycards/a93ba043-4ee3-4653-b67a-75068482d1af/vod_frames_poster.0000000.jpg';

const DEFAULT_VIDEO_SRC_PROD =
	'https://assets.dev.story-cards.com/abr_vods/storycards/a93ba043-4ee3-4653-b67a-75068482d1af/vod.m3u8';
const DEFAULT_VIDEO_POSTER_PROD =
	// eslint-disable-next-line max-len
	'https://assets.dev.story-cards.com/abr_vods/storycards/a93ba043-4ee3-4653-b67a-75068482d1af/vod_frames_poster.0000000.jpg';

export const defaultProps = {
	[OTHER_PROPS.video.videoSrc]: IS_PROD ? DEFAULT_VIDEO_SRC_PROD : DEFAULT_VIDEO_SRC_DEV,
	[OTHER_PROPS.video.videoPoster]: IS_PROD ? DEFAULT_VIDEO_POSTER_PROD : DEFAULT_VIDEO_POSTER_DEV,
	[OTHER_PROPS.video.controls]: false,
	[OTHER_PROPS.video.autoplay]: false,
	[OTHER_PROPS.video.loop]: false,
	[OTHER_PROPS.video.muted]: false,
	[OTHER_PROPS.video.autopause]: true,
	[OTHER_PROPS.video.soundControl]: true,
	[OTHER_PROPS.video.playbackSpeedControl]: false,
	[OTHER_PROPS.video.fullscreenControl]: true,
	[OTHER_PROPS.video.pipModeControl]: true,
	[OTHER_PROPS.video.skipControl]: false,
};
