import React from 'react';
import { LAYER_TYPE } from 'common/constants';
import css from './FixedWrapper.scss';

type Props = {
	zIndex: number;
	children: React.ReactElement;
};

const FixedWrapper = (props: Props) => {
	return (
		<div data-bb className={css.fixed} style={{ zIndex: props.zIndex, position: 'fixed' }}>
			{props.children}
		</div>
	);
};

function withFixedWrapper({ layer, zIndex, children, key, isCardTree }) {
	if ((layer === LAYER_TYPE.FIXED && isCardTree) || (layer === LAYER_TYPE.FIXED_GLOBAL && !isCardTree)) {
		return (
			<FixedWrapper zIndex={zIndex} key={`withFixedWrapper-${key}`}>
				{children}
			</FixedWrapper>
		);
	}

	return children;
}

export { withFixedWrapper };
