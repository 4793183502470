import React from 'react';
import type { BBCommonProps } from 'types/story';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import { LottieTrigger } from './utils';

const LottieComponent = React.lazy(() => import('./LottieComponent'));

const Lottie: React.FC<BBCommonProps> = props => {
	/*
	 Update the key to re-render the component and autoplay the lottie animation on scroll in card editor properly,
	 with every change made by user.
	 */
	let key = '';
	if (props.isEditableMode && props.uiConfig.componentProps.other.ltTrigger === LottieTrigger.scroll) {
		key = `${props._id}-${Math.random().toString(36).substring(7)}`;
	}

	return (
		<React.Suspense fallback={<div ref={props.containerRef as React.RefObject<HTMLDivElement>} />}>
			<LottieComponent {...props} {...(key ? { key } : undefined)} />
		</React.Suspense>
	);
};
export default withCardTransitionContext(Lottie);
