import React, { Suspense, useEffect, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import type { CardData } from 'types/story';
import type { ClientReducerState } from 'client/reducers';
import type { VariableDataType } from 'common/utils/variables/types';
import { getAvailableVariables } from 'common/utils/variables/variables';
import { useClientSelector } from 'client/reducers';
import { selectStory, selectStorySpreadsheetVariables } from 'client/reducers/story/selectors';
import { selectCard } from 'client/reducers/card/selectors';

type Props = ConnectedProps<typeof connector> & {
	html: string;
	onChange: (editorState: any) => void;
	onComplete: () => void;
	editableRef: React.RefObject<HTMLDivElement>;
};

const RichEditor = React.lazy(() => import('client/components/common/RichEditor'));

const ContentEditable = ({ html, onChange, onComplete, editableRef, selection }: Props) => {
	const id = editableRef.current?.id ?? '';
	const isSelected = useRef(selection[id] !== undefined);

	const story = useClientSelector(selectStory, () => true)!;
	const spreadsheetVariables = useClientSelector(selectStorySpreadsheetVariables, () => true);
	const card = useClientSelector(selectCard) as CardData;
	const variablesRef = useRef<VariableDataType[]>([]);
	if (variablesRef.current.length === 0) {
		// Get variables once, do not recompute them during next text edit.
		// Variables are used to display [data-mention] and <MentionSuggestions />
		variablesRef.current = getAvailableVariables({ story, spreadsheetVariables, card });
	}

	useEffect(
		function completeOnSelectionChange() {
			if (isSelected.current && !selection[id]) {
				onComplete();
			}

			isSelected.current = selection[id] !== undefined;
		},
		[onComplete, selection, id]
	);

	return (
		// eslint-disable-next-line react/no-danger
		<Suspense fallback={<div dangerouslySetInnerHTML={{ __html: html }} />}>
			<RichEditor html={html} onChange={onChange} variables={variablesRef.current} />
		</Suspense>
	);
};

const connector = connect(({ editor }: ClientReducerState) => ({ selection: editor.selection.clicked }));

export default connector(ContentEditable);
