/* eslint-disable react/no-array-index-key */
import React from 'react';
import cn from 'classnames';

import { useClientSelector } from 'src/routes/client/reducers';
import { selectStoryLang } from 'src/routes/client/reducers/story/selectors';
import {
	COUNTER_DIGIT_DATA_ATTR,
	UNIT_LABELS,
	CounterUnit,
	COUNTER_LABEL_DATA_ATTR,
	COUNTER_UNIT_DATA_ATTR,
	CounterAnimation,
} from './constants';
import css from './Counter.scss';

interface Props {
	className?: string;
	type: CounterUnit;
	value: number;
	label: boolean;
	animation?: CounterAnimation;
}

export const Unit = (props: Props) => {
	const { type, value, label, animation, className } = props;
	const digitsAmount = Math.max(value.toString().length, 2);

	return (
		<div className={cn(css.unit, className)} {...{ [COUNTER_UNIT_DATA_ATTR]: '' }}>
			<div className={css.digits}>
				{Array(digitsAmount)
					.fill(null)
					.map((_, index) => {
						const indexedValue = Math.floor(value / 10 ** (digitsAmount - index - 1)) % 10;

						return <Digit value={indexedValue} key={index} animation={animation} />;
					})}
			</div>
			{label && <Label type={type} />}
		</div>
	);
};

interface LabelProps {
	type: CounterUnit;
}

const Label = (props: LabelProps) => {
	const { type } = props;
	const lang = useClientSelector(selectStoryLang);

	return (
		<div className={css.label} {...{ [COUNTER_LABEL_DATA_ATTR]: '' }}>
			{UNIT_LABELS[lang] ? UNIT_LABELS[lang][type] : UNIT_LABELS.EN[type]}
		</div>
	);
};

interface DigitProps {
	className?: string;
	value: number;
	animation?: CounterAnimation;
}

const Digit = (props: DigitProps) => {
	const { animation = CounterAnimation.SwipeDown } = props;
	const [currentValue, setCurrentValue] = React.useState(props.value);
	const [nextValue, setNextValue] = React.useState(props.value);
	const [isTransitionActive, setIsTransitionActive] = React.useState(false);

	React.useEffect(() => {
		let isMounted = true;

		async function changeValue() {
			switch (animation) {
				case CounterAnimation.SwipeDown:
				case CounterAnimation.SwipeUp:
				case CounterAnimation.Fade:
					setNextValue(props.value);
					setIsTransitionActive(true);

					await new Promise(resolve => setTimeout(resolve, 500));

					if (isMounted) {
						setCurrentValue(props.value);
						setIsTransitionActive(false);
					}
					break;
				default:
					setCurrentValue(props.value);
					break;
			}
		}

		if (props.value !== currentValue) {
			changeValue();
		}

		return () => {
			isMounted = false;
		};
	}, [props.value, currentValue, animation]);

	return (
		<div
			className={cn(
				css.digit,
				css[`transition_${animation}`],
				isTransitionActive && css.transitionActive,
				props.className
			)}
			{...{ [COUNTER_DIGIT_DATA_ATTR]: '' }}
		>
			{animation !== CounterAnimation.None && <div className={css.digitValueNext}>{nextValue}</div>}
			<div className={css.digitValue}>{currentValue}</div>
			<div className={css.digitValueBoundingBox}>{8}</div>
		</div>
	);
};

export default Unit;
