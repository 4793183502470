import React, { RefObject } from 'react';
import cn from 'classnames';
import type { BBCommonProps, BBEditableModeProps } from 'types/story';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import css from 'client/components/common/BuildingBlocks/Embed/Embed.scss';
import { ScriptRenderer } from 'src/routes/client/components/common/ScriptRenderer/ScriptRenderer';

type Props = BBCommonProps;

class Embed extends React.Component<Props> {
	renderEditable() {
		const props = { ...this.props.editableModeProps?.nodeProps };
		return this.renderDefault(props);
	}

	renderDefault(props: Partial<BBEditableModeProps['nodeProps']> = {}) {
		const script = this.props.uiConfig.componentProps?.other?.embedCode ?? '';
		const { nodeProps } = this.props.uiConfig;
		const className = cn(css.embed, nodeProps.className, props.className, { [css.empty]: !script });

		return (
			<div
				{...nodeProps}
				{...this.props.stateAttrs}
				{...this.props.eventListeners}
				{...props}
				className={className}
				ref={this.props.containerRef as RefObject<HTMLDivElement>}
			>
				<ScriptRenderer value={this.props.uiConfig.componentProps?.other?.embedCode ?? ''} />
			</div>
		);
	}

	render() {
		return this.props.isEditableMode ? this.renderEditable() : this.renderDefault();
	}
}

export default withCardTransitionContext(Embed);
