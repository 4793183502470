/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

export default ({
	color = '#FFF',
	empty,
	...props
}: SVGAttributes<SVGElement> & { color?: string; empty?: boolean }) => (
	<svg width={18} xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24">
		<path
			fill={color}
			d={
				empty
					? 'M19.71 8.041L7.75 20.001H4v-3.75l11.96-11.96c.18-.18.411-.277.646-.291h.118c.196.012.389.081.552.208l.094.083 2.34 2.34c.36.36.388.924.083 1.316l-.083.094zm-5.167-.918L5 16.665v2.336h2.334l9.544-9.543-2.335-2.335zm2.122-2.122L15.25 6.415l2.335 2.335L19 7.336l-2.335-2.335z'
					: 'M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z'
			}
		/>
	</svg>
);
