import React, { MutableRefObject, useRef } from 'react';
import cn from 'classnames';
import { EditorMode } from 'types/story';
import { useDidMount } from 'common/components/useDidMount';
import { useWillUnmount } from 'common/components/useWillUnmount';
import { FIELD_ERROR_DATA_ATTR } from '../constants';
import { ErrorEditable } from './ErrorEditable';
import css from './Error.scss';

function updateMarginRaf(nodeRef: MutableRefObject<HTMLParagraphElement | null>, raf: MutableRefObject<number>) {
	const node = nodeRef.current;

	if (!node) {
		return;
	}

	const parent = node.parentNode as HTMLElement;
	const nodeH = window.getComputedStyle(node).height;
	const nodeVwH = `${(parseInt(nodeH, 10) / window.innerWidth) * 100}vw`;
	const { marginBottom } = parent.style;

	if (marginBottom !== nodeVwH) {
		// eslint-disable-next-line no-param-reassign
		parent.style.marginBottom = nodeVwH;
	}

	// eslint-disable-next-line no-param-reassign
	raf.current = requestAnimationFrame(() => updateMarginRaf(nodeRef, raf));
}

type Props = {
	isEditableMode: boolean;
	text: string;
	errorType?: string;
	path?: string;
	isHidden: boolean;
	editorMode: EditorMode;
};

export const Error = ({ path = '', errorType, isEditableMode, isHidden, ...props }: Props) => {
	const raf = useRef(0);
	const nodeRef = useRef<HTMLParagraphElement>(null);
	useDidMount(() => {
		updateMarginRaf(nodeRef, raf);
	});

	useWillUnmount(() => {
		cancelAnimationFrame(raf.current);
		const parent = nodeRef.current && (nodeRef.current.parentNode as HTMLElement);
		if (parent) {
			parent.style.marginBottom = '';
		}
	});

	const isEditableError = errorType !== undefined && isEditableMode;
	const nodeProps = {
		ref: nodeRef,
		className: cn(css.error, { [css.hidden]: isHidden }),
		[FIELD_ERROR_DATA_ATTR]: '',
	};

	return isEditableError ? (
		<div {...nodeProps}>
			<ErrorEditable path={path} errorType={errorType} text={props.text} editorMode={props.editorMode} />
		</div>
	) : (
		// eslint-disable-next-line react/no-danger
		<div {...nodeProps} dangerouslySetInnerHTML={{ __html: props.text }} />
	);
};
