import type { DictionaryType } from 'types/story';
import arrLeft from 'common/assets/images/arr-left.svg';
import arrRight from 'common/assets/images/arr-right.svg';
import {
	COMPONENT_TYPE as TYPE,
	COMPONENT_DISPLAY_NAME,
	LAYER_TYPE,
	Pin,
	COMPONENT_STATES,
	DEFAULT_MEDIA_QUERY_PLATFORMS,
} from 'common/constants';
import { generateElementId, generateNodeId } from 'utils/generate-id';
import { OTHER_PROPS, UNIFORM_PROPS, CSS_PROPS } from 'common/constants/component-props';
import { SliderArrowDir } from 'client/components/common/BuildingBlocks/Slider/constants';
import { IMG } from 'client/components/common/BuildingBlocks/Img';
import View from './SliderArrow';

function getPinType(props: Pick<Props, 'lang' | 'direction'>) {
	if (props.lang?.toLowerCase() === 'he') {
		return props.direction === SliderArrowDir.PREV ? Pin.r : Pin.l;
	}
	return props.direction === SliderArrowDir.PREV ? Pin.l : Pin.r;
}

const iconLookup = {
	he: {
		[SliderArrowDir.PREV]: arrRight,
		[SliderArrowDir.NEXT]: arrLeft,
	},
	default: {
		[SliderArrowDir.PREV]: arrLeft,
		[SliderArrowDir.NEXT]: arrRight,
	},
};

function getArrowIcon(props: Pick<Props, 'lang' | 'direction'>): string {
	const language = props.lang?.toLowerCase() === 'he' ? 'he' : 'default';
	return iconLookup[language][props.direction ?? SliderArrowDir.NEXT];
}

type Props = {
	direction?: SliderArrowDir;
	dictionary?: DictionaryType;
	lang?: string;
};

export const SLIDER_ARROW = (props: Props = {}) => {
	const _id = generateElementId();

	return {
		view: View,
		get model() {
			const type = TYPE.SLIDER_ARROW;
			const pinType = getPinType(props);

			return {
				_id,
				type,
				uiConfig: {
					layer: LAYER_TYPE.CONTENT,
					editorProps: {
						name: `${COMPONENT_DISPLAY_NAME[type]} ${props.direction}`,
						selectable: true,
						draggable: false,
						expandable: true,
						expanded: false,
					},
					nodeProps: {
						id: generateNodeId(_id, type),
					},
					componentProps: {
						[UNIFORM_PROPS.sliderArrowDir]: props.direction,
						styles: {
							[COMPONENT_STATES.DEFAULT]: {
								[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]: {
									[CSS_PROPS.position.left]: pinType === Pin.l ? '1.56vw' : '-1.56vw',
								},
							},
						},
						other: {
							[COMPONENT_STATES.DEFAULT]: {
								[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]: {
									[OTHER_PROPS.pin.pinType]: pinType,
								},
							},
						},
					},
				},
				children: [
					IMG({
						styles: {
							[COMPONENT_STATES.DEFAULT]: {
								[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]: {
									[CSS_PROPS.background.backgroundImage]: `url(${getArrowIcon(props)})`,
									[CSS_PROPS.size.height]: '2.90vw',
									[CSS_PROPS.size.width]: '1.61vw',
									[CSS_PROPS.layout.alignSelf]: 'center',
									[CSS_PROPS.layout.justifySelf]: 'center',
									[CSS_PROPS.position.top]: 'auto',
									[CSS_PROPS.position.right]: 'auto',
									[CSS_PROPS.position.bottom]: 'auto',
									[CSS_PROPS.position.left]: pinType === Pin.l ? '-0.19vw' : '0.19vw',
								},
								[DEFAULT_MEDIA_QUERY_PLATFORMS.MOBILE]: {
									[CSS_PROPS.size.height]: '8vw',
									[CSS_PROPS.size.width]: '8vw',
									[CSS_PROPS.layout.alignSelf]: 'center',
									[CSS_PROPS.layout.justifySelf]: 'center',
									[CSS_PROPS.position.top]: 'auto',
									[CSS_PROPS.position.right]: 'auto',
									[CSS_PROPS.position.bottom]: 'auto',
								},
							},
						},
						other: {
							[COMPONENT_STATES.DEFAULT]: {
								[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]: {
									[OTHER_PROPS.pin.pinType]: getPinType(props),
								},
							},
						},
					}).model,
				],
			};
		},
	};
};
