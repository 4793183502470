import React from 'react';
import cn from 'classnames';

import { Context as ResponsiveProviderContext } from 'client/components/common/ResponsiveProvider/Context';
import { DEFAULT_MEDIA_QUERY_PLATFORMS } from 'src/common/constants';
import { CONTAINER_ID, CONTAINER_WRAP_ID, CONTAINER_POSITION_TOP_OFFSET_EL_ID } from './constants';
import { useOutbrain } from './use-outbrain';
import css from './OutbrainWidget.scss';

const { MOBILE, MOBILE_LANDSCAPE, TABLET } = DEFAULT_MEDIA_QUERY_PLATFORMS;
const OutbrainWidget: React.FC = () => {
	const { outbrain, shouldRenderWidget } = useOutbrain();
	const canonicalLink = document.querySelector<HTMLLinkElement>("link[rel='canonical']");
	const canonicalURL = canonicalLink?.href ?? window.location.href;
	const { currentMediaQuery: platform } = React.useContext(ResponsiveProviderContext);
	const isMobile = platform === MOBILE || platform === MOBILE_LANDSCAPE || platform === TABLET;
	const widgetId = isMobile ? outbrain?.mobileWidgetId : outbrain?.desktopWidgetId;

	React.useEffect(() => {
		let isMounted = true;
		let retryCount = 0;

		async function loadOutbrain() {
			if (shouldRenderWidget) {
				(window as any).OBR?.extern.reloadWidget();

				await new Promise(resolve => setTimeout(resolve, 1000));
				await retryRefresh();
			}
		}

		async function retryRefresh() {
			if (!isMounted || document.getElementById(CONTAINER_ID)?.children?.length || retryCount >= 5) return;

			(window as any).OBR?.extern.refreshWidget(canonicalURL);

			await new Promise(resolve => setTimeout(resolve, 500 * 2 ** retryCount));
			if (!isMounted) return;

			retryCount += 1;
			await retryRefresh();
		}

		loadOutbrain();

		return () => {
			isMounted = false;
		};
	}, [shouldRenderWidget, canonicalURL]);

	if (!shouldRenderWidget) {
		return null;
	}

	return (
		// CONTAINER_ID is replaced with new DIV id in runtime
		// other part of application ("Credit" component) may need a way to find widget container:
		//
		// CONTAINER_POSITION_TOP_OFFSET_EL_ID is a helper for IntersectionObserver inside
		// useOutbrain hook. It's used to calculate widget position on the page (with predefined offset).
		<div id={CONTAINER_WRAP_ID} className={cn(css.outbrainWidget, isMobile === false && css.desktopPlatform)}>
			<div id={CONTAINER_POSITION_TOP_OFFSET_EL_ID} className={css.outbrainWidgetTopOffset} />
			<div className="OUTBRAIN" data-src={canonicalURL} data-widget-id={widgetId} id={CONTAINER_ID} />
		</div>
	);
};

export default OutbrainWidget;
