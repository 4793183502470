import { createStore, compose, applyMiddleware } from 'redux';
import { actionTypes as formActionTypes } from 'redux-form';
import { thunk } from 'redux-thunk';
import { createBrowserHistory as createHistory } from 'history';
import { createLogger } from 'redux-logger';

const history = createHistory();

const devMiddleware = [
	// thunk middleware can also accept an extra argument to be passed to each thunk action
	// https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
	// thunk.withExtraArgument(thunkExtraArguments),
	thunk,
];

if (process.env.REDUX_LOG === undefined) {
	devMiddleware.push(
		// must come after thunks to avoid undefined action dispatches
		// Redux action logging
		createLogger({
			collapsed: true,
			predicate: (getState, action) => !/@@redux-form\/(UN)?REGISTER_FIELD/.test(action.type),
		})
	);
}

type Options = {
	id: string;
	middleware?: () => any[];
};

function configureStoreDev(initialState, reducer, options: Options) {
	/*
	 Why store is created conditionally and middleware is a function, but not just an array?
	 > Fix HMR issue: store duplication. Any change in redux initializes new additional store.
	 > It's bad for state-sync-middleware.ts, because of it creates extra BroadcastChannel which leads to infinity loop

	 todo: fix HMR for redux, then get rid of conditional store creation after
	 */
	let store = window[options.id];
	if (!store) {
		const middlewareMap = {
			dev: devMiddleware,
			prod: [thunk],
		};
		const isDev = process.env.NODE_ENV !== 'production';
		const middleware = isDev ? middlewareMap.dev : middlewareMap.prod;

		if (options.middleware) {
			middleware.push(...options.middleware());
		}

		// add support for Redux dev tools:
		const devtools = isDev ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : undefined;
		const composeEnhancers =
			devtools?.({
				actionsDenylist: [formActionTypes.UNREGISTER_FIELD, formActionTypes.REGISTER_FIELD],
				name: `${window.location.host} [${document.documentElement.id === 'preview' ? ' Preview' : 'Editor'}]
				       — ${new Date().toLocaleTimeString()}`,
			}) || compose;

		store = createStore(reducer, initialState, composeEnhancers(applyMiddleware(...middleware)));

		window[options.id] = store;
	}

	if ((module as any).hot) {
		// Enable Webpack hot module replacement for reducers
		(module as any).hot.accept('./reducers', () => store.replaceReducer(reducer));
	}

	return store;
}

export { history };

// @todo create a production configure store function
export default configureStoreDev;
