import {
	COMPONENT_STATES,
	COMPONENT_TYPE as TYPE,
	DEFAULT_MEDIA_QUERY_PLATFORMS,
	LAYER_TYPE,
	COMPONENT_DISPLAY_NAME,
} from 'common/constants';
import { generateElementId, generateNodeId } from 'common/utils/generate-id';
import { OTHER_PROPS, UNIFORM_PROPS } from 'common/constants/component-props';
import View from './Overlay';
import { defaultProps } from './constants';

export const OVERLAY = () => {
	const _id = generateElementId();

	return {
		view: View,
		get model() {
			const type = TYPE.OVERLAY;

			return {
				_id,
				type,
				uiConfig: {
					layer: LAYER_TYPE.CONTENT,
					editorProps: {
						name: COMPONENT_DISPLAY_NAME[type],
						selectable: true,
						draggable: true,
						expandable: true,
						expanded: false,
					},
					nodeProps: {
						id: generateNodeId(_id, type),
					},
					componentProps: {
						[UNIFORM_PROPS.overlayType]: defaultProps.overlayType,
						[UNIFORM_PROPS.overlayShowOn]: defaultProps.overlayShowOn,
						[UNIFORM_PROPS.overlayTriggers]: defaultProps.overlayTriggers,
						styles: {},
						other: {
							[COMPONENT_STATES.DEFAULT]: {
								[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]: {
									[OTHER_PROPS.overlay.overlayBackdrop]: defaultProps.overlayBackdrop,
									[OTHER_PROPS.overlay.overlayBackdropVisible]: defaultProps.overlayBackdropVisible,
									[OTHER_PROPS.overlay.overlayVisible]: defaultProps.overlayVisible,
									[OTHER_PROPS.overlay.overlayAnimationPreset]: defaultProps.overlayAnimationPreset,
								},
							},
						},
					},
				},
				children: [],
			};
		},
	};
};
