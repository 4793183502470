import {
	COMPONENT_TYPE as TYPE,
	LAYER_TYPE,
	COMPONENT_DISPLAY_NAME,
	COMPONENT_STATES as STATE,
	DEFAULT_MEDIA_QUERY_PLATFORMS as MQ,
	CONTENT_ALIGN_Y,
} from 'common/constants';
import { generateNodeId, generateElementId } from 'utils/generate-id';

export default function CardModel() {
	const _id = generateElementId();
	const type = TYPE.CARD;

	return {
		_id,
		type,
		uiConfig: {
			layer: LAYER_TYPE.CONTENT,
			editorProps: {
				name: COMPONENT_DISPLAY_NAME[type],
				selectable: true,
				draggable: false,
				expandable: false,
				expanded: false,
			},
			nodeProps: {
				id: generateNodeId(_id, type),
			},
			componentProps: {
				other: {
					[STATE.DEFAULT]: {
						[MQ.DEFAULT]: {
							contentAlignY: CONTENT_ALIGN_Y.top,
							muted: true,
						},
					},
				},
				styles: {},
			},
		},
		children: [],
	};
}
