import a1 from 'common/assets/images/avatar-1.svg';
import a2 from 'common/assets/images/avatar-2.svg';
import a3 from 'common/assets/images/avatar-3.svg';
import a4 from 'common/assets/images/avatar-4.svg';
import a5 from 'common/assets/images/avatar-5.svg';
import a6 from 'common/assets/images/avatar-6.svg';
import a7 from 'common/assets/images/avatar-7.svg';
import a8 from 'common/assets/images/avatar-8.svg';
import a9 from 'common/assets/images/avatar-9.svg';
import a10 from 'common/assets/images/avatar-10.svg';
import a11 from 'common/assets/images/avatar-11.svg';
import a12 from 'common/assets/images/avatar-12.svg';
import a13 from 'common/assets/images/avatar-13.svg';
import a14 from 'common/assets/images/avatar-14.svg';
import a15 from 'common/assets/images/avatar-15.svg';
import a16 from 'common/assets/images/avatar-16.svg';
import a17 from 'common/assets/images/avatar-17.svg';
import { IS_DEVEL } from 'src/common/constants';
import { buildLocalAssetsUrl } from 'utils/assets';

const avatars = [a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, a12, a13, a14, a15, a16, a17];

export const getAvatar = () => {
	const index = Math.floor(Math.random() * (avatars.length - 1));

	if (IS_DEVEL) {
		return avatars[index];
	}
	return `${buildLocalAssetsUrl(`/assets/images/avatar-${index + 1}.svg`)}`;
};
