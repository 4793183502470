import React, { ReactNode, RefObject } from 'react';
import cn from 'classnames';
import type { BBCommonProps, BBEditableModeProps } from 'types/story';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import { ChildrenWithParentState } from 'client/components/common/BuildingBlocks/ChildrenWithParentState';
import { getClassNames } from './constants';
import css from './Box.scss';

type Props = BBCommonProps & { children: ReactNode };

class Box extends React.Component<Props> {
	renderEditable() {
		const props = { ...this.props.editableModeProps?.nodeProps };
		return this.renderDefault(props);
	}

	renderDefault(props: Partial<BBEditableModeProps['nodeProps']> = {}) {
		const { nodeProps, componentProps } = this.props.uiConfig;
		const { boxType } = componentProps.other ?? {};

		return (
			<div
				{...nodeProps}
				{...this.props.stateAttrs}
				{...this.props.eventListeners}
				{...props}
				data-box-type={boxType}
				className={cn(
					css.box,
					nodeProps.className,
					props.className,
					getClassNames({ uiConfig: this.props.uiConfig })
				)}
				ref={this.props.containerRef as RefObject<HTMLDivElement>}
			>
				{this.props.isEditableMode ? (
					this.props.children
				) : (
					<ChildrenWithParentState states={this.props.parentStates}>
						{/* parent states can be received from parent Answer BB or parent Swipe BB */}
						{this.props.children}
					</ChildrenWithParentState>
				)}
			</div>
		);
	}

	render() {
		return this.props.isEditableMode ? this.renderEditable() : this.renderDefault();
	}
}

export default withCardTransitionContext(Box);
