import { size, get, isEmpty } from 'lodash';
import React, { ReactNode } from 'react';
import { createRoot } from 'react-dom/client';
import { connect, ConnectedProps } from 'react-redux';

import type { BBCommonProps, StorySettingsOfCard } from 'types/story';
import { sleep } from 'utils/helpers';
import { location } from 'utils/url-helper';
import { COMPONENT_STATES, COMPONENT_TYPE } from 'common/constants';

import { StoryHistory } from 'client/utils';
import type { ClientReducerState } from 'client/reducers';
import { getCardVotes, sendAnswer } from 'client/actions/api';
import { updateAnswers } from 'client/actions/update-answers';
import type { EventProviderContextT } from 'client/components/pages/Story/EventProvider/Context';
import { TimerEvEm } from 'client/components/common/BuildingBlocks/Timer/utils';
import { AnimationEvEm } from 'client/components/common/BuildingBlocks/animation';
import { ScriptRenderer } from 'client/components/common/ScriptRenderer/ScriptRenderer';
import { LottieEvEm } from 'client/components/common/BuildingBlocks/Lottie/utils';
import { ChildrenWithParentState } from 'client/components/common/BuildingBlocks/ChildrenWithParentState';
import AnswerBody from './AnswerBody';
import AnswerContext from './Context';

const { isPreview } = location.client;
const WRONG_ANSWER_DELAY = 500;

function getIsCorrect(
	id: DefaultAnswerProps['_id'],
	answersSettings: NonNullable<DefaultAnswerProps['cardSettings']>['answers']
) {
	const answerSettings = answersSettings?.[id];
	return answerSettings && 'isCorrect' in answerSettings ? answerSettings.isCorrect! : false;
}

function getResult(
	id: DefaultAnswerProps['_id'],
	answersSettings: NonNullable<DefaultAnswerProps['cardSettings']>['answers']
) {
	const isCorrect = getIsCorrect(id, answersSettings);
	return { correct: isCorrect, incorrect: !isCorrect };
}

/**
 * User could delete result blocks from Answer and in this case showResults must be a "false".
 * This only applies to stories and templates created in version 1.8.0 and earlier.
 * Newer ones are controlled by "showResults" prop only
 */
function hasResultBlocks(children: ReactNode, showResults: boolean) {
	let result = false;

	React.Children.forEach(children, child => {
		if (!React.isValidElement<BBCommonProps>(child)) {
			return;
		}
		const re = new RegExp(`${COMPONENT_TYPE.RESULT_TEXT}|${COMPONENT_TYPE.RESULT_SHAPE}`);

		if (
			showResults &&
			child.props.type.match(re) !== null &&
			get(child.props, 'uiConfig.componentProps.styles.display') !== 'none'
		) {
			result = true;
		}
	});

	return result;
}

const connector = connect(null, { sendAnswer, updateAnswers, getCardVotes });

type DefaultAnswerProps = ConnectedProps<typeof connector> &
	BBCommonProps &
	EventProviderContextT & { children: ReactNode } & {
		storyId: string;
		cardId: string;
		showResults: boolean;
		showCorrect: boolean;
		cardSettings: StorySettingsOfCard | undefined;
		answersData: ClientReducerState['user']['answers'][string];
		answerTimeout: number;
	};

type DefaultAnswerState = { result: null | { correct: boolean; incorrect: boolean } };

/**
 * How does it work:
 * 1. Click on Answer
 * 2. Send answer (only prod)
 * 3. Get votes statistics from BE (only prod & showResults & hasResultBlocks)
 * 4. Store answer & votes in client redux store
 * 4.1. re-render component and provide answerData
 * 4.2. @:getDerivedStateFromProps: add answer result to state of selected Answer
 * 4.2.1. @:componentDidUpdate: (only if "isCanBeCorrect": true) update correct|incorrect state and show it visually
 * 4.3. (only if "showResults" & "hasResultBlocks") render children with votes results from 4.1 (ResultText|ResultShape)
 */
class DefaultAnswer extends React.Component<DefaultAnswerProps, DefaultAnswerState> {
	state = {
		result: null,
	};

	hasResultBlocks = hasResultBlocks(this.props.children, this.props.showResults);

	// if true: prevent send answer multiple times
	isClicked = false;

	/* prevent infinite componentDidUpdate call by updateCorrectStates()
	 in case when Answer is by fault is nested in Swipe */
	isUpdatedCorrectStates = false;

	static getDerivedStateFromProps(props: DefaultAnswerProps, state: DefaultAnswerState): Partial<DefaultAnswerState> {
		const nextState: Partial<DefaultAnswerState> = {};

		const isCurrentAnswered = props.answersData?.selected?.includes(props._id);
		if (isCurrentAnswered) {
			nextState.result = getResult(props._id, props.cardSettings?.answers);
		}

		return nextState;
	}

	componentDidMount() {
		const { onClickCapture } = this.ownListeners;
		// Register component events in provider, potentially Swipe BB can use it.
		if (onClickCapture)
			this.props.eventProvider.setComponentEvents(this.props._id, { onAnswerClick: onClickCapture });
	}

	componentDidUpdate(prevProps: DefaultAnswerProps, prevState: DefaultAnswerState) {
		const { showCorrect, showResults, states, _id } = this.props;
		if (showCorrect) this.showAnswerResultByCorrectProp();
		else if (showResults && !states.selectedState && this.selected.includes(_id)) this.showSelectedBySelectedProp();
	}

	get selected() {
		return this.props.answersData?.selected ?? [];
	}

	get isAllowAnswer() {
		return this.selected.length === 0;
	}

	get ownListeners() {
		if (this.props.isEditableMode) {
			return {};
		}

		return {
			/**
			 * Event isn't always a React.MouseEvent, because of listener may be invoked programmatically
			 * by another component. Event can be equal to "swipe" only when it is called by Swipe BB
			 */
			onClickCapture: async (event: React.MouseEvent<HTMLElement, MouseEvent> | 'swipe') => {
				const isMouseEvent = event !== 'swipe' && event?.nativeEvent instanceof Event;

				if (isMouseEvent) {
					if (this.isClicked || !this.isAllowAnswer) {
						/*
						 Prevent Answer click in case of:
						 - event is not called by Swipe component through eventProvider
						 - mouse event is called more than 1 time
						 - reached answersMax
						 */
						return;
					}
					// The problem is when you do event.stopPropagation in capture phase it cancels handler in
					// bubbling phase on the element, what breaks animation with onclick trigger,
					// so as fix — normal onclick listener is called manually:
					this.props.eventListeners?.onClick?.(event);
				}

				this.isClicked = true;

				const nextStates = { [COMPONENT_STATES.HOVER]: false };

				if (!this.props.showCorrect) nextStates[COMPONENT_STATES.SELECTED] = true;

				this.props.setStates(nextStates);

				// Call event assigned by Swipe BB (optional. example is a "TrueOrFalse|ThisOrThat" card)
				const providedSwipeHandler = this.props.eventProvider.events.component[this.props._id]?.onSwipe;
				if (isMouseEvent && providedSwipeHandler) {
					providedSwipeHandler();
					return;
				}

				const isCorrect = getIsCorrect(this.props._id, this.props.cardSettings?.answers);
				const nodeId = this.props.uiConfig.nodeProps.id;
				TimerEvEm.emit('pause', { cardId: this.props.cardId });
				LottieEvEm.emit('playback', { action: 'onAnswer', answerNodeId: nodeId, isCorrect });
				AnimationEvEm.emit('playback', { action: 'onAnswer', answerNodeId: nodeId, isCorrect });

				const { showResults } = this;
				const { cardId, storyId, _id } = this.props;

				const answerText = this.props.containerRef?.current?.innerText || 'MediaContent*';
				const sendAnswerPayload = {
					storyId,
					cardId,
					answerId: _id,
					answerText,
				};

				// Store answer in redux
				this.props.updateAnswers({ cardId: this.props.cardId, answerId: _id });

				// Store answer to localStorage (used then to calculate a total score)
				const sHistory = new StoryHistory({ storyId });
				sHistory.addAnswer({ cardId, selectedAnswers: [{ id: _id }] });

				// Render answer embed code
				const pixels = this.props.uiConfig.componentProps?.other?.embedCode;
				if (pixels) {
					const container = document.createElement('div');
					container.id = `answer-script-${cardId}-${_id}`;
					document.body.appendChild(container);
					const root = createRoot(container);
					root.render(<ScriptRenderer value={pixels} scriptOnly />);
				}

				try {
					if (!isPreview) {
						// Store answer in BE
						this.props.sendAnswer(sendAnswerPayload);

						if (showResults) {
							// Update votes after marking the chosen answer, as this operation might be time-consuming
							const votes = await this.props.getCardVotes({ cardId, storyId });
							if (votes) this.props.updateAnswers({ cardId, votes });
						}
					}
				} catch (e) {
					console.error(e);
				}

				if (this.props.showCorrect && !isCorrect) {
					// wait WRONG_ANSWER_DELAY to show correct and wait 500 before to go forward
					await sleep(WRONG_ANSWER_DELAY + 500);
				} else if (nextStates[COMPONENT_STATES.SELECTED]) {
					await sleep(500);
				}

				if (this.props.answerTimeout) await sleep(this.props.answerTimeout);

				this.props.eventProvider.events.flow(_id);
			},
		};
	}

	get showResults() {
		return this.props.showResults && this.hasResultBlocks;
	}

	get answerVoteResult() {
		const isCorrect = this.props.states[COMPONENT_STATES.CORRECT];
		const isShow = !this.props.isEditableMode && this.selected.length > 0;
		const noVotes = isEmpty(this.props.answersData?.votes);
		let value = this.props.answersData?.votes?.[this.props._id];

		if (value === undefined) {
			value = isCorrect && noVotes ? 100 : 0;
		}

		// Provide vote data to child components to display vote results (see ResultText, ResultShape)
		return { isShow, value, duration: Math.max(350, this.props.answerTimeout * 0.35) };
	}

	/**
	 * Updates the visual state of the Answer component to reflect selection status.
	 * Typically, the Answer's click event listener manages the selected state.
	 * However, if an external component selects the Answer, this method updates the Answer's state.
	 * See `componentDidMount` in `StoryCard/Poll/index.tsx` (commit `0c77238a`) for usage example.
	 */
	showSelectedBySelectedProp = () => {
		this.props.setStates({
			[COMPONENT_STATES.HOVER]: false,
			[COMPONENT_STATES.SELECTED]: true,
		});
	};

	/**
	 * Update answer components states, to show visually is answer correct or not.
	 */
	showAnswerResultByCorrectProp = async () => {
		const p: DefaultAnswerProps = this.props;
		const s: DefaultAnswerState = this.state;
		const { CORRECT, INCORRECT } = COMPONENT_STATES;

		/**
		 * Update correct|incorrect states at "this.props.states" & "this.props.statesAttrs"
		 */
		const updateCorrectStates = () => {
			if (!s.result) {
				return;
			}

			const { correct, incorrect } = s.result;

			if (correct !== p.states[CORRECT] || incorrect !== p.states[INCORRECT]) {
				this.isUpdatedCorrectStates = true;
				p.setStates({
					...p.states,
					[CORRECT]: correct,
					[INCORRECT]: incorrect,
				});
			}
		};

		if (!this.isUpdatedCorrectStates) {
			updateCorrectStates();
		}

		const forceShowCorrect =
			p.showCorrect &&
			!s.result &&
			this.selected.length > 0 &&
			getIsCorrect(this.props._id, this.props.cardSettings?.answers);
		if (forceShowCorrect) {
			await sleep(WRONG_ANSWER_DELAY);
			this.setState({ result: getResult(p._id, p.cardSettings?.answers) });
		}
	};

	render() {
		return (
			<AnswerBody
				{...this.props}
				isDisabled={size(this.props.answersData?.selected) > 0}
				onClickCapture={this.ownListeners.onClickCapture}
			>
				<AnswerContext.Provider
					value={{
						showResults: this.props.showResults,
						answerVote: this.answerVoteResult,
					}}
				>
					{this.props.isEditableMode ? (
						this.props.children
					) : (
						<ChildrenWithParentState states={this.props.states /* change child states to answer state */}>
							{this.props.children}
						</ChildrenWithParentState>
					)}
				</AnswerContext.Provider>
			</AnswerBody>
		);
	}
}

export default connector(DefaultAnswer);
