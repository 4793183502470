import { useEffect, useMemo, useState } from 'react';

const translate = {
	en: { less: 'show less', more: 'show more' },
	he: { less: 'קרא פחות', more: 'קרא עוד' },
};

type UseTruncateProps = {
	// text that needs to be truncated
	text: string;
	// DOM node that renders text
	node: HTMLElement | undefined;
	// maximum number of lines
	maxRows: number | undefined;
	// disable event handlers on show more|less buttons
	disableEventHandlers?: boolean;
	// show/hide full text button class name
	btnClassName?: string;
	// truncated text container class name
	containerClassName: string;
};

enum TruncateState {
	showMore,
	showLess,
}

/**
 * Hook to cut the text if it exceeds the maximum number of lines. Adds buttons to show or hide the full text.
 */
const useTruncate = ({
	node,
	text,
	maxRows,
	disableEventHandlers = false,
	btnClassName = '',
	containerClassName,
}: UseTruncateProps) => {
	const [truncateState, setTruncateState] = useState(TruncateState.showMore);
	const lang = useMemo(() => {
		const val = document.documentElement.lang.toLowerCase();
		return val === 'en' || val === 'he' ? val : 'en';
	}, []);

	// handle show/hide button event listeners
	useEffect(() => {
		const onTruncateBtnClick = (e: MouseEvent) => {
			const target = e.target as HTMLDivElement;
			const isTruncateBtn = target.className === btnClassName && node?.contains(target);
			if (isTruncateBtn)
				setTruncateState(state => {
					if (state === TruncateState.showMore) return TruncateState.showLess;
					return TruncateState.showMore;
				});
		};

		if (!disableEventHandlers) {
			document.addEventListener('click', onTruncateBtnClick);
		}

		return () => {
			document.removeEventListener('click', onTruncateBtnClick);
		};
	}, [node, disableEventHandlers, btnClassName]);

	if (!node || !text || !maxRows) {
		return text;
	}

	// Get original text height
	const clone = node.cloneNode(true) as HTMLDivElement;
	clone.style.opacity = '0';
	clone.innerHTML = text;
	node.after(clone);
	const initial = { height: clone.offsetHeight, text: clone.textContent || clone.innerText };
	clone.remove();

	// Get
	const lineHeight = parseInt(window.getComputedStyle(node).lineHeight, 10);
	const initialRowsCount = Math.floor(initial.height / lineHeight);

	if (truncateState === TruncateState.showMore && initialRowsCount > maxRows && initial.text?.trim()) {
		const showMoreBtn = `<span class="${btnClassName}">${translate[lang].more}</span>`;
		return `<div class="${containerClassName}">${text}</div>${showMoreBtn}`;
	}

	if (truncateState === TruncateState.showLess) {
		const showLessBtn = `<span class="${btnClassName}">${translate[lang].less}</span>`;
		return `${text}${showLessBtn}`;
	}

	return text;
};

export default useTruncate;
