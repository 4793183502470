import { OTHER_PROPS } from 'common/constants/component-props';

export enum SliderArrowDir {
	PREV = 'prev',
	NEXT = 'next',
}

export enum SliderDir {
	HORIZONTAL = 'horizontal',
	VERTICAL = 'vertical',
}

export enum SliderEffect {
	SLIDE = 'slide',
	FADE = 'fade',
	COVERFLOW = 'coverflow',
	FLIP = 'flip',
}

export enum PaginationType {
	BULLETS = 'bullets',
	FRACTION = 'fraction',
	PROGRESSBAR = 'progressbar',
}

export const defaultProps = {
	[OTHER_PROPS.slider.speed]: 0.3,
	[OTHER_PROPS.slider.autoplayDelay]: 5,
	[OTHER_PROPS.slider.autoplay]: false,
	[OTHER_PROPS.slider.loop]: true,
	[OTHER_PROPS.slider.spaceBetween]: 0,
	[OTHER_PROPS.slider.slidesPerView]: 1,
	[OTHER_PROPS.slider.sliderDirection]: SliderDir.HORIZONTAL,
	[OTHER_PROPS.slider.sliderMousewheel]: false,
	[OTHER_PROPS.slider.disableBackwardSlide]: false,
	[OTHER_PROPS.slider.centeredSlides]: false,
	[OTHER_PROPS.slider.sliderFreeMode]: false,
	[OTHER_PROPS.slider.slideEffect]: SliderEffect.SLIDE,
	[OTHER_PROPS.sliderPagination.paginationType]: PaginationType.BULLETS,
};
