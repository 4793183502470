import { IFRAME_ACTIONS, transmitTo, UpdateElementByPathValue } from 'utils/iframe-tunnel';

type Props = {
	id: string;
	isStory: boolean;
	path: string | string[];
	value: UpdateElementByPathValue | UpdateElementByPathValue[];
};

/**
 * `postMessage` to admin panel with a building block(bb) update by path and value
 */
export function transmitToAdminBbUpdate({ id, path, value, isStory }: Props) {
	transmitTo({
		id,
		target: 'admin',
		action: isStory ? IFRAME_ACTIONS.UPDATE_STORY_ELEMENT_BY_PATH : IFRAME_ACTIONS.UPDATE_CARD_ELEMENT_BY_PATH,
		payload: { path, value },
	});
}
