import { EditableStateInfo } from 'types/story';
import { SetEditorModePayload } from 'client/actions/set-editor-mode';
import { SELECTION_TYPES } from 'client/components/common/SelectionHint/utils';
import { SetEditorSelectedNodePayload } from 'client/actions/set-editor-selected-node';
import { SET_EDITOR_SELECTED_NODE, SET_EDITABLE_STATE, SET_EDITOR_MODE } from 'client/constants/actions';
import { initialState, EditorReducer } from './initial-state';

type ActionTypes =
	| { type: typeof SET_EDITOR_SELECTED_NODE; payload: SetEditorSelectedNodePayload }
	| { type: typeof SET_EDITABLE_STATE; payload: EditableStateInfo }
	| { type: typeof SET_EDITOR_MODE; payload: SetEditorModePayload };

export default function editorReducer(state = initialState, action: ActionTypes): EditorReducer {
	switch (action.type) {
		case SET_EDITOR_MODE:
			return { ...state, editorMode: action.payload.mode };
		case SET_EDITABLE_STATE:
			return {
				...state,
				state: {
					...state.state,
					...action.payload,
				},
			};
		case SET_EDITOR_SELECTED_NODE: {
			const { type, data, isMultiSelect } = action.payload;

			if (!type) {
				return { ...state, selection: initialState.selection };
			}

			const selection = { ...state.selection };
			const isDataArray = Array.isArray(data);
			const id = isDataArray ? undefined : data?.id;

			if (type === SELECTION_TYPES.hovered) {
				const nextData = id && !isDataArray && data !== undefined ? { [id]: data } : null;
				selection[type] = { ...initialState.selection[type], ...nextData };
			}

			if (type === SELECTION_TYPES.clicked && isMultiSelect) {
				if (isDataArray) {
					// update multiple selected nodes in a single bunch
					data.forEach(selectedNode => {
						selection[type][selectedNode.id] = selectedNode;
					});
				} else if (id !== undefined && selection[type][id]) {
					delete selection[type][id];
				} else if (id !== undefined && data !== undefined) {
					selection[type][id] = data;
				}
			} else if (type === SELECTION_TYPES.clicked && id !== undefined && data !== undefined && !isDataArray) {
				selection[type] = { [id]: data };
			}

			return { ...state, selection };
		}
		default:
			return state;
	}
}
