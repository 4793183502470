/* eslint-disable react/no-unused-state */
import React from 'react';

import cn from 'classnames';
import ContentEditable from 'react-contenteditable';

import { EditorMode } from 'types/story';
import { isLayerType } from 'common/utils/blocks/is-layer-type';
import { pastePlainText, triggerFocus } from 'common/utils/helpers';
import { UNIFORM_PROPS } from 'common/constants/component-props';
import { transmitToAdminBbUpdate } from 'client/utils/transmit-to-admin-bb-update';
import css from './Error.scss';

type OwnState = any;

type State = OwnState & typeof ErrorEditable.defaultProps;

type Props = {
	text: string;
	errorType: string;
	editorMode: EditorMode;
	path?: string;
};
class ErrorEditable extends React.Component<Props, State> {
	static defaultProps = {
		path: '',
	};

	editableRef = React.createRef<HTMLElement>();

	constructor(props, context) {
		super(props, context);

		this.state = {
			htmlPrev: props.text,
			html: props.text,
			editing: false,
		};
	}

	static getDerivedStateFromProps({ text }, state) {
		const isUpdatedChildrenFromProp = text !== state.htmlPrev;
		if (isUpdatedChildrenFromProp) {
			return { html: text, htmlPrev: text };
		}
		return null;
	}

	onEditableBlur = event => {
		const { path: p, errorType } = this.props;

		transmitToAdminBbUpdate({
			id: ErrorEditable.name,
			path: `elements.${p}.uiConfig.componentProps.${UNIFORM_PROPS.fieldError}.${errorType}`,
			value: this.state.html,
			isStory: isLayerType(this.props).global,
		});

		this.setState({ editing: false });
	};

	onEditableChange = event => {
		this.setState({ html: event.target.value });
		// trigger SelectionHint update to set current editable node sizes
		triggerFocus(this.editableRef.current);
	};

	onEditableDoubleClick = () => {
		this.setState({ editing: true }, () => {
			this.editableRef.current?.focus();
		});
	};

	render() {
		return (
			<ContentEditable
				// data-key={+Date.now()}
				innerRef={this.editableRef}
				className={cn(css.editable /* , Date.now() */)}
				html={this.state.html}
				tagName="span"
				disabled={!this.state.editing}
				onClick={this.props.editorMode === EditorMode.CONTENT ? this.onEditableDoubleClick : undefined}
				onDoubleClick={this.onEditableDoubleClick}
				onChange={this.onEditableChange}
				onBlur={this.onEditableBlur}
				onPaste={pastePlainText}
			/>
		);
	}
}

export { ErrorEditable };
