import { COMPONENT_TYPE as TYPE, LAYER_TYPE, COMPONENT_DISPLAY_NAME } from 'common/constants';
import { UNIFORM_PROPS } from 'common/constants/component-props';
import { generateElementId, generateNodeId } from 'utils/generate-id';
import View from 'client/components/common/BuildingBlocks/Swipe/Swipe';

export const SWIPE = () => {
	const _id = generateElementId();

	return {
		view: View,
		get model() {
			const type = TYPE.SWIPE;

			return {
				_id,
				type,
				uiConfig: {
					layer: LAYER_TYPE.CONTENT,
					editorProps: {
						name: COMPONENT_DISPLAY_NAME[type],
						selectable: true,
						draggable: true,
						expandable: true,
						expanded: true,
					},

					componentProps: {
						[UNIFORM_PROPS.onSwipe]: {
							left: '',
							right: '',
						},
						styles: {},
						other: {},
					},
					nodeProps: {
						id: generateNodeId(_id, type),
					},
				},
				children: [],
			};
		},
	};
};
