import {
	COMPONENT_TYPE as TYPE,
	COMPONENT_DISPLAY_NAME,
	LAYER_TYPE,
	COMPONENT_STATES,
	DEFAULT_MEDIA_QUERY_PLATFORMS,
} from 'common/constants';
import slide1 from 'common/assets/images/slide-1.jpg';
import slide2 from 'common/assets/images/slide-2.jpg';
import slide3 from 'common/assets/images/slide-3.jpg';
import { generateElementId, generateNodeId } from 'utils/generate-id';
import { IMG } from 'client/components/common/BuildingBlocks/Img';
import View from './SliderSlides';

const getImgStyles = (src: string) => {
	return {
		[COMPONENT_STATES.DEFAULT]: {
			[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]: {
				backgroundImage: `url(${src})`,
			},
		},
	};
};

export const SLIDER_SLIDES = () => {
	const _id = generateElementId();

	return {
		view: View,
		get model() {
			const type = TYPE.SLIDER_SLIDES;

			return {
				_id,
				type,
				uiConfig: {
					layer: LAYER_TYPE.CONTENT,
					editorProps: {
						name: COMPONENT_DISPLAY_NAME[type],
						selectable: true,
						draggable: false,
						expandable: true,
						expanded: true,
					},
					nodeProps: {
						id: generateNodeId(_id, type),
					},
					componentProps: {
						styles: {},
						other: {},
					},
				},
				children: [
					IMG({ styles: getImgStyles(slide1) }).model,
					IMG({ styles: getImgStyles(slide2) }).model,
					IMG({ styles: getImgStyles(slide3) }).model,
				],
			};
		},
	};
};
