import React, { useEffect, useState, useRef } from 'react';
import { gsap } from 'gsap';
import { useWillUnmount } from 'common/components/useWillUnmount';

const defaultProps = {
	onUpdate: (value: number) => {},
	duration: 1500,
	animate: false,
};

type Props = {
	onUpdate?: () => void;
	animate?: boolean;
	// value in milliseconds
	duration?: number;
	start: number;
	end: number;
} & typeof defaultProps;

const AnimatedCounter = ({ duration, end, start, animate, onUpdate }: Props) => {
	const [count, setCount] = useState(start);
	const tl = useRef<ReturnType<typeof gsap.to>>();

	function animateCount() {
		if (!animate) {
			return;
		}

		const c = { count: start };

		tl.current = gsap.to(c, {
			count: end,
			roundProps: 'count',
			duration: duration / 1000,
			onStart: () => setCount(c.count),
			onUpdate: () => {
				setCount(c.count);
				onUpdate(c.count);
			},
		});
	}

	useEffect(animateCount, [onUpdate, animate, duration, start, end]);

	useWillUnmount(() => {
		if (tl.current) tl.current.kill();
	});

	return <span>{count}</span>;
};

AnimatedCounter.defaultProps = defaultProps;

export default AnimatedCounter;
