import React, { useCallback, useMemo } from 'react';
import type { BBCommonProps, StorySettingsOfCard } from 'types/story';
import { isTouch } from 'common/utils/environment';
import { getStateDOMAttr } from 'client/components/common/BuildingBlocks/utils/common';
import type { EventProviderContextT } from 'client/components/pages/Story/EventProvider/Context';
import { COMPONENT_STATES } from 'common/constants';
import { ClientReducerState, useClientDispatch } from 'client/reducers';
import { updateAnswers } from 'client/actions';
import AnswerContext from 'client/components/common/BuildingBlocks/Answer/Context';
import { ChildrenWithParentState } from 'client/components/common/BuildingBlocks/ChildrenWithParentState';
import { LottieEvEm } from 'client/components/common/BuildingBlocks/Lottie/utils';
import { AnimationEvEm } from 'client/components/common/BuildingBlocks/animation';
import AnswerBody from './AnswerBody';

type Props = BBCommonProps &
	EventProviderContextT & {
		storyId: string;
		cardId: string;
		showResults: boolean;
		showCorrect: boolean;
		cardSettings: StorySettingsOfCard | undefined;
		answersData: ClientReducerState['user']['answers'][string];
		totalSelectedAnswers: number;
		answersMax: number;
		isForceSubmitBtn: boolean;
	};

const PollMultipleAnswer: React.FC<Props> = ({
	answersData,
	totalSelectedAnswers,
	cardSettings,
	answersMax,
	storyId,
	cardId,
	...props
}) => {
	const answerId = props._id;
	const answerNodeId = props.uiConfig.nodeProps.id;
	const dispatch = useClientDispatch();
	const isSubmitted = Boolean(answersData?.submitted);
	const isReachedAnswersMax = totalSelectedAnswers >= answersMax;
	const selectedAnswers = answersData?.selected;
	const isSelected = selectedAnswers?.includes(answerId);
	const isSingleAnswerForceSubmit = props.isForceSubmitBtn && answersMax === 1;
	const isDisabled = React.useMemo(() => {
		if (props.isEditableMode) return true;
		if (isSingleAnswerForceSubmit) return false;
		return (isReachedAnswersMax && !isSelected) || isSubmitted;
	}, [props.isEditableMode, isSingleAnswerForceSubmit, isReachedAnswersMax, isSelected, isSubmitted]);

	// Provide vote data to child components to display vote results (see ResultText, ResultShape)
	const answerVoteResult = useMemo(() => {
		const isVotesReceived = answersData?.votes !== undefined;
		const isShow = !props.isEditableMode && isSubmitted && isVotesReceived;
		const noVotes = !Object.keys(answersData?.votes ?? {}).length;
		const value = answersData?.votes?.[answerId] ?? (noVotes && isSelected ? 100 : 0);
		return { isShow, value, duration: 350 };
	}, [props.isEditableMode, answersData?.votes, answerId, isSubmitted, isSelected]);

	const { setStates } = props;
	const embedCode = props.uiConfig.componentProps?.other?.embedCode;
	const textContent = props.containerRef?.current?.innerText || 'MediaContent*';
	const eventListenersOnClick = props.eventListeners?.onClick;

	const onClickCapture = useCallback(
		async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
			// The problem is when you do event.stopPropagation in capture phase it cancels handler in
			// bubbling phase on the element, what breaks animation with onclick trigger,
			// so as fix — normal onclick listener is called manually:
			eventListenersOnClick?.(event);

			setStates({
				[COMPONENT_STATES.HOVER]: isTouch ? false : event.isTrusted && isSelected,
				[COMPONENT_STATES.SELECTED]: !isSelected,
			});

			if (!isSelected) {
				LottieEvEm.emit('playback', { action: 'onMultipleAnswerSelect', answerNodeId });
				AnimationEvEm.emit('playback', { action: 'onMultipleAnswerSelect', answerNodeId });
			}

			// deselect selected answers and select current one
			if (isSingleAnswerForceSubmit && !isSelected && selectedAnswers?.length) {
				const siblings = findSiblingsWithSelectedState(event.currentTarget);
				siblings.forEach(sibling => sibling.click());
			}

			// Store answer in redux (select|deselect)
			dispatch(updateAnswers({ cardId, answerId, data: { embedCode, textContent } }));
		},
		[
			eventListenersOnClick,
			isSelected,
			cardId,
			answerId,
			answerNodeId,
			textContent,
			embedCode,
			setStates,
			dispatch,
			isSingleAnswerForceSubmit,
			selectedAnswers,
		]
	);

	const providedContext = useMemo(
		() => ({
			showResults: props.showResults,
			answerVote: answerVoteResult,
		}),
		[props.showResults, answerVoteResult]
	);

	// Manage states of answers selected programmatically (e.g., after vote and page refresh with `oncePerUser: true`)
	React.useEffect(() => {
		if (isSelected && !props.states.selectedState) {
			setStates({
				[COMPONENT_STATES.HOVER]: false,
				[COMPONENT_STATES.SELECTED]: true,
			});
		}
	}, [setStates, isSelected, props.states.selectedState, answerVoteResult.isShow]);

	return (
		<AnswerBody {...props} isDisabled={isDisabled} onClickCapture={isDisabled ? undefined : onClickCapture}>
			<AnswerContext.Provider value={providedContext}>
				{props.isEditableMode ? (
					props.children
				) : (
					<ChildrenWithParentState states={props.states /* change child states to answer state */}>
						{props.children}
					</ChildrenWithParentState>
				)}
			</AnswerContext.Provider>
		</AnswerBody>
	);
};

function findSiblingsWithSelectedState(element: HTMLElement | null): HTMLElement[] {
	if (!element || !element.parentNode) {
		return [];
	}

	const siblings = Array.from(element.parentNode.children) as HTMLElement[];
	return siblings.filter(sbl => sbl !== element && sbl.hasAttribute(getStateDOMAttr(COMPONENT_STATES.SELECTED)));
}

export default PollMultipleAnswer;
