import type { UserFeatureFlags } from 'types/user';
import type { StoryModel, SpreadsheetRange } from 'types/story';
import type { SpreadsheetValuesMap } from 'client/actions/get-story-spreadsheet-values-map';

interface IClientStoryReducer {
	story: null | StoryModel;
	// list of available spreadsheet variables to add. only in card editor.
	spreadsheetVariables: SpreadsheetRange[];
	// map of integrated Google spreadsheet ranges with a proper values. only in preview/published
	spreadsheetValuesMap: Partial<SpreadsheetValuesMap>;
	// list of features enabled for a current user. only in card editor
	features: UserFeatureFlags;
}

const initialState: IClientStoryReducer = {
	story: null,
	spreadsheetVariables: [],
	spreadsheetValuesMap: {},
	features: {},
};

export default initialState;
