import { CARD_TYPE } from 'common/constants';
import { InfoFacade } from 'utils/facades/info-card-facade';
import { TriviaFacade } from 'utils/facades/trivia-card-facade';
import { SortableTriviaFacade } from 'utils/facades/sortable-trivia-card-facade';
import { PollFacade } from 'utils/facades/poll-card-facade';
import { SortablePollFacade } from 'utils/facades/sortable-poll-card-facade';
import { PersonalityTestFacade } from 'utils/facades/personality-test-card-facade';
import { ThisOrThatFacade } from 'utils/facades/this-or-that-card-facade';
import { TrueOrFalseFacade } from 'utils/facades/true-or-false-card-facade';
import { NavigationFacade } from 'utils/facades/navigation-card-facade';
import { FormFacade } from 'utils/facades/form-card-facade';
import { RegistrationFacade } from 'utils/facades/registration-card-facade';
import { SandboxFacade } from 'utils/facades/sandbox-card-facade';

const facades = {
	[CARD_TYPE.INFO]: InfoFacade,
	[CARD_TYPE.TRIVIA]: TriviaFacade,
	[CARD_TYPE.SORTABLE_TRIVIA]: SortableTriviaFacade,
	[CARD_TYPE.POLL]: PollFacade,
	[CARD_TYPE.SORTABLE_POLL]: SortablePollFacade,
	[CARD_TYPE.PERSONALITY_TEST]: PersonalityTestFacade,
	[CARD_TYPE.TRUE_OR_FALSE]: TrueOrFalseFacade,
	[CARD_TYPE.THIS_OR_THAT]: ThisOrThatFacade,
	[CARD_TYPE.NAVIGATION]: NavigationFacade,
	[CARD_TYPE.FORM]: FormFacade,
	[CARD_TYPE.REGISTRATION]: RegistrationFacade,
	[CARD_TYPE.SANDBOX]: SandboxFacade,
} as const;

export type FacadeKeys = keyof typeof facades;
export type FacadeValues =
	| InfoFacade
	| TriviaFacade
	| PollFacade
	| PersonalityTestFacade
	| ThisOrThatFacade
	| TrueOrFalseFacade
	| NavigationFacade
	| FormFacade
	| SandboxFacade;
export default facades;
