import { CARD_DEFAULT_TEMPLATE_NAME } from 'common/constants';
import { defaultTemplate } from 'utils/facades/templates/default-template';
import { CardFacadeBase } from 'utils/facades/card-facade-base';

class TrueOrFalseFacade extends CardFacadeBase {
	static template = {
		[CARD_DEFAULT_TEMPLATE_NAME]: defaultTemplate,
	};
}

export { TrueOrFalseFacade };
