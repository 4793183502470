/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import noop from 'lodash/noop';

import { getSourceType } from 'utils/assets';
import { trackVisibilityChange, getVimeoPlayerSrc, getYoutubePlayerSrc } from './helpers';
import NativePlayer from './Native';
import VideoJsPlayer from './VideoJs';
import type { Props as VideoJsPlayerProps, CustomOptions } from './VideoJs/Player';
import css from './VideoPlayer.scss';

export type Props = {
	id?: string;
	storyId?: string;
	transcodingId?: string | null;
	title?: string;
	poster?: string;
	src: string;
	autoplay: boolean;
	playsinline?: boolean;
	muted: boolean;
	loop: boolean;
	controls: boolean;
	isEditableMode?: boolean;
	className?: string;
} & Partial<CustomOptions>;

export const VideoPlayer = (props: Props) => {
	const {
		src,
		muted,
		loop,
		controls,
		poster,
		isEditableMode,
		autoplay,
		autopause,
		playsinline = true,
		soundControl,
		thumbnailsControl,
		castControl,
		fullscreenControl,
		playbackSpeedControl,
		qualityControl,
		pipModeControl,
		skipControl,
	} = props;
	const [isVisible, setIsVisible] = React.useState<boolean | undefined>(undefined);
	const [isReady, setIsReady] = React.useState(false);
	const iframeLoadAbortControllerRef = React.useRef<AbortController | null>(null);
	const containerRef = React.useRef<HTMLDivElement>(null);
	const videoRef = React.useRef<HTMLVideoElement>(null);
	const youtubeRef = React.useRef<HTMLIFrameElement>(null);
	const vimeoRef = React.useRef<HTMLIFrameElement>(null);
	const type = getSourceType(props.src);
	const reloadAttempts = React.useRef({ value: 0, max: 3 });

	const videoJsOptions = React.useMemo<VideoJsPlayerProps['options']>(() => {
		return {
			// non-videojs options:
			autopause,
			soundControl,
			thumbnailsControl,
			castControl,
			fullscreenControl,
			playbackSpeedControl,
			qualityControl,
			pipModeControl,

			// videojs options:
			sources: [
				{
					src,
					type: 'application/x-mpegURL',
				},
			],
			autoplay,
			playsinline,
			muted,
			loop,
			controls,
			controlBar: {
				remainingTimeDisplay: {
					displayNegative: false,
				},
				fullscreenToggle: Boolean(fullscreenControl),
				volumePanel: Boolean(soundControl),
				pictureInPictureToggle: Boolean(pipModeControl),
				skipButtons: Boolean(skipControl) && {
					forward: 10,
					backward: 10,
				},
			},
			width: 450,
			responsive: true,
			inactivityTimeout: 1500,
			poster: Boolean(poster) && poster !== 'none' ? poster : undefined,
			playbackRates: playbackSpeedControl ? [0.5, 1, 1.5, 2] : undefined,
			preload: isEditableMode ? 'metadata' : 'auto',
			// audioOnlyMode: true,
			// audioPosterMode: true,
			// fluid: true,
		};
	}, [
		src,
		isEditableMode,
		autoplay,
		autopause,
		playsinline,
		muted,
		loop,
		controls,
		poster,
		soundControl,
		thumbnailsControl,
		castControl,
		fullscreenControl,
		playbackSpeedControl,
		qualityControl,
		pipModeControl,
		skipControl,
	]);

	const onIframeLoad = () => {
		if (isEditableMode || !autoplay || !isVisible) {
			return;
		}

		iframeLoadAbortControllerRef.current?.abort();
		iframeLoadAbortControllerRef.current = new AbortController();

		switch (type) {
			case 'youtube': {
				youtubeRef.current?.contentWindow?.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
				break;
			}

			case 'vimeo': {
				vimeoRef.current?.contentWindow?.postMessage('{"method":"play"}', '*');
				window.addEventListener(
					'message',
					event => {
						if (event.origin !== 'https://player.vimeo.com') return;

						const data = JSON.parse(event.data);
						if (data.event === 'ready') {
							vimeoRef.current?.contentWindow?.postMessage('{"method":"play"}', '*');
						}
					},
					{ signal: iframeLoadAbortControllerRef.current.signal }
				);
				break;
			}

			default: {
				break;
			}
		}
	};

	React.useEffect(function cleanupIframeLoad() {
		return () => {
			iframeLoadAbortControllerRef.current?.abort();
		};
	}, []);

	React.useEffect(
		function trackVisibility() {
			const intervalDuration = isEditableMode ? 2500 : 200;
			const visibilityHandler = (cb?: (visible: boolean) => void) => (visible: boolean) => {
				if (isVisible === visible) return;

				cb?.(visible);
				setIsVisible(visible);
				if (!visible) setIsReady(false);
			};
			let cleanupTrackVisibilityChange = noop;

			switch (type) {
				case 'video':
				case 'hls':
					cleanupTrackVisibilityChange = trackVisibilityChange(
						containerRef.current,
						visibilityHandler(),
						intervalDuration
					);
					break;

				case 'vimeo':
					cleanupTrackVisibilityChange = trackVisibilityChange(
						containerRef.current,
						visibilityHandler(visible => {
							if (visible && autoplay) {
								vimeoRef.current?.contentWindow?.postMessage('{"method":"play"}', '*');
							} else {
								vimeoRef.current?.contentWindow?.postMessage('{"method":"pause"}', '*');
								vimeoRef.current?.contentWindow?.postMessage(
									'{"method":"setCurrentTime","value":"0"}',
									'*'
								);
							}
						}),
						intervalDuration
					);
					break;

				case 'youtube':
					cleanupTrackVisibilityChange = trackVisibilityChange(
						containerRef.current,
						visibilityHandler(visible => {
							if (visible && autoplay) {
								youtubeRef.current?.contentWindow?.postMessage(
									'{"event":"command","func":"playVideo","args":""}',
									'*'
								);
							} else {
								youtubeRef.current?.contentWindow?.postMessage(
									'{"event":"command","func":"stopVideo","args":""}',
									'*'
								);
							}
						}),
						intervalDuration
					);
					break;

				default:
					break;
			}

			return () => {
				cleanupTrackVisibilityChange();
			};
		},
		[isEditableMode, isVisible, autoplay, type]
	);

	React.useEffect(() => {
		if (type === 'video' && isVisible) {
			if (videoRef.current?.readyState === 0) {
				console.info('VideoPlayer:load');
				videoRef.current.load();
			} else if (isReady && autoplay) {
				console.info('VideoPlayer:play');
				videoRef.current?.play().catch(error => console.error('VideoPlayer:play', error));
			}
		}
	}, [type, isVisible, isReady, autoplay]);

	return (
		<div className={css.videoPlayer} data-visible={isVisible} ref={containerRef as React.RefObject<HTMLDivElement>}>
			{isVisible && (
				<>
					{type === 'youtube' && (
						<iframe
							title={props.title}
							src={getYoutubePlayerSrc(props)}
							width="100%"
							height="100%"
							// eslint-disable-next-line max-len
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
							onLoad={onIframeLoad}
							ref={youtubeRef}
						/>
					)}

					{type === 'vimeo' && (
						<iframe
							title={props.title}
							src={getVimeoPlayerSrc(props)}
							width="100%"
							height="100%"
							allowFullScreen
							onLoad={onIframeLoad}
							ref={vimeoRef}
						/>
					)}

					{type === 'video' || type === 'hls' ? (
						// note: ensure that source type is compatible with a NativePlayer
						type === 'video' ? (
							<NativePlayer
								videoRef={videoRef}
								setIsReady={setIsReady}
								isVisible={isVisible}
								reloadAttempts={reloadAttempts}
								title={props.title}
								src={props.src}
								poster={props.poster}
								controls={props.controls}
								muted={props.muted}
								loop={props.loop}
								autoplay={autoplay}
								playsinline={playsinline}
								className={props.className}
							/>
						) : (
							<VideoJsPlayer
								id={props.id}
								storyId={props.storyId}
								transcodingId={props.transcodingId}
								title={props.title}
								options={videoJsOptions}
								className={props.className}
							/>
						)
					) : null}
				</>
			)}
		</div>
	);
};

export default VideoPlayer;
