type EventName = string;
type EventPayload = unknown;
type Type = { type: EventName; data?: EventPayload };
type EventListener<E extends Type['type'], D extends Type['data']> = (event: { type: E; data: D }) => void;
type Listeners<E extends Type['type'], D extends Type['data']> = Record<E, EventListener<E, D>[]>;

export class EventEmitter<T extends Type> {
	private listeners = {} as Listeners<T['type'], Extract<T, { type: T['type'] }>['data']>;

	addListener = <E extends T['type']>(event: E, listener: EventListener<E, Extract<T, { type: E }>['data']>) => {
		if (!this.listeners[event]) {
			this.listeners[event] = [];
		}

		const castedListener = listener as EventListener<T['type'], Extract<T, { type: T['type'] }>['data']>;
		this.listeners[event].push(castedListener);
	};

	removeListener = <E extends T['type']>(event: E, listener: EventListener<E, Extract<T, { type: E }>['data']>) => {
		if (!this.listeners[event]) {
			return;
		}

		const castedListener = listener as EventListener<T['type'], Extract<T, { type: T['type'] }>['data']>;
		const index = this.listeners[event].indexOf(castedListener);

		if (index >= 0) {
			this.listeners[event].splice(index, 1);
		}
	};

	emit = <E extends T['type']>(event: E, data: Extract<T, { type: E }>['data'] = undefined) => {
		(this.listeners[event] || []).forEach(listener => {
			listener({ type: event, data });
		});
	};

	destroy = () => {
		this.listeners = {} as Listeners<T['type'], Extract<T, { type: T['type'] }>['data']>;
	};
}
