import {
	COMPONENT_TYPE as TYPE,
	COMPONENT_DISPLAY_NAME,
	LAYER_TYPE,
	COMPONENT_STATES,
	DEFAULT_MEDIA_QUERY_PLATFORMS,
	Pin,
} from 'common/constants';
import { generateElementId, generateNodeId } from 'utils/generate-id';
import { OTHER_PROPS } from 'common/constants/component-props';
import View from './SliderPagination';

export const SLIDER_PAGINATION = () => {
	const _id = generateElementId();

	return {
		view: View,
		get model() {
			const type = TYPE.SLIDER_PAGINATION;

			return {
				_id,
				type,
				uiConfig: {
					layer: LAYER_TYPE.CONTENT,
					editorProps: {
						name: COMPONENT_DISPLAY_NAME[type],
						selectable: true,
						draggable: false,
						expandable: false,
						expanded: false,
					},
					nodeProps: {
						id: generateNodeId(_id, type),
					},
					componentProps: {
						styles: {},
						other: {
							[COMPONENT_STATES.DEFAULT]: {
								[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]: {
									[OTHER_PROPS.pin.pinType]: Pin.b,
								},
							},
						},
					},
				},
				children: [],
			};
		},
	};
};
