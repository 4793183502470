import { DEFAULT_EDITABLE_STATE_INFO } from 'common/constants';
import { SELECTION_TYPES } from 'client/components/common/SelectionHint/utils';
import { SelectionSingleType } from 'client/components/common/SelectionHint/types';
import { EditorMode } from 'types/story';

export type EditorReducer = {
	editorMode: EditorMode;
	state: typeof DEFAULT_EDITABLE_STATE_INFO;
	selection: {
		[SELECTION_TYPES.clicked]: {
			[key: string]: SelectionSingleType;
		};
		[SELECTION_TYPES.hovered]: {
			[key: string]: SelectionSingleType;
		};
	};
};

export const initialState: EditorReducer = {
	editorMode: EditorMode.DESIGN,
	state: DEFAULT_EDITABLE_STATE_INFO,
	selection: {
		[SELECTION_TYPES.clicked]: {},
		[SELECTION_TYPES.hovered]: {},
		/*
		 [selectionType]: {
		    elementID: {...data},
		    elementID: {...data},
		 },
		*/
	},
};
