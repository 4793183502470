import React from 'react';
import type { Props } from './Player';

const Player = React.lazy(() => import('./Player'));

export const VideoJsPlayer = (props: Props) => {
	return (
		<React.Suspense fallback={<div>Loading...</div>}>
			<Player {...props} />
		</React.Suspense>
	);
};

export default VideoJsPlayer;
