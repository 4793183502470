// The FIELD_DATA_ATTR constant defines a custom data attribute used to mark form fields that are relevant
// for form submission. This attribute enables selective targeting of these fields within the document,
// ensuring that only specified elements are considered when gathering form data.
export const FIELD_DATA_ATTR = 'data-bb-field';

export const FIELD_EDITABLE_ATTR = 'data-editable';

export const FIELD_ERROR_DATA_ATTR = 'data-bb-error';

export enum FieldBBType {
	text = 'text',
	email = 'email',
	tel = 'tel',
	date = 'date',
	select = 'select',
	hidden = 'hidden',
}

export enum FieldBBErrorType {
	required = 'required',
	email = 'email',
	tel = 'tel',
	fullName = 'fullName',
}

export enum SelectBBOptionsOrder {
	'alphabet' = 'alphabet',
	'random' = 'random',
	'custom' = 'custom',
}
