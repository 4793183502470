import { round, sortBy, sum } from 'lodash';
import type { CardData, SpreadsheetRange, StoryModel, StorySettingsOfCard } from 'types/story';
import type { SpreadsheetValuesMap } from 'client/actions/get-story-spreadsheet-values-map';
import TraverseTreeDataCollector from 'client/components/common/BuildingBlocks/utils/traverse-tree-data-collector';
import { CardFacade, StoryFacade } from 'utils/facades';
import { CARD_TYPE, COMPONENT_TYPE } from 'common/constants';
import { isFieldBlock } from 'utils/blocks/misc';
import { componentWalkFull } from 'utils/blocks/component-walk-full';
import { CountType, NavigationFacade } from 'utils/facades/navigation-card-facade';
import { VariableCategory, VariableDataType, Variables, VariableType } from './types';

const genericVariablePattern = {
	[VariableType.fieldValue]: /^field\/(?<cardId>[^/]+)\/(?<componentId>[^/]+)$/, // match "field/:cardId/:componentId"
	[VariableType.characterScore]: /^character\/s\/(?<characterId>[^/]+)$/, // match "character/s/:characterId"
	[VariableType.characterScorePercent]: /^character\/sp\/(?<characterId>[^/]+)$/, // match "character/sp/:characterId"
	[VariableType.urlParam]: /^urlParam\/(?<param>[^/]+)$/, // match "urlParam/:param"
	[VariableType.spreadsheetRange]: /^sheet\/(?<rangeId>[^/]+)$/, // match "sheet/:rangeId"
};

const extractGenericVariableParts = {
	[VariableType.fieldValue]: (value: string) => {
		const match = value.match(genericVariablePattern[VariableType.fieldValue]);
		return match && match.groups ? { cardId: match.groups.cardId, componentId: match.groups?.componentId } : null;
	},
	[VariableType.characterScore]: (value: string) => {
		const match = value.match(genericVariablePattern[VariableType.characterScore]);
		return match && match.groups ? { characterId: match.groups.characterId } : null;
	},
	[VariableType.characterScorePercent]: (value: string) => {
		const match = value.match(genericVariablePattern[VariableType.characterScorePercent]);
		return match && match.groups ? { characterId: match.groups.characterId } : null;
	},
	[VariableType.urlParam]: (value: string) => {
		const match = value.match(genericVariablePattern[VariableType.urlParam]);
		return match && match.groups ? { param: match.groups.param } : null;
	},
	[VariableType.spreadsheetRange]: (value: string) => {
		const match = value.match(genericVariablePattern[VariableType.spreadsheetRange]);
		return match && match.groups ? { param: match.groups.rangeId } : null;
	},
};

export const getGenericVariableName = {
	[VariableType.fieldValue]: (params: { cardId: string; componentId: string }) =>
		VariableType.fieldValue.replace(':cardId', params.cardId).replace(':componentId', params.componentId),
	[VariableType.characterScore]: (params: { characterId: string }) =>
		VariableType.characterScore.replace(':characterId', params.characterId),
	[VariableType.characterScorePercent]: (params: { characterId: string }) =>
		VariableType.characterScorePercent.replace(':characterId', params.characterId),
	[VariableType.urlParam]: (params: { param: string }) => VariableType.urlParam.replace(':param', params.param),
	[VariableType.spreadsheetRange]: (params: { rangeId: string }) =>
		VariableType.spreadsheetRange.replace(':rangeId', params.rangeId),
};

export const commonVariables: Record<
	| 'cardName'
	| 'date'
	| 'time'
	| 'datePublished'
	| 'score'
	| 'scorePercent'
	| 'cardsVisited'
	| 'cardsScored'
	| 'cardsAnswered'
	| 'totalAnswerCards'
	| 'totalScoreCards'
	| 'correct'
	| 'incorrect'
	| 'answersMax'
	| 'answersMin'
	| 'answersScore'
	| 'answerIndex'
	| 'answersSelected'
	| 'storyAnswersSelected',
	VariableDataType
> = {
	cardName: {
		name: VariableType.cardName,
		title: 'Card name',
		placeholder: 'card name',
		description: 'The current card name',
		type: VariableType.cardName,
		category: VariableCategory.general,
	},
	date: {
		name: VariableType.date,
		title: 'Date',
		placeholder: 'date',
		description: "The current date, displayed according to the user's location",
		type: VariableType.date,
		category: VariableCategory.general,
	},
	time: {
		name: VariableType.time,
		title: 'Time',
		placeholder: 'time',
		description: "Current time [hh]:[mm]:[ss], displayed according to the user's location",
		type: VariableType.time,
		category: VariableCategory.general,
	},
	datePublished: {
		name: VariableType.datePublished,
		title: 'Published date and time',
		placeholder: 'published time',
		description: 'The date and time your Story was published, displayed according to the user’s location',
		type: VariableType.datePublished,
		category: VariableCategory.general,
	},
	score: {
		name: VariableType.score,
		title: 'Total score (#)',
		placeholder: 'score #',
		description: 'Total score for answered cards',
		type: VariableType.score,
		category: VariableCategory.scoring,
	},
	scorePercent: {
		name: VariableType.scorePercent,
		title: 'Total score (%)',
		placeholder: 'score %',
		description: 'Score as a percentage, calculated by dividing the received score by the maximum potential score',
		type: VariableType.scorePercent,
		category: VariableCategory.scoring,
	},
	cardsVisited: {
		name: VariableType.cardsVisited,
		title: 'Card count - visited',
		placeholder: 'count - visited',
		description: 'The number of cards visited by the user',
		type: VariableType.cardsVisited,
		category: VariableCategory.numbering,
	},
	cardsScored: {
		name: VariableType.cardsScored,
		title: 'Card count - scored',
		placeholder: 'count - scored',
		description: 'The number of cards with scoring logic (Trivia, True or False, etc.) visited by the user',
		type: VariableType.cardsScored,
		category: VariableCategory.numbering,
	},
	cardsAnswered: {
		name: VariableType.cardsAnswered,
		title: 'Card count - answered',
		placeholder: 'count - answered',
		description: 'Total completed cards that can have an answer (Trivia, Poll, etc.)',
		type: VariableType.cardsAnswered,
		category: VariableCategory.numbering,
	},
	totalAnswerCards: {
		name: VariableType.totalAnswerCards,
		title: 'Total answerable cards',
		placeholder: 'answerable cards',
		description: 'Total number of cards that can have an answer component (Trivia, Poll, etc.) in the Story',
		type: VariableType.totalAnswerCards,
		category: VariableCategory.numbering,
	},
	totalScoreCards: {
		name: VariableType.totalScoreCards,
		title: 'Total scorable cards',
		placeholder: 'scorable cards',
		description: 'Total number of cards that include scoring logic (Trivia, True or False, etc.) in the Story',
		type: VariableType.totalScoreCards,
		category: VariableCategory.numbering,
	},
	correct: {
		name: VariableType.correct,
		title: 'Answer count - correct',
		placeholder: 'correct answers',
		description: 'Total correct answers sent by the user',
		type: VariableType.correct,
		category: VariableCategory.scoring,
	},
	incorrect: {
		name: VariableType.incorrect,
		title: 'Answer count - incorrect',
		placeholder: 'incorrect answers',
		description: 'Total incorrect answers sent by the user',
		type: VariableType.incorrect,
		category: VariableCategory.scoring,
	},
	answersMax: {
		name: VariableType.answersMax,
		title: 'Max answers',
		placeholder: 'max answers',
		description: 'Maximum number of answers allowed to be selected on this card',
		type: VariableType.answersMax,
		category: VariableCategory.multipleAnswers,
	},
	answersMin: {
		name: VariableType.answersMin,
		title: 'Min answers',
		placeholder: 'min answers',
		description: 'Minimum number of answers required to be selected on this card',
		type: VariableType.answersMin,
		category: VariableCategory.multipleAnswers,
	},
	answersScore: {
		name: VariableType.answersScore,
		title: 'Total score (#) for this card',
		placeholder: 'card score',
		description: 'Total score for correct answers on this card',
		type: VariableType.answersScore,
		category: VariableCategory.scoring,
	},
	answerIndex: {
		name: VariableType.answerIndex,
		title: '[new] Current answer index',
		placeholder: 'answer index',
		description: 'Points to the current answer in the trivia sequence.',
		type: VariableType.answerIndex,
		category: VariableCategory.multipleAnswers,
	},
	answersSelected: {
		name: VariableType.answersSelected,
		title: 'Answer count - selected (card)',
		placeholder: 'selected card answers',
		description: 'Total answers selected by the user on this card',
		type: VariableType.answersSelected,
		category: VariableCategory.multipleAnswers,
	},
	storyAnswersSelected: {
		name: VariableType.storyAnswersSelected,
		title: 'Answer count - selected (Story)',
		placeholder: 'selected story answers',
		description: 'Total answers selected by the user in the Story',
		type: VariableType.storyAnswersSelected,
		category: VariableCategory.multipleAnswers,
	},
};

// ordered list of variables in order to render in <MentionSuggestions> component
const orderedVariables: VariableType[] = [
	VariableType.cardName,
	VariableType.date,
	VariableType.time,
	VariableType.datePublished,
	VariableType.cardsVisited,
	VariableType.cardsScored,
	VariableType.cardsAnswered,
	VariableType.totalAnswerCards,
	VariableType.totalScoreCards,
	VariableType.score,
	VariableType.scorePercent,
	VariableType.answersScore,
	VariableType.correct,
	VariableType.incorrect,
	VariableType.fieldValue,
	VariableType.characterScore,
	VariableType.characterScorePercent,
	VariableType.answersMax,
	VariableType.answersMin,
	VariableType.answerIndex,
	VariableType.answersSelected,
	VariableType.storyAnswersSelected,
	VariableType.urlParam,
	VariableType.spreadsheetRange,
];

type GetAvailableVariablesProps = {
	card: CardData;
	story: StoryModel;
	spreadsheetVariables?: SpreadsheetRange[];
};

export const getAvailableVariables = ({
	story,
	spreadsheetVariables = [],
	card: currentCard,
}: GetAvailableVariablesProps) => {
	const { settings } = new StoryFacade(story);
	const variables = new Set<VariableDataType>([
		commonVariables.cardName,
		commonVariables.date,
		commonVariables.time,
		commonVariables.datePublished,
		commonVariables.cardsVisited,
	]);

	const storyFacade = new StoryFacade(story as StoryModel);
	const currentCardFacade = new CardFacade(currentCard);

	if (currentCardFacade.hasFeature.score || currentCardFacade.hasFeature.answerScore) {
		variables.add(commonVariables.answersScore);
	}

	if (currentCardFacade.hasFeature.multipleAnswer) {
		const hasMinMax = Array.isArray(settings?.cards?.[currentCardFacade.cardId]?.answersMinMax);
		const isSortableTrivia = currentCardFacade.type === CARD_TYPE.SORTABLE_TRIVIA;

		variables.add(commonVariables.answersSelected);

		if (hasMinMax || isSortableTrivia) {
			variables.add(commonVariables.answersMax);
			variables.add(commonVariables.answersMin);
		}

		if (isSortableTrivia) {
			variables.add(commonVariables.answerIndex);
		}
	}

	// Collect hardcoded variables based on cards
	storyFacade.cards.forEach(storyCard => {
		const storyCardFacade = new CardFacade(storyCard);

		if (storyCardFacade.hasFeature.answer) {
			variables.add(commonVariables.cardsAnswered);
			variables.add(commonVariables.storyAnswersSelected);
			variables.add(commonVariables.totalAnswerCards);
		}

		if (storyCardFacade.hasFeature.score) {
			variables.add(commonVariables.cardsScored);
			variables.add(commonVariables.totalScoreCards);
		}

		if (storyCardFacade.hasFeature.score || storyCardFacade.hasFeature.answerScore) {
			variables.add(commonVariables.score);
			variables.add(commonVariables.scorePercent);
		}

		if (storyCardFacade.hasFeature.correct) {
			variables.add(commonVariables.correct);
			variables.add(commonVariables.incorrect);
		}

		switch (storyCard.type) {
			case CARD_TYPE.FORM:
				// collect field variables
				componentWalkFull({
					elements: storyCardFacade.elements,
					symbols: storyFacade.symbols,
					callback: function walk({ component: c }) {
						if (isFieldBlock(c) && c.type !== COMPONENT_TYPE.CHECKBOX) {
							const fieldName = c.uiConfig.editorProps.name;
							variables.add({
								name: getGenericVariableName[VariableType.fieldValue]({
									cardId: storyCard._id,
									componentId: c._id,
								}),
								title: `Field - ${fieldName} (${storyCard.name})`,
								description: 'The text the user filled in this input',
								placeholder: fieldName,
								type: VariableType.fieldValue,
								category: VariableCategory.leadsForm,
							});
						}
					},
				});
				break;
			case CARD_TYPE.NAVIGATION: {
				// collect character points vars
				const navFacade = new NavigationFacade(storyCard);
				if (settings && navFacade.getCountType(settings) === CountType.character) {
					Object.entries(navFacade.characters).forEach(([characterId, value]) => {
						const characterName = value.name;

						variables.add({
							name: getGenericVariableName[VariableType.characterScore]({ characterId }),
							title: `${characterName} (${storyCard.name}) - score (#)`,
							description:
								`Total score received by the character ${characterName} based on ` +
								`the user’s answers`,
							placeholder: `${characterName} (num)`,
							type: VariableType.characterScore,
							category: VariableCategory.characters,
						});

						variables.add({
							name: getGenericVariableName[VariableType.characterScorePercent]({ characterId }),
							title: `${characterName} (${storyCard.name}) - score (%)`,
							description:
								`Score received by the character ${characterName} as a percentage, ` +
								`calculated by dividing the character’s score by the total score of all ` +
								`characters based on the user’s answers`,
							placeholder: `${characterName} (%)`,
							type: VariableType.characterScorePercent,
							category: VariableCategory.characters,
						});
					});
				}
				break;
			}
			default:
				break;
		}
	});

	// Collect URL search param variables
	settings.integrations?.urlParams?.params?.forEach(param => {
		variables.add({
			name: getGenericVariableName[VariableType.urlParam]({ param }),
			title: `Parameter: "${param}"`,
			description: `Holds the value extracted from the "${param}" URL search parameter`,
			placeholder: `param/${param}`,
			type: VariableType.urlParam,
			category: VariableCategory.urlParams,
		});
	});

	// Collect custom variables
	const settingsVars: Variables = [
		// NOTE: temporary till there is no UI in admin to add custom variable
		// { name: 'c/custom1', title: 'Custom variable 1', value: 'Static custom value' },
	];

	// add story integrated spreadsheet variables
	spreadsheetVariables?.forEach(item => {
		settingsVars.push({
			name: getGenericVariableName[VariableType.spreadsheetRange]({ rangeId: item.id.toString() }),
			title: item.label,
			description: `Spreadsheet range "${item.range}" in sheet "${item.sheetName}"`,
			placeholder: `sheet/${item.label}`,
			type: VariableType.spreadsheetRange,
			category: VariableCategory.spreadsheet,
		});
	});

	// add custom story vars
	settings.variables?.custom?.forEach(v => {
		settingsVars.push(v);
	});

	// add story settings vars
	settingsVars.forEach(v => {
		variables.add(v);
	});

	return sortBy([...variables.values()], ({ type }) => (type === null ? -1 : orderedVariables.indexOf(type)));
};

const getAnswersMax = (params: { cardFacade: CardFacade; storyCardSettings: StorySettingsOfCard }) => {
	if (params.cardFacade.type === CARD_TYPE.SORTABLE_TRIVIA) {
		const tree = TraverseTreeDataCollector.findComponentByType(COMPONENT_TYPE.SORTABLE_BOX);
		const initialAnswers = tree?.uiConfig.componentProps.sortableStartItems?.split(',').length ?? 1;
		const totalAnswers = TraverseTreeDataCollector.findComponentsByType(COMPONENT_TYPE.ANSWER).length;
		return Math.max(0, totalAnswers - initialAnswers);
	}

	return CardFacade.getAnswersMax(params.cardFacade.type, params.storyCardSettings);
};

// TODO: optimization (do less re-computes as possible. now it gets all values for every inject fn call)
export function injectVariablesValue({
	html,
	story,
	card,
	spreadsheetValuesMap,
	storyCardSettings,
	totalSelectedAnswers,
}: {
	html: string;
	story: StoryModel;
	spreadsheetValuesMap: Partial<SpreadsheetValuesMap>;
	card: CardData;
	storyCardSettings: StorySettingsOfCard;
	totalSelectedAnswers: number;
}) {
	const div = document.createElement('div');
	div.innerHTML = html;
	const nodes = div.querySelectorAll<HTMLElement>('[data-mention]');
	const storyFacade = new StoryFacade(story);
	const cardFacade = new CardFacade(card);
	const getTotalScore = () => storyFacade.storyScore[CountType.score]();
	const getTotalCharacterPoints = () => storyFacade.storyScore[CountType.character]();
	const variables: VariableType[] = [];

	[...nodes].forEach(draftNode => {
		const variableName = draftNode.dataset.mention as VariableType;
		variables.push(variableName);

		// Variable value
		let value: string | number | null = null;

		switch (variableName) {
			case VariableType.cardName: {
				const cardName = storyFacade.getDataByCardId(card._id).card?.name;
				if (cardName !== undefined) value = cardName;
				break;
			}
			case VariableType.date: {
				if (storyFacade.language === 'HE') {
					value = heILFormatter.format(new Date());
				} else {
					value = new Date().toLocaleDateString().replaceAll('/', '.');
				}
				break;
			}
			case VariableType.time: {
				value = new Date().toLocaleTimeString();
				break;
			}
			case VariableType.datePublished: {
				const d = new Date(storyFacade.story.updatedAt);
				const format = '2-digit';
				// use template string instead of locale options to support old browsers
				value = `${d
					.toLocaleDateString(undefined, {
						day: format,
						month: format,
						year: 'numeric',
						hour: format,
						minute: format,
						second: format,
					})
					.replaceAll('/', '.')
					.replace(',', '')}`;
				break;
			}
			case VariableType.cardsScored:
				value = getTotalScore().cardsScored;
				break;
			case VariableType.cardsAnswered:
				value = getTotalScore().cardsAnswered;
				break;
			case VariableType.cardsVisited:
				value = storyFacade.totalVisitedCards;
				break;
			case VariableType.totalAnswerCards:
				value = storyFacade.cards.filter(c => new CardFacade(c).hasFeature.answer).length;
				break;
			case VariableType.totalScoreCards:
				value = storyFacade.cards.filter(c => new CardFacade(c).hasFeature.score).length;
				break;
			case VariableType.correct:
				value = getTotalScore().answersCorrect;
				break;
			case VariableType.incorrect:
				value = getTotalScore().answersCorrectIncorrect - getTotalScore().answersCorrect;
				break;
			case VariableType.answersMin:
				value = CardFacade.getAnswersMin(cardFacade.type, storyCardSettings);
				break;
			case VariableType.answersMax:
				value = getAnswersMax({ cardFacade, storyCardSettings });
				break;
			case VariableType.answersSelected:
				value = totalSelectedAnswers;
				break;
			case VariableType.answerIndex: {
				value = Math.min(totalSelectedAnswers + 1, getAnswersMax({ cardFacade, storyCardSettings }));
				break;
			}
			case VariableType.answersScore:
				value = getTotalScore().scorePerCard[cardFacade.cardId] ?? 0;
				break;
			case VariableType.storyAnswersSelected:
				value = getTotalScore().totalAnswersSelected;
				break;
			case VariableType.score:
				value = getTotalScore().scoreTotal;
				break;
			case VariableType.scorePercent:
				value = round(getTotalScore().scoreRelative, 2);
				break;
			default: {
				if (!variableName) {
					break;
				}

				// Generic variables
				if (variableName.match(genericVariablePattern[VariableType.fieldValue])) {
					const fieldValParts = extractGenericVariableParts[VariableType.fieldValue](variableName)!;
					const { cardId, componentId } = fieldValParts;
					const fieldVal = componentId ? storyFacade.submittedFormData[cardId]?.[componentId] : null;
					if (fieldVal !== undefined) value = fieldVal;
					break;
				}
				if (variableName.match(genericVariablePattern[VariableType.characterScore])) {
					/*
					 todo: Think what I can do with a character results.

					 const characterResults = this.storyScore[CountType.character]();
					 1. When NavigationCard last time ran I can store how much points and which character won
					 and display there points as $score or $points, or I can just remember eventId and cardId which led
					 user to current page
					 2. + It is possible by demand somehow show how much points each character got.
					 */
					const characterParts = extractGenericVariableParts[VariableType.characterScore](variableName)!;
					value = getTotalCharacterPoints()[characterParts.characterId] ?? 0;
					break;
				}
				if (variableName.match(genericVariablePattern[VariableType.characterScorePercent])) {
					const characterParts =
						extractGenericVariableParts[VariableType.characterScorePercent](variableName)!;
					const totalCharacterPoints = getTotalCharacterPoints();
					const charPoints = totalCharacterPoints[characterParts.characterId] ?? 0;
					const totalPoints = sum(Object.values(totalCharacterPoints));
					value = `${charPoints > 0 ? round((charPoints / totalPoints) * 100, 2) : 0}%`;
					break;
				}
				if (variableName.match(genericVariablePattern[VariableType.urlParam])) {
					const urlParamParts = extractGenericVariableParts[VariableType.urlParam](variableName);
					const searchParams = new URLSearchParams(window.location.search);
					if (urlParamParts) {
						const paramValue = searchParams.get(urlParamParts.param);
						if (paramValue !== null) value = paramValue;
					}
					break;
				}

				// Custom variables
				const customVariables = storyFacade.settings.variables?.custom;
				const customVariable = customVariables?.find(v => v.name === variableName);
				if (customVariable) {
					value = customVariable.value;
					break;
				}

				// Integrated Google spreadsheet range values
				if (variableName.match(genericVariablePattern[VariableType.spreadsheetRange])) {
					const parts = extractGenericVariableParts[VariableType.spreadsheetRange](variableName);
					if (parts?.param) {
						const range = spreadsheetValuesMap[parts.param];
						const hasValues = range && Array.isArray(range.values);
						const rangeValue = hasValues ? range.values.flatMap(arr => arr).join(', ') : null;
						if (rangeValue) value = rangeValue;
					}
					break;
				}
			}
		}

		draftNode.textContent = value !== null && value !== undefined ? `${value}` : '';
	});

	return {
		variables,
		html: div.innerHTML,
	};
}

// "he-IL" locale provides the date in the format of "dd.mm.yyyy"
const heILFormatter = new Intl.DateTimeFormat('he-IL', {
	year: 'numeric',
	month: '2-digit',
	day: '2-digit',
});
