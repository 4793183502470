import {
	COMPONENT_STATES,
	COMPONENT_TYPE as TYPE,
	DEFAULT_MEDIA_QUERY_PLATFORMS,
	LAYER_TYPE,
	COMPONENT_DISPLAY_NAME,
} from 'common/constants';
import { generateElementId, generateNodeId } from 'utils/generate-id';
import View from 'client/components/common/BuildingBlocks/Box/Box';
import { defaultProps } from './constants';

export const BOX = () => {
	const _id = generateElementId();

	return {
		view: View,
		get model() {
			const type = TYPE.BOX;

			return {
				_id,
				type,
				uiConfig: {
					layer: LAYER_TYPE.CONTENT,
					editorProps: {
						name: COMPONENT_DISPLAY_NAME[type],
						selectable: true,
						draggable: true,
						expandable: true,
						expanded: true,
					},

					componentProps: {
						styles: {
							[COMPONENT_STATES.DEFAULT]: {
								[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]: {
									// ...
								},
							},
						},
						other: {
							[COMPONENT_STATES.DEFAULT]: {
								[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]: {
									boxType: defaultProps.boxType,
								},
							},
						},
					},
					nodeProps: {
						id: generateNodeId(_id, type),
					},
				},
				children: [],
			};
		},
	};
};
