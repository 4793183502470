import _ from 'lodash';
import { produce } from 'immer';
import { appLog } from 'utils/helpers';
import { componentWalk } from 'utils/blocks/component-walk';
import { DEFAULT_MEDIA_QUERY_PLATFORMS, COMPONENT_STATES_ORDERED, LAYER_TYPE } from 'common/constants';
import type { BBModel, StoryMediaQuery, StoryStep } from 'types/story';

/**
 * Transform element(s) and theirs children values, which are can be configurable
 * by state or media query and have predefined default values.
 *
 * In short this is to pick "default" values of media queries
 * and write them to defaultPlatform key of Story mediaQuery, then delete "default" key.
 *
 * Common use cases is: add new element, add element into one of the card facades "this.defaultElements"
 *
 * @param {String} defaultPlatform - Story default platform key
 * @param {Object|Array} dataOrigin - Card elements array or single element object
 * @return {Object|Array} new edited data(dataOrigin)
 */
function transformElementDefaults(
	defaultPlatform: StoryMediaQuery['defaultPlatform'],
	dataOrigin: BBModel | BBModel[]
) {
	const log = appLog.extend('utils:transformElementDefaults');
	log('RUN');

	// Flow: mapComponents -> mapProperties -> transformProperty

	function transformProperty(property, component) {
		const values = _.get(component, property);

		// log(`component ${property} Before:`, _.cloneDeep(values));

		if (!values) {
			return;
		}

		_.forEach(COMPONENT_STATES_ORDERED, state => {
			if (values[state]) {
				const defaultValues = _.get(values, [state, DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]);
				const defaultPlatformValues = _.get(values, [state, defaultPlatform]);

				const isArrayOrObject = v => _.isArray(v) || _.isObject(v);

				// Overwrite components values
				if (isArrayOrObject(defaultValues) || isArrayOrObject(defaultPlatformValues)) {
					_.set(values, [state, defaultPlatform], { ...defaultValues, ...defaultPlatformValues });
				} else {
					_.set(values, [state, defaultPlatform], defaultPlatformValues || defaultValues);
				}

				// drop component "default" values
				delete values[state][DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT];
			}
		});

		// log(`component ${property} After:`, _.cloneDeep(values));
	}

	function mapProperties({ component }) {
		const children = _.get(component, 'children');

		_.forOwn(_.get(component, 'uiConfig.componentProps'), (value, key) => {
			transformProperty(`uiConfig.componentProps.${key}`, component);
		});

		if (children && _.isArray(children)) {
			componentWalk(children, mapProperties);
		} else if (children && _.isObject(children)) {
			// Currently only Text component has children as object, what means his children can be
			// overwritten by state or media query
			transformProperty('children', component);
		}
	}

	if (Array.isArray(dataOrigin)) {
		return produce(dataOrigin, draft => {
			componentWalk(draft, mapProperties);
		});
	}

	return produce(dataOrigin, draft => {
		mapProperties({ component: draft });
	});
}

/**
 * Change layer type to move element to Content or FLA|FLB building blocks
 *
 * @param elementOrigin {Object} Story card element object
 * @param discard {Boolean} transform element from float children to default
 * @return {Object} new edited elementOrigin
 */
function transformElementToFloatChildren(elementOrigin, discard = false) {
	return produce(elementOrigin, draft => {
		_.set(draft, 'uiConfig.layer', discard ? LAYER_TYPE.CONTENT : LAYER_TYPE.FLOAT);
	});
}

/**
 * @param steps {Array} Story steps
 * @param cardId {String} searchable card id
 * @return {String}
 */
function getPathToCardInSteps(steps: StoryStep[], cardId: string) {
	return _.reduce(
		steps,
		(memo, step, index) => {
			const cardIndex = _.findIndex(step.cards, card => card._id === cardId);
			if (cardIndex !== -1) {
				return `${index}.cards.${cardIndex}`;
			}
			return memo;
		},
		''
	);
}

export { transformElementDefaults, transformElementToFloatChildren, getPathToCardInSteps };
