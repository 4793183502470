import type { CardData, CardTemplate } from 'types/story';
import { CARD_DEFAULT_TEMPLATE_NAME } from 'common/constants';
import { defaultTemplate } from './templates/default-template';

export type CardFacadeTemplate = {
	[key: string]: (params: { type: CardData['type']; name: string }) => CardTemplate;
};

class CardFacadeBase {
	static template: CardFacadeTemplate = {
		[CARD_DEFAULT_TEMPLATE_NAME]: defaultTemplate,
	};

	data: CardData;

	constructor(card: CardData) {
		const { _id, type, name, events, elements, settings } = card;

		this.data = {
			_id,
			type,
			name,
			settings,
			events,
			elements,
		};
	}
}

export { CardFacadeBase };
