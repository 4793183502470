import React from 'react';
import { UserFeatureFlags } from 'types/user';
import { useClientSelector } from 'client/reducers';
import { selectFeatureFlags } from 'client/reducers/story/selectors';

type Props = {
	feature: keyof UserFeatureFlags;
	children: React.ReactNode;
};

const ConditionalFeatureRender: React.FC<Props> = props => {
	const flags = useClientSelector(selectFeatureFlags);
	return flags[props.feature] ? props.children : null;
};

export default ConditionalFeatureRender;
