import React from 'react';
import type { BBCommonProps } from 'types/story';
import cn from 'classnames';
import { attachRef } from 'utils/helpers';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import { defaultProps } from '../constants';
import Context from '../context';
import css from './SliderPagination.scss';

const SliderPagination: React.FC<BBCommonProps> = props => {
	const { setPaginationSettings, paginationRef } = React.useContext(Context);
	const { editableModeProps: EMP, stateAttrs, uiConfig } = props;
	const customBg = uiConfig.componentProps.styles.backgroundColor || uiConfig.componentProps.styles.backgroundImage;
	const { paginationType = defaultProps.paginationType } = uiConfig.componentProps.other;

	React.useEffect(() => {
		setPaginationSettings({ type: paginationType });
	}, [setPaginationSettings, paginationType]);

	return (
		<div
			{...uiConfig.nodeProps}
			{...stateAttrs}
			{...props.eventListeners}
			{...EMP?.nodeProps}
			style={uiConfig.nodeProps?.style}
			className={cn(css.pagination, uiConfig.nodeProps.className, EMP?.nodeProps?.className, {
				[css.customBg]: customBg,
			})}
			ref={attachRef([props.containerRef!])}
		>
			{/* Maintain a clean Swiper pagination node to avoid attribute conflicts */}
			<div ref={paginationRef} />
		</div>
	);
};

export default withCardTransitionContext(SliderPagination);
