import store from 'client/store';
import type { ThunkDispatch } from 'redux-thunk';
import { sleep } from 'utils/helpers';
import {
	sendFinishStoryEvent,
	sendStartStoryEvent,
	sendRegisterEvent,
	sendCardViewEvent,
	sendStoryViewEvent,
	sendShareEvent,
} from 'client/actions/api-events';

class _ApiEvents {
	dispatch: ThunkDispatch<any, any, any> = store.dispatch;

	viewedCards: string[] = [];

	isStoryViewSent: boolean = false;

	canSendStartStoryEvent: boolean = true;

	canSendFinishStoryEvent: boolean = true;

	isRegisterSent: boolean = false;

	sendStartStoryEvent = (payload: Parameters<typeof sendStartStoryEvent>[0]) => {
		if (!this.canSendStartStoryEvent) {
			return;
		}

		this.dispatch(sendStartStoryEvent(payload));
		this.canSendStartStoryEvent = false;
	};

	sendFinishStoryEvent = (payload: Parameters<typeof sendFinishStoryEvent>[0]) => {
		if (!this.canSendFinishStoryEvent) {
			return;
		}

		this.dispatch(sendFinishStoryEvent(payload));
		this.canSendFinishStoryEvent = false;
	};

	sendCardViewEvent = (payload: Parameters<typeof sendCardViewEvent>[0]) => {
		if (this.viewedCards.includes(payload.cardId)) {
			return;
		}

		this.dispatch(sendCardViewEvent(payload));
		this.viewedCards.push(payload.cardId);
	};

	sendShareEvent = (payload: Parameters<typeof sendShareEvent>[0]) => {
		this.dispatch(sendShareEvent(payload));
	};

	sendStoryViewEvent = async (payload: Parameters<typeof sendStoryViewEvent>[0], defer?: boolean) => {
		if (this.isStoryViewSent) {
			return;
		}

		if (defer) await sleep(1000);

		this.dispatch(sendStoryViewEvent(payload));
		this.isStoryViewSent = true;
	};

	sendRegisterEvent = async (payload: Parameters<typeof sendRegisterEvent>[0], defer?: boolean) => {
		if (this.isRegisterSent) {
			return;
		}

		if (defer) await sleep(1000);

		this.dispatch(sendRegisterEvent(payload));
		this.isRegisterSent = true;
	};
}

export const ApiEvents = new _ApiEvents();
