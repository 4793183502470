import React from 'react';
import noop from 'lodash/noop';
import { CounterType } from './constants';

interface Params {
	isEditableMode: boolean;
	counterType: CounterType;
	counterDate?: string;
	onComplete?: () => void;
}

export function useCounter(params: Params) {
	const { counterType, counterDate, isEditableMode, onComplete } = params;
	const [currentValue, setCurrentValue] = React.useState<number>(() => getCurrentValue(counterType, counterDate));

	React.useEffect(() => {
		if (isEditableMode) {
			const newValue = getCurrentValue(counterType, counterDate);
			if (newValue !== currentValue) setCurrentValue(newValue);
			return noop;
		}

		let interval: ReturnType<typeof setInterval> | null = null;
		if (counterDate) {
			interval = setInterval(() => {
				const newValue = getCurrentValue(counterType, counterDate);
				if (newValue !== currentValue) setCurrentValue(newValue);
				if (newValue === 0) onComplete?.();
			}, 1000);
		}

		return () => {
			if (interval) clearInterval(interval);
		};
	}, [isEditableMode, currentValue, counterDate, counterType, onComplete]);

	return {
		currentValue: currentValue ?? 0,
	};
}

function getCurrentValue(counterType: CounterType, date?: string) {
	if (!date) return 0;
	return counterType === CounterType.CountDown
		? Math.max(0, new Date(date).getTime() - Date.now())
		: Math.max(0, Date.now() - new Date(date).getTime());
}
