import {
	COMPONENT_TYPE as TYPE,
	LAYER_TYPE,
	COMPONENT_DISPLAY_NAME,
	COMPONENT_STATES,
	DEFAULT_MEDIA_QUERY_PLATFORMS,
} from 'common/constants';
import { generateElementId, generateNodeId } from 'utils/generate-id';
import View from 'client/components/common/BuildingBlocks/Video/Video';
import { OTHER_PROPS, CSS_PROPS } from 'common/constants/component-props';
import { defaultProps } from './constants';

export const VIDEO = () => {
	const _id = generateElementId();

	return {
		view: View,
		get model() {
			const type = TYPE.VIDEO;

			return {
				_id,
				type,
				uiConfig: {
					layer: LAYER_TYPE.CONTENT,
					editorProps: {
						name: COMPONENT_DISPLAY_NAME[type],
						selectable: true,
						draggable: true,
						expandable: false,
						expanded: false,
					},
					nodeProps: {
						id: generateNodeId(_id, type),
					},
					componentProps: {
						styles: {
							[COMPONENT_STATES.DEFAULT]: {
								[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]: {
									[CSS_PROPS.size.height]: '11.25vw', // 16:9 aspect ratio to match the default video
								},
							},
						},
						other: {
							[COMPONENT_STATES.DEFAULT]: {
								[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]: {
									[OTHER_PROPS.video.controls]: true,
									[OTHER_PROPS.video.videoSrc]: defaultProps[OTHER_PROPS.video.videoSrc],
									[OTHER_PROPS.video.videoPoster]: defaultProps[OTHER_PROPS.video.videoPoster],
								},
							},
						},
					},
				},
				children: [],
			};
		},
	};
};
