import { createContext } from 'react';

export default createContext<
	| {
			showResults: boolean;
			answerVote: { isShow: boolean; value: number; duration: number };
	  }
	| undefined
>({
	showResults: false,
	answerVote: { isShow: false, value: 0, duration: 350 },
});
