import React, { RefObject, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import useStyle from 'antd/es/select/style';
import DownOutlined from '@ant-design/icons/DownOutlined';
import { ConfigContext } from 'antd/es/config-provider/context';

import { BBCommonProps, EditorMode } from 'types/story';
import { COMPONENT_STATES } from 'common/constants';
import { isLayerType } from 'common/utils/blocks/is-layer-type';
import { CSS_PROPS, UNIFORM_PROPS } from 'common/constants/component-props';
import { transmitToAdminBbUpdate } from 'client/utils/transmit-to-admin-bb-update';
import type { TFieldProps } from 'client/components/common/BuildingBlocks/Fields/types';
import { SelectionHintEvent } from 'client/components/common/SelectionHint/SelectionHintEvent';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import { FIELD_DATA_ATTR, FIELD_EDITABLE_ATTR, FieldBBType, SelectBBOptionsOrder } from '../constants';
import { placeholderPath, WithFieldT, withField } from '../utils';
import { Error } from '../Error';
import css from './Select.scss';

const SelectComponent = React.lazy(() => import('./SelectComponent'));

const Select: React.FC<BBCommonProps & WithFieldT> = props => {
	// use antd context and styles to reuse antd select styles in editor
	const context = React.useContext(ConfigContext);
	const prefix = context.getPrefixCls('select', '');
	const [, hashId] = useStyle(prefix);

	const propsRef = useRef(props);
	propsRef.current = props;

	const { isEditableMode, editorMode } = props;
	const path = props.editableModeProps?.nodeProps?.['data-path'];
	const providedPlaceholder = props.uiConfig.componentProps[UNIFORM_PROPS.fieldPlaceholder];

	const [pending, setPending] = useState(false);
	const [readOnly, setReadOnly] = useState(isEditableMode);
	const [placeholder, setPlaceholder] = useState(providedPlaceholder);

	const nodeProps = {
		...props.uiConfig.nodeProps,
		...(props.editableModeProps ? props.editableModeProps.nodeProps : null),
		className: cn(css.select, props.uiConfig.nodeProps.className, props.editableModeProps?.nodeProps?.className),
	};
	const isIncorrect = 'data-incorrect-state' in nodeProps || props.states[COMPONENT_STATES.INCORRECT];
	const errorMessage = props.getErrorMsg(nodeProps);

	// place caret into input to start editing placeholder
	useEffect(() => {
		if (!readOnly) {
			const p = propsRef.current as WithRequired<BBCommonProps, 'editableModeProps'>;
			SelectionHintEvent.triggerFocus(p);
		}
	}, [readOnly]);

	// update placeholder with a value from props
	useEffect(() => {
		setPlaceholder(providedPlaceholder);
	}, [providedPlaceholder]);

	// ensure that editable placeholder successfully saved to complete editing mode
	useEffect(() => {
		if (providedPlaceholder === placeholder && pending) {
			setPending(false);
			setReadOnly(true);
		}
	}, [providedPlaceholder, placeholder, pending]);

	// list of common input properties
	const inputProps: TFieldProps = {
		className: cn(css.field, { [css.readonly]: readOnly }),
		type: FieldBBType.text,
		name: props.uiConfig.editorProps?.name,
		placeholder,
		autoComplete: 'off',
		[FIELD_DATA_ATTR]: '',
	};

	// list of properties to enable placeholder inline editing
	const editableInputProps: TFieldProps = {
		...inputProps,
		...(readOnly ? null : { [FIELD_EDITABLE_ATTR]: '' /* necessarily prevent component drag */ }),
		readOnly,
		value: readOnly ? '' : placeholder ?? '',
		style: { color: props.uiConfig.componentProps.styles[CSS_PROPS.field.style.text.phColor] },
		[editorMode === EditorMode.CONTENT ? 'onClick' : 'onDoubleClick']: () => {
			setReadOnly(false);
		},
		onChange: event => setPlaceholder(event.target.value),
		onBlur: event => {
			if (placeholder === providedPlaceholder) {
				setReadOnly(true);
			} else {
				transmitToAdminBbUpdate({
					id: SelectComponent.name,
					path: `elements.${path}.${placeholderPath}`,
					value: placeholder,
					isStory: isLayerType(props).global,
				});
				setPending(true);
			}
		},
		onFocus: event => {
			// prevent this block from being selected when clicking on its parent
			event.stopPropagation();
		},
	};

	return (
		<div
			{...props.stateAttrs}
			{...props.eventListeners}
			{...nodeProps}
			ref={props.containerRef as RefObject<HTMLDivElement>}
		>
			{isEditableMode ? (
				<>
					{/* repeat antd select structure to ensure consistent styling in both editor/preview modes */}
					<div className={`${hashId} ${css.field} ant-select ant-select-allow-clear ant-select-show-arrow`}>
						<div className="ant-select-selector">
							<span className="ant-select-selection-search">
								<input {...editableInputProps} className="" />
							</span>
						</div>
						<DownOutlined className="ant-select-arrow" />
					</div>
				</>
			) : (
				<React.Suspense fallback={null}>
					<SelectComponent
						inputProps={inputProps}
						platform={props.currentMediaQuery}
						isMulti={Boolean(props.uiConfig.componentProps[UNIFORM_PROPS.selectIsMulti])}
						options={props.uiConfig.componentProps[UNIFORM_PROPS.selectOptions] ?? []}
						order={
							props.uiConfig.componentProps[UNIFORM_PROPS.selectOptionsOrder] ??
							SelectBBOptionsOrder.alphabet
						}
					/>
				</React.Suspense>
			)}
			<Error
				isHidden={!isIncorrect || errorMessage === undefined}
				path={path}
				isEditableMode={!!isEditableMode}
				errorType={props.uiConfig.editorProps[UNIFORM_PROPS.fieldErrorShow]}
				text={errorMessage}
				editorMode={editorMode}
			/>
		</div>
	);
};

export default withCardTransitionContext(withField(Select));
