export enum ShareType {
	fb = 'Facebook',
	wa = 'WhatsApp',
	tw = 'Twitter',
	email = 'E-mail',
}

export enum ShareTarget {
	story = 'story',
	parent = 'parent',
	custom = 'custom',
}

export enum ShareOgSource {
	story = 'story',
	card = 'card',
}

export const DEFAULT_SHARE_TARGET = ShareTarget.story;

export const DEFAULT_SHARE_OG_SOURCE = ShareOgSource.story;
