import React from 'react';
import { FormContext } from './FormContext';

export const withFormContext = WrappedComponent => {
	const name = WrappedComponent.displayName || WrappedComponent.name;

	const Block = props => {
		return (
			<FormContext.Consumer>
				{values => <WrappedComponent {...props} formContext={values} />}
			</FormContext.Consumer>
		);
	};

	Object.defineProperty(Block, 'name', { value: name, writable: false });

	return Block;
};
