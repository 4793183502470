import React from 'react';
import type { BBCommonProps } from 'types/story';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';

const SliderComponent = React.lazy(() => import('./SliderComponent'));

const Slider: React.FC<BBCommonProps> = props => {
	return (
		<React.Suspense fallback={<div ref={props.containerRef as React.RefObject<HTMLDivElement>} />}>
			<SliderComponent {...props} />
		</React.Suspense>
	);
};

export default withCardTransitionContext(Slider);
