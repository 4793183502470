import { set } from 'lodash';
import { location } from 'utils/url-helper';

const { isScreenshot } = location.client;

/**
 * interceptor for api requests - adds session id and isBrowser keys
 *
 * @param {object} req - request object
 * @param {object} requestOptions - request options
 * @param {object} requestOptions.data - request data
 * @return req
 */
export function apiRequest(req, requestOptions) {
	if (isScreenshot()) req.cancel();

	req.withCredentials();

	set(requestOptions, ['data'], { data: requestOptions.data });

	return req;
}
