import { forOwn } from 'lodash';
import type { StoryMediaPlatform, StoryMediaQuery, StorySingleMediaQueryConfig } from 'types/story';

export type MediaKeysInOrderOfInheritance = StoryMediaPlatform[];

/**
 * Get story element media queries keys in order of inheritance
 *
 * @param defaultPlatform {String}
 * @param currentMediaQueryConfig {Object} Current card editor media query config
 * @param currentMediaQueryConfig.maxWidth {Number}
 * @param currentMediaQueryConfig.minWidth {Number}
 * @param orderedMediaQueryConfig {Object} Story.data.mediaQuery.config as ordered array by min\max values
 * @param orderedMediaQueryConfig.key {String}
 * @param orderedMediaQueryConfig.config {Object}
 * @return {Array}
 */
export const getMediaKeysInOrderOfInheritance = (
	defaultPlatform: StoryMediaQuery['defaultPlatform'],
	currentMediaQueryConfig: StorySingleMediaQueryConfig,
	orderedMediaQueryConfig
): MediaKeysInOrderOfInheritance => {
	const keys: StoryMediaQuery['defaultPlatform'][] = [defaultPlatform];

	const getMediaProperty = _currentMediaQueryConfig => {
		if (_currentMediaQueryConfig.maxWidth) {
			return 'maxWidth';
		}
		if (_currentMediaQueryConfig.minWidth) {
			return 'minWidth';
		}
		return '';
	};

	const mediaProperty = getMediaProperty(currentMediaQueryConfig);
	const isMax = mediaProperty === 'maxWidth';
	const isMin = mediaProperty === 'minWidth';

	forOwn(orderedMediaQueryConfig, object => {
		const value = object.config[mediaProperty];
		const elementValue = currentMediaQueryConfig[mediaProperty];

		// max eg.: default -> max900 -> max800
		if (!!value && isMax && value >= elementValue) {
			keys.push(object.key);
		}

		// min eg.: default -> min1200 -> min1600
		if (!!value && isMin && value <= elementValue) {
			keys.push(object.key);
		}
	});

	return keys;
};
