import type { DictionaryType } from 'types/story';
import { COMPONENT_TYPE as TYPE, COMPONENT_DISPLAY_NAME, LAYER_TYPE } from 'common/constants';
import { generateElementId, generateNodeId } from 'utils/generate-id';
import { SLIDER_ARROW } from 'client/components/common/BuildingBlocks/Slider/SliderArrow';
import { SLIDER_PAGINATION } from 'client/components/common/BuildingBlocks/Slider/SliderPagination';
import { SLIDER_SLIDES } from 'client/components/common/BuildingBlocks/Slider/SliderSlides';
import { SliderArrowDir } from 'client/components/common/BuildingBlocks/Slider/constants';
import View from './Slider';

type Props = {
	dictionary?: DictionaryType;
	lang?: string;
};

export const SLIDER = ({ dictionary = {}, lang }: Props = {}) => {
	const _id = generateElementId();

	return {
		view: View,
		get model() {
			const type = TYPE.SLIDER;

			return {
				_id,
				type,
				uiConfig: {
					layer: LAYER_TYPE.CONTENT,
					editorProps: {
						name: COMPONENT_DISPLAY_NAME[type],
						selectable: true,
						draggable: true,
						expandable: true,
						expanded: true,
					},
					nodeProps: {
						id: generateNodeId(_id, type),
					},
					componentProps: {
						styles: {},
						other: {},
					},
				},
				children: [
					SLIDER_ARROW({ dictionary, direction: SliderArrowDir.PREV, lang }).model,
					SLIDER_ARROW({ dictionary, direction: SliderArrowDir.NEXT, lang }).model,
					SLIDER_PAGINATION().model,
					SLIDER_SLIDES().model,
				],
			};
		},
	};
};
