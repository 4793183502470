import React, { useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';

type Props = {
	children: React.ReactNode;
	targetNodeId: string;
};

const PrependPortal: React.FC<Props> = ({ children, targetNodeId }) => {
	const portalRef = useRef(document.createElement('span'));

	useEffect(() => {
		const portalNode = portalRef.current;
		let targetNode = document.getElementById(targetNodeId);

		if (!targetNode) {
			targetNode = document.createElement('div');
			targetNode.id = targetNodeId;
			document.body.appendChild(targetNode);
		}

		targetNode?.insertBefore(portalNode, targetNode.firstChild);

		return () => {
			if (targetNode?.contains(portalNode)) {
				targetNode.removeChild(portalNode);
			}
		};
	}, [targetNodeId]);

	return createPortal(children, portalRef.current);
};

export default PrependPortal;
