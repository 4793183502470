import produce from 'immer';
import type { DictionaryType } from 'types/story';
import { COMPONENT_TYPE as TYPE, COMPONENT_DISPLAY_NAME } from 'common/constants';
import { UNIFORM_PROPS } from 'common/constants/component-props';
import { generateNodeId } from 'utils/generate-id';
import { BUTTON } from 'client/components/common/BuildingBlocks/Button';

type Props = {
	dictionary?: DictionaryType;
};

export const BUTTON_SUBMIT = ({ dictionary = {} }: Props = {}) => {
	const type = TYPE.BUTTON_SUBMIT;
	const Button = BUTTON({ dictionary, type });
	return {
		view: Button.view,
		get model() {
			return produce(Button.model, acc => {
				acc.uiConfig.editorProps.name = COMPONENT_DISPLAY_NAME[type];
				acc.uiConfig.componentProps[UNIFORM_PROPS.btnSubmit] = true;
				acc.uiConfig.nodeProps.id = generateNodeId(acc._id, type);
			});
		},
	};
};
