import { DEFAULT_MEDIA_QUERY_SIZES } from 'common/constants';
import { EMBED_SCRIPT_MESSAGE_SENDER_ID, STORY_MESSAGE_SENDER_ID, embedingEvent } from 'client/constants/common';
import { ADMIN_PREVIEW_PAGE } from 'client/constants/routes';
import { IS_DEVEL } from 'utils/environment';
import UrlHelper from 'utils/url-helper';

/*
 * Story embeding code sample:
 *
 * <!-- StoryCards embed start -->
 * 	<div id="story-$clientStoryId">
 *    <!--  ADD THESE STYLES FOR A VERSION WITHOUT AUTO-HEIGHT style="width: 500px; height: 300px; display: flex;" -->
 *  </div>
 *	<script
 *	  src="https://stories.sc/$clientStoryId/sdk-prod.js"
 *	  onload="(() => embedStory('$clientStoryId', { autoHeight: true, start: 'in-view' }))()"
 *	  async
 *  ></script>
 * <!-- StoryCards embed end -->
 */

export type EmbedStoryParams = Partial<{
	lazy: boolean;
	start: 'onload' | 'in-view';
	autoHeight: boolean;
	autoScrollOnCardChange: boolean;
	pageScroll: boolean;
	isStretchHeight: boolean;
	aspectRatio: boolean; // preserve iframe size aspect ratio to look the same as in story editor
	viewport: {
		w: number;
		h: number;
	};
	domain?: string;
	// search query string passed to src
	urlParams?: string;
}>;

export type MessageData<T, P> = {
	type: T;
	payload: P;
	sender?: typeof EMBED_SCRIPT_MESSAGE_SENDER_ID | typeof STORY_MESSAGE_SENDER_ID;
};

export type IncommingMessage<T, P> = {
	data: MessageData<T, P>;
	source?: Window;
};

export type OutcommingMessage<T, P> = MessageData<T, P> & {
	sender?: typeof EMBED_SCRIPT_MESSAGE_SENDER_ID;
};

export type StoryMessage =
	| IncommingMessage<typeof embedingEvent.STORY_IS_READY, void>
	| IncommingMessage<typeof embedingEvent.STORY_RESIZE, { w: number; h: number }>
	| IncommingMessage<typeof embedingEvent.CARD_CHANGED, { cardHeight: number }>;

export type EmbedScriptMessage =
	| OutcommingMessage<typeof embedingEvent.PAGE_IS_READY, EmbedStoryParams>
	| OutcommingMessage<typeof embedingEvent.STORY_IN_VIEWPORT, boolean>
	| OutcommingMessage<
			typeof embedingEvent.PAGE_SCROLL,
			{
				pageYOffset: number;
				containerPosTop: number;
				viewport: { w: number; h: number };
			}
	  >;

export const DEFAULT_PARAMS: EmbedStoryParams = {
	lazy: true,
	start: 'onload',
	autoHeight: true,
	autoScrollOnCardChange: true,
	pageScroll: true,
	aspectRatio: false,
	domain: '',
	urlParams: '',
};

(function initSDK() {
	const currentWindow = window as any;
	const isLogEnabled = currentWindow.location.search.match(/[?|&]sclog=1/g) !== null;
	const log = (...args) => isLogEnabled && console.info('SC-SDK', ...args);
	const error = (...args) => isLogEnabled && console.error('SC-SDK', ...args);
	let id = 'embedStory';

	if (document.currentScript) {
		const { dataset } = document.currentScript;
		if (dataset.id) id = dataset.id;
	}

	if (!currentWindow.embedStory || !currentWindow.embedStory.items) {
		currentWindow.embedStory = function embedStory(clientStoryId, params) {
			const fn = currentWindow.embedStory.items[clientStoryId] || currentWindow.embedStory.items[id];
			fn(clientStoryId, params);
		};
		currentWindow.embedStory.items = {};
	}

	currentWindow.embedStory.items[id] = function script(clientStoryId: string, _params: EmbedStoryParams = {}) {
		const defaultParams: EmbedStoryParams = {
			...DEFAULT_PARAMS,
			viewport: { w: currentWindow.innerWidth, h: currentWindow.innerHeight },
		};

		const params = { ...defaultParams, ..._params };

		log('init', { clientStoryId, params, _params });

		if (!clientStoryId) {
			error('No clientStoryId provided');
			return;
		}

		const containerID = `story-${clientStoryId}`;
		const container = document.getElementById(containerID);

		if (!container) {
			error(`Container #${containerID} was not found.`);
			return;
		}

		const iframe = document.createElement('iframe') as HTMLIFrameElement & {
			loading: 'lazy' | 'eager';
			contentWindow: Window;
		};
		let isInViewport: boolean;

		if (IS_DEVEL) iframe.id = 'client-iframe';
		iframe.style.boxSizing = 'border-box';
		iframe.style.display = 'block';
		iframe.style.width = '100%';
		iframe.style.border = '0';

		const url = new URL(
			params.domain
				? params.domain
				: IS_DEVEL
					? `${window.location.origin}${ADMIN_PREVIEW_PAGE}?embedDev&storyId=${clientStoryId.split('/')[1]}`
					: UrlHelper.getPublishedUrl({ clientStoryId })
		);

		if (params.urlParams) {
			url.search += `${url.search ? `&` : ''}${params.urlParams || ''}`;
		}

		iframe.src = url.href;

		if (params.lazy) {
			iframe.loading = 'lazy';
		}

		if (params.aspectRatio && !params.autoHeight) {
			const cw = container.clientWidth;
			Object.values(DEFAULT_MEDIA_QUERY_SIZES)
				.sort((a, b) => (a.min || 0) - (b.min || 0))
				.forEach(({ min, ratio }) => {
					if (cw >= (min || 0)) {
						container.style.height = `${cw / ratio}px`;
						iframe.style.height = '100%';
					}
				});
		}

		// if fixed size
		if (container.style.width && container.style.height) {
			iframe.style.height = '100%';
		}

		// if stretch height
		if (container.style.height && container.style.height === '100vh') {
			iframe.style.height = '100%';
		}

		container.style.position = 'relative';
		container.append(iframe);
		currentWindow.addEventListener('message', onStoryMessage);

		/**
		 * CARD_CHANGED event should be called on every card change (including a 1st card)
		 * This event used to scroll current window to card top (params.autoScrollOnCardChange),
		 * to make it visible in case of page was scrolled and next card height is different from previous,
		 * but this behaviour is supposed to use for navigation between card and not for a initially loaded card.
		 */
		let isFirstCardLoaded = false;

		/**
		 * Listen to embed Story events (sent by 'postMessage')
		 */
		function onStoryMessage(message: StoryMessage) {
			if (message.data.sender !== STORY_MESSAGE_SENDER_ID || message.source !== iframe.contentWindow) {
				return;
			}

			log('[GET] message', { message: message.data, params });

			switch (message.data.type) {
				case embedingEvent.STORY_IS_READY:
					postMessage({ type: embedingEvent.PAGE_IS_READY, payload: params });

					currentWindow.addEventListener('scroll', onWindowScroll);
					break;

				case embedingEvent.STORY_RESIZE:
					if (params.autoHeight) {
						iframe.style.height = `${message.data.payload.h}px`;
					}
					break;

				case embedingEvent.CARD_CHANGED:
					onWindowScroll();

					if (params.autoScrollOnCardChange && isFirstCardLoaded) {
						const viewportHeight = currentWindow.innerHeight;
						const containerBBox = container!.getBoundingClientRect();
						const offsetTop = containerBBox.top;
						const offsetBottom = viewportHeight - (containerBBox.top + message.data.payload.cardHeight);
						const offset = -10;

						if (isContainerInView() && (offsetTop < 0 || offsetBottom < 0)) {
							const toBlockTop = currentWindow.pageYOffset + offsetTop + offset;
							currentWindow.scrollTo({
								top: toBlockTop,
								behavior: 'smooth',
							});
						}
					}

					isFirstCardLoaded = true;
					break;

				default:
					break;
			}
		}

		function onWindowScroll() {
			if (params.start === 'in-view') {
				if (isContainerInView()) {
					if (!isInViewport) {
						isInViewport = true;
						postMessage({ type: embedingEvent.STORY_IN_VIEWPORT, payload: isInViewport });
					}
				} else if (isInViewport) {
					isInViewport = false;
					postMessage({ type: embedingEvent.STORY_IN_VIEWPORT, payload: isInViewport });
				}
			}
			if (params.pageScroll) {
				postMessage({
					type: embedingEvent.PAGE_SCROLL,
					payload: {
						pageYOffset: currentWindow.pageYOffset,
						containerPosTop: container!.getBoundingClientRect().top,
						viewport: { w: currentWindow.innerWidth, h: currentWindow.innerHeight },
					},
				});
			}
		}

		function isContainerInView() {
			const viewportHeight = currentWindow.innerHeight;
			const containerBBox = container!.getBoundingClientRect();
			const topPoint = containerBBox.top;
			const bottomPoint = containerBBox.top + containerBBox.height;

			return (
				(topPoint >= 0 && topPoint <= viewportHeight) ||
				(bottomPoint >= 0 && bottomPoint <= viewportHeight) ||
				(topPoint <= 0 && bottomPoint >= viewportHeight)
			);
		}

		/**
		 * Send events to Story
		 */
		function postMessage(message: EmbedScriptMessage) {
			log('[POST] message', { message });
			if (iframe.contentWindow) {
				iframe.contentWindow.postMessage({ ...message, sender: EMBED_SCRIPT_MESSAGE_SENDER_ID }, '*');
			}
		}
	};
	currentWindow.embedStory.items[id].version = process.env.VERSION;
})();
