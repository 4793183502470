/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

export default ({ color = '#FFF', ...props }: SVGAttributes<SVGElement> & { color?: string }) => (
	<svg width="24px" viewBox="0 0 24 24" {...props}>
		<path
			fill={color}
			d="M13.4570256,20 L13.4570256,12.8 L15.6429544,12.8 L16,9.6 L13.4570256,9.6 L13.4570256,8.0416 C13.4570256,7.2176 13.4780744,6.4 14.6295072,6.4 L15.7957512,6.4 L15.7957512,4.112 C15.7957512,4.0776 14.7939976,4 13.7805496,4 C11.6640032,4 10.3387256,5.3256 10.3387256,7.76 L10.3387256,9.6 L8,9.6 L8,12.8 L10.3387256,12.8 L10.3387256,20 L13.4570256,20 Z"
		/>
	</svg>
);
