import _ from 'lodash';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { COMPONENT_TYPE } from 'common/constants';
import { clientLog, defer } from 'utils/helpers';
import { BBEditableModeProps, BBSymbolInfo, BBUiConfig, ComponentTypes } from 'types/story';
import { SELECTION_TYPES } from 'client/components/common/SelectionHint/utils';
import { SelectionHintEvent } from 'client/components/common/SelectionHint/SelectionHintEvent';
import { SelectionHintController } from 'client/components/common/SelectionHint/SelectionHintController';
import { ClientReducerState } from 'client/reducers';

const log = clientLog.extend('SelectedComponentWatcher');

const mapStateToProps = (state: ClientReducerState) => ({
	selected: state.editor.selection[SELECTION_TYPES.clicked],
});

const connector = connect(mapStateToProps);

type OwnProps = {
	uiConfig: BBUiConfig;
	type: ComponentTypes;
	_id: string;
	editableModeProps: BBEditableModeProps;
	symbol?: BBSymbolInfo;
};

type Props = OwnProps & ConnectedProps<typeof connector>;

/**
 * Monitor whether the selected(editable) component did mounted,
 * to trigger click on it, for push to the admin card editor his latest computed data.
 */
class SelectedComponentWatcher extends React.PureComponent<Props> {
	/**
	 * After the component has been rendered,
	 * it is necessary to send new computed data about the selected(editable) component to the admin panel.
	 */
	componentDidMount() {
		this.updateSelectionData();
	}

	componentDidUpdate(prevProps) {
		if (_.get(this.props, 'uiConfig') !== _.get(prevProps, 'uiConfig')) {
			this.updateSelectionData();
		}
	}

	componentWillUnmount() {
		defer(() => {
			const isSelected = !!this.props.selected[this.id];
			const isExists = !!document.getElementById(this.id);

			if (isSelected && !isExists) {
				this.selectCardBB();
			}
		});
	}

	get id() {
		return _.get(this.props.uiConfig, ['nodeProps', 'id']);
	}

	selectCardBB = () => {
		const card = document.querySelector(`[data-bb="${COMPONENT_TYPE.CARD}"]`);
		if (card) {
			card.dispatchEvent(new MouseEvent('mousedown', { bubbles: true }));
		}
	};

	updateSelectionData = () => {
		const { id } = this;
		const { selected } = this.props;

		if (selected[id]) {
			// run asynchronously after finishing current execution block in browser stack
			defer(() => {
				const node = document.getElementById(id) as HTMLElement & HTMLImageElement;

				if (!node) {
					return;
				}

				if (node.tagName === 'IMG') {
					const img = new Image();
					img.onload = () => this.pushUpdates(node);
					img.src = node.src;
				} else {
					this.pushUpdates(node);
				}
			});
		}

		if (_.isEmpty(selected) && this.props.type === COMPONENT_TYPE.CARD) {
			// run asynchronously after finishing current execution block in browser stack
			defer(() => {
				const node = document.getElementById(id);
				this.pushUpdates(node);
			});
		}
	};

	/**
	 * Trigger "click" to update view of "hint"
	 * and also "transmit" component computed data + inheritance to the admin panel.
	 * @param node
	 */
	pushUpdates = node => {
		log('dispatch SelectionHintEvent to update selected element', node.id);

		const totalSelected = _.size(this.props.selected);
		const dispatcher = new SelectionHintEvent({
			options: {
				detail: {
					trigger: SelectionHintController.SELECT,
					selectionType: SELECTION_TYPES.clicked,
					originalEvent: {
						totalSelected,
						shiftKey: totalSelected > 1,
						currentTarget: {
							id: node.id,
						},
					},
					componentProps: {
						_id: this.props._id,
						type: this.props.type,
						uiConfig: this.props.uiConfig,
						editableModeProps: this.props.editableModeProps,
						symbol: this.props.symbol,
					},
				},
			},
		});
		dispatcher.dispatch();
	};

	render() {
		return null;
	}
}

export default connector(SelectedComponentWatcher);
