import { CardData } from 'types/story';
import { SET_CARD } from 'client/constants/actions';
import { setCard } from 'client/actions';

const initialState: CardData | Partial<CardData> = {};
type State = typeof initialState;
type Action = ReturnType<typeof setCard>;

export default function cardReducer(state: State = initialState, action: Action): State {
	const { type, payload } = action;
	if (type === SET_CARD) {
		return {
			...state,
			...payload,
		};
	}

	return state;
}
