/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

export default ({ color = '#FFF', ...props }: SVGAttributes<SVGElement> & { color?: string }) => (
	<svg width="24px" {...props} viewBox="0 0 96 96">
		<path
			fill={color}
			d="M53.137 52.288L73.24 76h-4.764L51.021 55.411 37.08 76H21l21.082-31.134L21 20h4.764l18.433 21.743L58.92 20H75L53.136 52.288h.001zm-7.004-8.121l-2.136 3.107L27 72h7.318l13.716-19.955 2.136-3.107L68 23h-7.318l-14.55 21.165v.002z"
		/>
	</svg>
);
