import { COMPONENT_TYPE as TYPE, LAYER_TYPE, COMPONENT_DISPLAY_NAME } from 'common/constants';
import { generateNodeId, generateElementId } from 'utils/generate-id';

export default function FloatBelowModel() {
	const _id = generateElementId();
	const type = TYPE.FLOAT_BELOW;

	return {
		_id,
		type,
		uiConfig: {
			layer: LAYER_TYPE.CONTENT,
			editorProps: {
				name: COMPONENT_DISPLAY_NAME[type],
				selectable: false,
				draggable: false,
				expandable: true,
				expanded: true,
			},
			nodeProps: {
				id: generateNodeId(_id, 'flb'),
			},
			componentProps: {
				styles: {},
				other: {},
			},
		},
		children: [],
	};
}
