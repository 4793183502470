import React from 'react';
import type { BBCommonProps } from 'types/story';
import cn from 'classnames';
import { attachRef } from 'utils/helpers';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import { SliderArrowDir } from 'client/components/common/BuildingBlocks/Slider/constants';
import Context from '../context';
import css from './SliderArrow.scss';

const SliderArrow: React.FC<BBCommonProps> = props => {
	const sliderContext = React.useContext(Context);
	const { editableModeProps: EMP, stateAttrs, uiConfig } = props;
	const { sliderArrowDir } = props.uiConfig.componentProps;

	return (
		<div
			{...uiConfig.nodeProps}
			{...stateAttrs}
			{...props.eventListeners}
			{...EMP?.nodeProps}
			style={uiConfig.nodeProps?.style}
			className={cn(css.arrow, uiConfig.nodeProps.className, EMP?.nodeProps?.className, {
				[css.prev]: sliderArrowDir === SliderArrowDir.PREV,
				[css.next]: sliderArrowDir === SliderArrowDir.NEXT,
			})}
			ref={attachRef([
				props.containerRef!,
				sliderArrowDir === SliderArrowDir.NEXT ? sliderContext.nextArrowRef : sliderContext.prevArrowRef,
			])}
		>
			{Array.isArray(props.children) && props.children.length > 0
				? props.children
				: props.uiConfig.componentProps.sliderArrowDir}
		</div>
	);
};

export default withCardTransitionContext(SliderArrow);
