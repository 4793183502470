import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import type { BBCommonProps } from 'types/story';
import { EditorMode } from 'types/story';
import type { TransitionHandlerContextType } from 'client/components/common/CardTransition/TransitionHandler';
import type { TFieldProps } from 'client/components/common/BuildingBlocks/Fields/types';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import css from 'client/components/common/BuildingBlocks/Fields/TextField/TextField.scss';
import { COMPONENT_STATES } from 'common/constants';
import { isLayerType } from 'common/utils/blocks/is-layer-type';
import { transmitToAdminBbUpdate } from 'client/utils/transmit-to-admin-bb-update';
import { SelectionHintEvent } from 'client/components/common/SelectionHint/SelectionHintEvent';
import { CSS_PROPS, UNIFORM_PROPS } from 'common/constants/component-props';
import { InternationalCountryData } from 'client/components/common/PhoneInput/common';
import { Error } from '../Error';
import { placeholderPath, withField, WithFieldT } from '../utils';
import { FIELD_DATA_ATTR, FIELD_EDITABLE_ATTR, FieldBBType } from '../constants';

type Props = BBCommonProps & WithFieldT & TransitionHandlerContextType;

const DatePicker = React.lazy(() => import('client/components/common/BuildingBlocks/Fields/TextField/DatePicker'));
const PhoneInput = React.lazy(() => import('client/components/common/PhoneInput'));

const TextField: React.FC<Props> = props => {
	const propsRef = useRef(props);
	propsRef.current = props;

	const { isEditableMode, editorMode } = props;
	const path = props.editableModeProps?.nodeProps?.['data-path'];
	const type = props.uiConfig.componentProps[UNIFORM_PROPS.fieldType] ?? FieldBBType.text;
	const name = props.uiConfig.editorProps?.name;
	const phColor: string | undefined = props.uiConfig.componentProps.styles[CSS_PROPS.field.style.text.phColor];
	const isGlobal = isLayerType(props).global;
	const [readOnly, setReadOnly] = useState(isEditableMode);
	const [pending, setPending] = useState(false);
	const providedPlaceholder = props.uiConfig.componentProps[UNIFORM_PROPS.fieldPlaceholder];
	const [placeholder, setPlaceholder] = useState(providedPlaceholder);

	// list of properties to enable placeholder inline editing
	const editableModeInputProps: Partial<TFieldProps> = useMemo(() => {
		if (!isEditableMode) return {};

		return {
			...(readOnly ? null : { [FIELD_EDITABLE_ATTR]: '' /* necessarily prevent component drag */ }),
			value: readOnly ? '' : placeholder ?? '',
			style: { color: phColor },
			[editorMode === EditorMode.CONTENT ? 'onClick' : 'onDoubleClick']: () => {
				setReadOnly(false);
			},
			onChange: event => {
				setPlaceholder(event.target.value);
			},
			onBlur: event => {
				if (placeholder === providedPlaceholder) {
					setReadOnly(true);
					return;
				}

				transmitToAdminBbUpdate({
					id: TextField.name,
					path: `elements.${path}.${placeholderPath}`,
					value: placeholder,
					isStory: isGlobal,
				});

				setPending(true);
			},
			onFocus: event => {
				// prevent this block from being selected when clicking on its parent
				event.stopPropagation();
			},
		};
	}, [providedPlaceholder, placeholder, readOnly, isGlobal, path, phColor, isEditableMode, editorMode]);

	// list of common input properties
	const inputProps: TFieldProps = useMemo(() => {
		return {
			className: css.field,
			type,
			name,
			placeholder,
			autoComplete: 'off',
			[FIELD_DATA_ATTR]: '',
			readOnly,
			...editableModeInputProps,
		} as const;
	}, [type, name, placeholder, readOnly, editableModeInputProps]);

	const nodeProps = {
		...props.uiConfig.nodeProps,
		...(props.editableModeProps ? props.editableModeProps.nodeProps ?? null : null),
		className: cn(
			css.textField,
			css[inputProps.type],
			props.uiConfig.nodeProps.className,
			props.editableModeProps?.nodeProps?.className
		),
	};
	const isIncorrect = 'data-incorrect-state' in nodeProps || props.states[COMPONENT_STATES.INCORRECT];
	const errorMessage = props.getErrorMsg(nodeProps);

	const renderInput = () => {
		const defaultInput = (p?: React.InputHTMLAttributes<HTMLInputElement>) => <input {...inputProps} {...p} />;

		switch (inputProps.type) {
			case FieldBBType.date: {
				return isEditableMode ? (
					defaultInput({ type: 'text' })
				) : (
					<React.Suspense fallback={null}>
						<DatePicker {...inputProps} uiConfig={props.uiConfig} />
					</React.Suspense>
				);
			}
			case FieldBBType.tel: {
				const defaultCountry = props.uiConfig.componentProps[UNIFORM_PROPS.telCountry];
				const textAlign =
					props.uiConfig.componentProps.styles.textAlign ||
					(document.documentElement.dir === 'rtl' ? 'right' : 'left');
				const align = textAlign === 'right' ? 'right' : 'left';
				return defaultCountry ? (
					<React.Suspense fallback={null}>
						<PhoneInput
							{...(isEditableMode
								? {
										renderInput: providedProps =>
											defaultInput({
												...providedProps,
												className: cn(inputProps.className, providedProps.className),
											}),
									}
								: { inputProps })}
							placeholder={inputProps.placeholder}
							className={css.internationalPhone}
							align={align}
							defaultCountry={defaultCountry}
							hideCountry={defaultCountry !== InternationalCountryData.iso2}
							// disable country selector
							disabled={isEditableMode}
							// reinitialize component on `defaultCountry` change to update country selector UI
							key={`${inputProps.name}-${defaultCountry}`}
						/>
					</React.Suspense>
				) : (
					defaultInput()
				);
			}
			default: {
				return defaultInput();
			}
		}
	};

	// place caret into input to start editing placeholder
	useEffect(() => {
		if (!readOnly) {
			const p = propsRef.current as WithRequired<BBCommonProps, 'editableModeProps'>;
			SelectionHintEvent.triggerFocus(p);
		}
	}, [readOnly]);

	// update placeholder with a value from props
	useEffect(() => {
		setPlaceholder(providedPlaceholder);
	}, [providedPlaceholder]);

	// ensure that editable placeholder successfully saved to complete editing mode
	useEffect(() => {
		if (providedPlaceholder === placeholder && pending) {
			setPending(false);
			setReadOnly(true);
		}
	}, [providedPlaceholder, placeholder, pending]);

	return (
		<div
			{...props.stateAttrs}
			{...props.eventListeners}
			{...nodeProps}
			ref={props.containerRef as RefObject<HTMLDivElement>}
		>
			{renderInput()}
			<Error
				isHidden={!isIncorrect || errorMessage === undefined}
				path={path}
				isEditableMode={!!isEditableMode}
				errorType={props.uiConfig.editorProps[UNIFORM_PROPS.fieldErrorShow]}
				text={errorMessage}
				editorMode={editorMode}
			/>
		</div>
	);
};

export default withCardTransitionContext(withField(TextField));
