import React, { memo } from 'react';
import { createPortal } from 'react-dom';

type Props = {
	styles?: string;
	children?: string;
};

const GlobalStyles: React.FC<Props> = ({ styles = '', children = '' }: React.ComponentProps<typeof GlobalStyles>) => {
	return createPortal(
		<style data-title="storycards-global-styles-component">{styles || children}</style>,
		document.head
	);
};

export default memo(GlobalStyles);
