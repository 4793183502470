// Common util to read response errors
export const getResponseErrors = (e: IBackendResponseError) => e?.response?.body?.errors;

export const getResponseErrorMsg = (
	e: IBackendResponseError | IBackendError[],
	fallbackMsg: string = 'Something went wrong'
) => {
	const isResponseErrorArr = Array.isArray(e);
	const responseErrorMsg = isResponseErrorArr
		? e[0].message
		: getResponseErrors(e)?.[0]?.message ?? (e?.response?.body as any)?.message;

	if (typeof responseErrorMsg === 'string') {
		const objectIndex = responseErrorMsg.indexOf('{');
		if (objectIndex !== -1) {
			try {
				const json = JSON.parse(responseErrorMsg.slice(objectIndex));
				return json.response.errors[0].message;
			} catch (_e) {
				return responseErrorMsg;
			}
		}

		return responseErrorMsg;
	}

	return (isResponseErrorArr ? '' : e?.message) || fallbackMsg;
};
