import { Resource } from './resource';

export enum RequestMethod {
	GET = 'GET',
	POST = 'POST',
	PUT = 'PUT',
	PATCH = 'PATCH',
	DELETE = 'DELETE',
	HEAD = 'HEAD',
}

export type IInterceptorOptions = IResource['options'] & IEndpoint['options'] & SendOptions;

export type RequestInterceptor = (req: Request, requestOptions: IInterceptorOptions) => typeof req;

export type ResponseInterceptor = (req: Request, res: Response, options: IInterceptorOptions) => typeof req;

export type ErrorInterceptor = (req: Request, res: Response, options: IInterceptorOptions) => typeof req;

export interface IResource {
	url: string;
	options: Partial<{
		headers: Record<string, string>;
		requestInterceptors: RequestInterceptor[];
		responseInterceptors: ResponseInterceptor[];
		errorInterceptors: ErrorInterceptor[];
		storyAdapter: boolean;
	}>;
	interceptor: RequestInterceptor | ResponseInterceptor | ErrorInterceptor;
}

export interface IEndpoint {
	resource: Resource;
	url: string;
	options: { type?: RequestMethod; storyAdapter?: boolean };
}

type OptionalParam = {
	headers?: Record<string, string>;
	requestInterceptors?: RequestInterceptor[];
	responseInterceptors?: ResponseInterceptor[];
	errorInterceptors?: ErrorInterceptor[];
};
type SingletonOption = Record<Exclude<string, 'data' | 'query'>, any>;
export type DataOption = { data: Record<string, any> } & OptionalParam;
export type QueryOption = { query: Record<string, string> } & OptionalParam;
export type SendOptions = DataOption | QueryOption | SingletonOption;
