/* eslint-disable max-len */
import { parseInt } from 'lodash';
import type { StoryMediaQuery, StoryModel, StoryMediaPlatform } from 'types/story';
import { cutURLMethod, IMAGE_PARAMS, isGif, isGradient, isImageEditableURL, isSvg } from 'utils/assets';

const MAX_WIDTH = {
	DESKTOP: 2200,
	MOBILE: 1200,
	STEP: 600,
};

const QUALITY = 85;

const IMGIX_PARAMS_PRESET = {
	/* 'fit="fillmax"' restricts the loadable image size to the maximum size of the original image. For instance,
	   if a request specifies a width of 1800 pixels but the original image width is 100 pixels,
	   the resulting image will not exceed the original width.
	 */
	DESKTOP: `${IMAGE_PARAMS.width}=${MAX_WIDTH.DESKTOP}&${IMAGE_PARAMS.quality}=${QUALITY}&${IMAGE_PARAMS.fit}=fillmax&${IMAGE_PARAMS.auto}=format,compress`,
	MOBILE: `${IMAGE_PARAMS.width}=${MAX_WIDTH.MOBILE}&${IMAGE_PARAMS.quality}=${QUALITY}&${IMAGE_PARAMS.fit}=fillmax&${IMAGE_PARAMS.auto}=format,compress`,
};

const roundTo = (num: number, round = MAX_WIDTH.STEP): number => Math.max(Math.ceil(num / round) * round, round);

type BuildImageUrlCommonProps = {
	src: string;
	storycardsDomain: StoryModel['storycardsDomain'];
};

interface BuildBackgroundImageUrl extends BuildImageUrlCommonProps {
	type: 'card';
	mq: StoryMediaQuery['config'];
}

interface BuildResizableImageUrl extends BuildImageUrlCommonProps {
	type: 'image';
	width: number;
	platform: StoryMediaPlatform;
}

interface BuildOriginalImageUrl {
	type: 'original';
	src: string;
}

type BuildImageUrlProps = BuildBackgroundImageUrl | BuildResizableImageUrl | BuildOriginalImageUrl;

// configure image src with unsupported format
const handleUnsupportedFormats = (src: string): string => {
	try {
		const url = new URL(src);
		return `${url.origin}${url.pathname}`;
	} catch (e) {
		console.error(e);
		return src;
	}
};

// configure image src for background-image format. non-resizable. e.g. "Card" block
const handleBackgroundImageFormat = (props: BuildBackgroundImageUrl): string => {
	const hasParams = props.src.match(/\?/);
	const mobileSize = props.mq.mobile.maxWidth ?? 0;
	const params = window.innerWidth <= mobileSize ? IMGIX_PARAMS_PRESET.MOBILE : IMGIX_PARAMS_PRESET.DESKTOP;
	return `${props.src}${hasParams ? '&' : '?'}${params}`;
};

// configure resizable image src
const handleResizableImageUrl = (props: BuildResizableImageUrl): string => {
	try {
		const url = new URL(props.src);
		const naturalWidth = Number(url.searchParams.get(IMAGE_PARAMS.oW) ?? 0);
		const platformMax = props.platform === 'mobile' ? MAX_WIDTH.MOBILE : MAX_WIDTH.DESKTOP;
		const max = naturalWidth > 0 ? Math.min(naturalWidth, platformMax) : platformMax;
		const dpr = Math.ceil(window.devicePixelRatio);
		const width = Math.min(max, roundTo(props.width * dpr));
		if (width > 0) {
			url.searchParams.set(IMAGE_PARAMS.width, `${width}`);
			url.searchParams.set(IMAGE_PARAMS.quality, `${QUALITY}`);
			url.searchParams.set(IMAGE_PARAMS.auto, 'format,compress');
			return url.href;
		}

		return props.src;
	} catch (e) {
		return props.src;
	}
};

const adjustImageUrlForPreload = (src: string): string => {
	try {
		const url = new URL(src);
		url.searchParams.set(IMAGE_PARAMS.width, '50');
		url.searchParams.set(IMAGE_PARAMS.quality, '5');
		url.searchParams.set(IMAGE_PARAMS.blur, '20');
		return url.href;
	} catch (e) {
		console.error(e);
		return src;
	}
};

export const adjustImageUrlByProvider = (src: string, storycardsDomain: StoryModel['storycardsDomain']) => {
	if (storycardsDomain?.provider === 'Cloudflare') {
		try {
			const url = new URL(src);
			const paramsMap = {
				w: url.searchParams.get(IMAGE_PARAMS.width),
				q: url.searchParams.get(IMAGE_PARAMS.quality),
				rect: url.searchParams.get(IMAGE_PARAMS.rect),
				blur: url.searchParams.get(IMAGE_PARAMS.blur),
				fit: url.searchParams.get(IMAGE_PARAMS.fit),
				fsz: url.searchParams.get(IMAGE_PARAMS.fsz),
				optimization: url.searchParams.get(IMAGE_PARAMS.auto),
				oW: url.searchParams.get(IMAGE_PARAMS.oW),
				oH: url.searchParams.get(IMAGE_PARAMS.oH),
			};

			let trim: null | string = null;
			if (paramsMap.rect) {
				const [left, top, width, height] = paramsMap.rect.split(',');
				const n = (v: string) => Math.max(0, parseInt(v, 10));
				const isContain = paramsMap.fsz === 'contain';
				const w = n(isContain ? String(paramsMap.oW ?? 0) : width);
				const h = n(isContain ? String(paramsMap.oH ?? 0) : height);
				trim = `trim.left=${n(left)},trim.top=${n(top)},trim.width=${w},trim.height=${h}`;
			}

			const options = [
				trim,
				paramsMap.w ? `width=${paramsMap.w}` : null,
				paramsMap.q ? `quality=${paramsMap.q}` : null,
				paramsMap.blur ? `blur=${paramsMap.blur}` : null,
				paramsMap.fit ? `fit=scale-down` : null,
				paramsMap.optimization ? 'format=auto' : null,
			]
				.filter(Boolean)
				.join(',');

			url.pathname = `/cdn-cgi/image/${options}${url.pathname}`;

			url.searchParams.delete(IMAGE_PARAMS.width);
			url.searchParams.delete(IMAGE_PARAMS.quality);
			url.searchParams.delete(IMAGE_PARAMS.rect);
			url.searchParams.delete(IMAGE_PARAMS.blur);
			url.searchParams.delete(IMAGE_PARAMS.fit);
			url.searchParams.delete(IMAGE_PARAMS.auto);

			return url.href;
		} catch (e) {
			console.error(e);
		}
	}

	return src;
};

// TODO: consider to create new URL here and pass it to the utilities
export const buildImageUrl = (props: BuildImageUrlProps): { src: string; lowQualitySrc: string } => {
	let src = isGradient(props.src) ? '' : decodeURIComponent(cutURLMethod(props.src));
	const isNone = src === 'none';
	const isSvgFormat = isSvg(src);
	const isGifFormat = isGif(src);
	const isEditableURL = props.type !== 'original' && isImageEditableURL(src, props.storycardsDomain);
	const isAdjustable = isEditableURL && !isSvgFormat && !isGifFormat && !isNone;

	if (isEditableURL && (isSvgFormat || isGifFormat)) {
		src = handleUnsupportedFormats(src);
	} else if (props.type === 'card' && isEditableURL && !isNone && !isSvgFormat && !isGifFormat) {
		src = handleBackgroundImageFormat({ ...props, src });
	} else if (props.type === 'image') {
		src = handleResizableImageUrl({ ...props, src });
	}

	return {
		src: isAdjustable ? adjustImageUrlByProvider(src, props.storycardsDomain) : src,
		lowQualitySrc: isAdjustable
			? adjustImageUrlByProvider(adjustImageUrlForPreload(src), props.storycardsDomain)
			: src,
	};
};
